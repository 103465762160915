export default function Customer({ service }: any) {
  return (
    <div className="mt-4">
      <div className="border-t border-l border-r border-black px-2 flex">
        <div className="border-r border-black w-36">Customer Name:</div>
        <div className="px-2">{service?.contact?.name}</div>
      </div>
      <div className="border-t border-l border-r border-black px-2 flex">
        <div className="border-r border-black w-36">
          Company
          <span style={{ fontSize: 10, marginLeft: 5 }}>(if applicable) :</span>
        </div>
        <div className="px-2">
          {service?.customer?.isCompany ? service?.customer?.name : "-"}
        </div>
      </div>
      <div className="flex border-t border-l border-r border-b border-black px-2 ">
        <div className="border-r border-black w-36">Mobile Number :</div>
        <div className="px-2">{service?.contact?.mobile}</div>
      </div>
    </div>
  );
}
