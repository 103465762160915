import { ROAD_BASE_URL } from "../utils/urls";

const postDataRoad = async (data: any, uri: any) => {
  const response = await fetch(`${ROAD_BASE_URL}/${uri}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  return result;
};

export default postDataRoad;
