import { Button, Modal, Form, message } from "antd";
import { useState } from "react";

type RequiredMark = boolean | "optional";

export default function DeleteModel({ isVisible, setVisible, selected }: any) {
  const [isLoading, setLoading] = useState(false);
  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const [form] = Form.useForm();

  const onSubmit = async () => {
    message.error("Sorry, You can delete vehicle model");
    setVisible(false);
  };

  return (
    <Modal
      visible={isVisible}
      title="Delete Model"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          No
        </Button>,
        <Button
          key="submit"
          form="deleteModel"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Yes
        </Button>,
      ]}
    >
      <Form
        id="deleteModel"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
        initialValues={{ requiredMarkValue: requiredMark }}
        requiredMark={requiredMark}
      >
        <div>
          <p className="text-center font-bold">
            Are you sure you want to{" "}
            <span className="text-red-600 font-bold">delete</span> this model
          </p>
          <p className="text-center font-bold">{selected?.name}</p>
        </div>
      </Form>
    </Modal>
  );
}
