export default function Nature({ service }: any) {
  const { items } = service;
  return (
    <div className="mt-4">
      <div className="">Nature of Breakdown</div>
      <div className="border border-black">
        <div className="flex bg-gray-900 text-white">
          <div className="w-36 px-2 border-r border-white">Nature</div>
          <div className="px-2">Remarks</div>
        </div>
        {items?.map((item: any) => (
          <div className="flex text-black font-normal border-t border-black">
            <div className="w-36 px-2 border-r border-black">
              {item?.callout_desc}
            </div>
            <div className="px-2">{item?.remarks}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
