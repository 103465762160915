export default function Three() {
  return (
    <div className="mt-1">
      <div className="">
        <div style={{ fontSize: 12, marginTop: 2 }}>
          Toyota Tanzania 24/7 Roadside Assistance benefits and conditions
        </div>
        <div className="font-bold">Automotive Assistance</div>
        <div className="">
          We will arrange, where it is possible and safe to do so, most common
          Breakdown related problems to be rectified including inflation of a
          flat tyre, replacement of a flat tyre with the vehicle’s serviceable
          spare and jump-starting or replacement of a flat battery. Where
          appropriate, this may also include providing practical tips or advice,
          on a reasonable endeavors basis, in relation to simple Vehicle
          operation, any safety warnings or lights that may appear or practical
          information regarding the Vehicle.
        </div>

        <div className="font-bold mt-1">Minor Automotive Repairs</div>
        <div className="">
          We will arrange for minor breakdown related repairs to be carried out
          where it is possible and safe to do so at the location of the
          breakdown. However, if major parts or factory diagnostic equipment are
          required, the vehicle will be transported to Toyota Tanzania Dar es
          Salaam Dealership. The cost of repairs, including labour and any
          required parts, will be your responsibility.
        </div>

        <div className="font-bold mt-1">Flat Battery</div>
        <div className="">
          If your battery is flat we arrange for a jump start or coordinate a
          replacement battery if required.
        </div>

        <div className="font-bold mt-1">Emergency Fuel</div>
        <div className="">
          If you run out of fuel (petrol or diesel) we will arrange for the
          delivery of enough fuel to allow you to reach the nearest filling
          station. The maximum value of fuel provided is 10 litres for a fee as
          listed in the pricing menu.
        </div>

        <div className="font-bold mt-1">Flat Tyres</div>
        <div className="">
          If you have a flat tyre, we will arrange for it to be changed with the
          vehicle’s serviceable spare wheel or repair the puncture.
        </div>
        <div className="font-bold mt-1">Lost or Locked Keys</div>
        <div className="">
          Where the key has been lost or stolen, or has been locked inside the
          Vehicle, we will provide key lockout assistance.
        </div>
        <div style={{ fontSize: 8 }} className="my-2 italic">
          Matching copies of the following documents must be provided prior to
          any assistance being provided: vehicle registration card, driving
          license or NIDA.
        </div>
        <div className="">
          In the event you insist the vehicle be broken into to recover keys
          locked inside the Vehicle, we will not, under any circumstances, be
          responsible for any loss or damage that occurs to the Vehicle as a
          result.
        </div>
        <div className="font-bold mt-1">Towing</div>
        <div className="">
          If the Vehicle cannot be mobilized on the roadside, we will arrange
          for your vehicle to be towed to Toyota Tanzania Dar es Salaam
          Dealership. Towing is provided for a fee within Dar-es-Salaam City
          limits only. Please note that towing costs and any repair costs are
          your responsibility.
        </div>
        <div className="font-bold mt-1">Alternative Transport</div>
        <div className="">
          If you would not like to wait for the repair of your vehicle while on
          site, or your vehicle requires towing, we will coordinate for
          alternative transport to your destination of choice. Alternative
          transport services are provided by Bolt Tanzania, for your safety and
          convenience. Please note that all costs for alternative transport are
          your responsibility. By choosing alternative transport you agree to
          the Terms and Conditions of Bolt Tanzania, and relieve us of the sole
          duty for provision of alternative transport
        </div>
      </div>
    </div>
  );
}
