import InvoiceItemsRead from "./InvoiceItemsRead";
import InvoiceTotals from "./InvoiceTotals";
import DocumentLogo from "./DocumentLogo";
import DocumentRef from "./DocumentRef";
import DocumentVehicle from "./DocumentVehicle";

export default function InvoiceWrapper({ service }: any) {
  if (service === undefined) {
    return <></>;
  }

  return (
    <div className="book" id="invoice-doc">
      <div className="page">
        <div className="subpage">
          <div className="p-1">
            <DocumentLogo />
            <DocumentRef service={service} />

            <DocumentVehicle service={service} />
            <InvoiceItemsRead items={service?.items} />
            <InvoiceTotals items={service?.items} />
            <div className="absolute bottom-0 right-0 left-0 border-t border-black">
              <div className="flex flex-col justify-center items-center text-xs text-black py-1">
                <div className="" style={{ fontSize: 12 }}>
                  Toyota Tanzania Ltd, P.O Box 9060, Dar es Salaam, Tanzania
                </div>
                <div className="" style={{ fontSize: 10 }}>
                  TIN: 100-146-304, VRN: 10-006645-E
                </div>
                <div className="" style={{ fontSize: 10 }}>
                  Email: customerservice@toyotatz.com, Phone Number: +255 (0)800
                  750134
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
