import Moment from "react-moment";

export default function Attended({ service }: any) {
  return (
    <div className="border border-black mt-4 px-2 flex">
      <div className="w-36 flex items-center border-r border-black">
        Date Attended To:
      </div>
      <div className="w-36 flex items-center border-r border-black px-2">
        <Moment format="DD/MM/YYYY">{service?.createdAt}</Moment>
      </div>
      <div className="w-36 flex items-center border-r border-black px-2">
        Time Attended To:
      </div>
      <div className="w-36 flex items-center px-2">
        <Moment format="HH:mm A">{service?.createdAt}</Moment>
      </div>
    </div>
  );
}
