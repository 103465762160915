import { message } from "antd";
import { useState } from "react";
import postDataRoad from "../../../services/postDataRoad";
import getBase64 from "../../../utils/getBase64";
import { AiOutlineWarning } from "react-icons/ai";
import { BsCheck2Square } from "react-icons/bs";

export default function VehicleWidget({ item, service, refetch }: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<number>(0);

  const changeHandler = async (event: any, code: any, id: any) => {
    setLoading(true);
    setCode(code);
    const base64 = await getBase64(event.target.files[0]);
    const uri = `services/photos/${id}`;
    const data = { file: base64, service: service?._id, code: code };
    const res = await postDataRoad(data, uri);
    if (res?.success) {
      message.success("Photo uploaded successfully");
      refetch();
    } else {
      message.error("Something went wrong");
    }
    setLoading(false);
    setCode(0);
  };

  return (
    <div className="flex flex-col items-center w-48 h-72">
      <div className="p-1 text-black font-bold text-center">
        {item?.code}. {item?.position}
      </div>
      <div className="">
        {item?.url ? (
          <div className="flex flex-col items-center h-40">
            <img alt="" src={item?.url} className="w-40" />
            <div className="mt-2 h-12">
              {item?.issue === "No Issues" ? (
                <div className="flex justify-center items-center">
                  <BsCheck2Square color={"#15803d"} size={15} />
                  <span className="ml-1 truncate">{item?.issue}</span>
                </div>
              ) : (
                <div className="flex justify-center">
                  <AiOutlineWarning color={"#ca8a04"} size={22} />
                  <span className="ml-1">{item?.issue}</span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className=" flex justify-center items-center h-40">
            <div className="opacity-10 ">
              <img alt="" src={item?.guide} className="w-40" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
