import { Button, Modal, Form, Row, Col, message, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { BsCheck2, BsSearch } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { IoAddOutline } from "react-icons/io5";
import { RiFilterOffLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import fetchDataRoad from "../../../services/fetchDataRoad";
import postDataRoad from "../../../services/postDataRoad";
import CreateContact from "./CreateContact";
import VehicleModel from "./VehicleModel";

const { Option } = Select;

export default function CreateService({
  isVisible,
  selected,
  setVisible,
  refetch,
  isCust,
}: any) {
  const navigation = useNavigate();
  const [curVehicle, setCurVehicle] = useState<any>(null);
  const [contacts, setContacts] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [technicians, setTechnicians] = useState<any>([]);
  const [vehicles, setVehicles] = useState<any>([]);
  const [contact, setContact] = useState<any>(null);
  const [registration, setRegistration] = useState<string>("");
  const [isSearched, setSearched] = useState<boolean>(false);
  const [contactModal, setContactModal] = useState<boolean>(false);
  const [modelModal, setModelModal] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const [form] = Form.useForm();

  const handleFetchTechnicians = async () => {
    const data = await fetchDataRoad({ uri: `users` });
    setTechnicians(data?.payload);
  };

  const handleFetchVehicles = async () => {
    const data = await fetchDataRoad({ uri: `vehicles` });
    if (isCust) {
      const v = data?.payload.filter(
        (item: any) => item?.customer?._id === selected
      );
      setVehicles(v);
    } else {
      setVehicles(data?.payload);
    }
  };

  const handleSearchVehicle = async () => {
    if (!searchKey) {
      message.error("Please enter vehicle registration number");
      return;
    }
    setLoading(true);
    setError(null);
    const formated = searchKey.replace(" ", "").toUpperCase();
    const data = {};
    const uri = `vehicles/search/registration?key=${formated}`;
    const response = await postDataRoad(data, uri);
    if (response?.success) {
      setCurVehicle(response?.payload);
      setContacts(response?.payload?.customer?.contacts);
      setLoading(false);
      setError(null);
      setSearched(true);
      return;
    } else {
      setError(`${response?.message}`);
      setCurVehicle(null);
      setContacts([]);
      setLoading(false);
      return;
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const { location, mileage, technician } = await form.getFieldsValue();

    const tech = technicians?.filter((item: any) => item?._id === technician);

    if (!contact) {
      message.error("Please select contact person or driver");
      setLoading(false);
      return;
    }

    const vehcData = {
      _id: curVehicle?.registration,
      registration: curVehicle?.registration,
      model: curVehicle?.model,
      chassis: curVehicle?.chassis,
      fuel: curVehicle?.fuel,
      year: curVehicle?.year,
      card: curVehicle?.card,
      engine: curVehicle?.engineno,
      mileage: mileage,
    };

    const custData = {
      _id: curVehicle?.customer?._id,
      name: curVehicle?.customer?.name,
      email: curVehicle?.customer?.email,
      mobile: curVehicle?.customer?.mobile,
      isCompany: curVehicle?.customer?.isCompany,
      isCredit: curVehicle?.customer?.isCredit,
      credit: curVehicle?.customer?.credit,
    };

    const contData = {
      _id: contact?._id,
      name: contact?.name,
      email: contact?.email,
      mobile: contact?.mobile,
    };

    const techData = {
      _id: tech[0]?._id,
      name: tech[0]?.name,
      email: tech[0]?.email,
      mobile: tech[0]?.mobile,
    };

    const data = {
      vehicle: vehcData,
      customer: custData,
      technician: techData,
      location: location,
      status: "In Progress",
      account: custData?._id,
      contact: contData,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      items: [],
    };

    const uri = "services";

    const response = await postDataRoad(data, uri);
    if (response.success === false) {
      message.error("Something went wrong");
    } else {
      if (response.success) {
        message.success("Created Successfully");
        if (isCust) {
          navigation(
            `/customers/${custData?._id}/services/${
              response?.payload?._id
            }?redirect=${`/customers/${custData?._id}/services?name=${custData?.name}`}`
          );
        } else {
          navigation(`/services/${response?.payload?._id}?redirect=/services`);
        }
        await refetch();
        form.resetFields();
        hideModal();
      } else {
        message.error(response.message);
      }
    }
    setLoading(false);
  };

  const technicianOptions = technicians?.map(
    (technician: any, index: number) => {
      return (
        <Option value={technician._id} key={index}>
          {`${technician?.name}`}
        </Option>
      );
    }
  );

  const hideModal = () => {
    setLoading(false);
    setVisible(false);
  };

  useEffect(() => {
    form.resetFields();
    handleFetchVehicles();
    handleFetchTechnicians();
  }, [selected, form]);

  return (
    <Modal
      visible={isVisible}
      title="Create Service"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="createService"
          htmlType="submit"
          type="primary"
          danger
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Next
        </Button>,
      ]}
    >
      <div className="flex flex-col">
        <div className="flex">
          <Form layout="inline" form={form}>
            <Form.Item name="name">
              <Input
                name="searchKey"
                onChange={(e: any) => {
                  setSearchKey(e.target.value);
                  setSearched(false);
                }}
                style={{ width: "100%" }}
                type="text"
                placeholder="Vehicle Registration No."
              />
            </Form.Item>
          </Form>
          <Button
            className="focus:outline-none"
            onClick={() => {
              if (isSearched) {
                setSearched(false);
                form.resetFields();
                setSearchKey(null);
              } else {
                handleSearchVehicle();
              }
            }}
          >
            {isSearched ? (
              <RiFilterOffLine color={"#f87171"} />
            ) : (
              <BsSearch color={searchKey ? "#16a34a" : "#9ca3af"} />
            )}
          </Button>
        </div>
        {error ? (
          <div className="text-red-600 mt-4">{error}</div>
        ) : (
          <>
            {isSearched ? (
              <div className="mt-4">
                <div className="mb-2">
                  <div className="font-bold">Vehicle Details</div>
                  <div className="flex">
                    <div className="w-20">Vehicle:</div>
                    <div className="text-green-600">
                      {curVehicle?.registration}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-20">Model:</div>
                    <div className="flex items-center">
                      <div className="text-green-600 mr-2">
                        {curVehicle?.model?.name}
                      </div>
                      {curVehicle?.model?.name ? (
                        <span onClick={() => setModelModal(true)}>
                          <FiEdit />
                        </span>
                      ) : (
                        <span
                          className="border py-1 px-2 text-red-600"
                          onClick={() => setModelModal(true)}
                        >
                          Add Vehicle Model
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-20">Customer:</div>
                    <span className="text-green-600">
                      {curVehicle?.customer?.name}
                    </span>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex justify-between">
                    <div className="font-bold">Contact Person / Driver</div>
                    <div
                      className="h-5 w-5 border flex justify-center items-center"
                      onClick={() => setContactModal(true)}
                    >
                      <IoAddOutline />
                    </div>
                  </div>
                  {contacts?.map((item: any, index: number) => (
                    <div className="flex items-center justify-between">
                      <div className="flex">
                        <div className="mr-2">{index + 1}.</div>
                        <div className="">{item?.name}</div>
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          setContact(item);
                        }}
                      >
                        <div className="h-5 w-5 border flex justify-center items-center">
                          <BsCheck2
                            color={
                              contact?._id === item?._id ? "green" : "#FFF"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <Form
                  id="createService"
                  form={form}
                  layout="vertical"
                  name="normal_login"
                  className="login-form"
                  onFinish={onSubmit}
                >
                  <Row gutter={[16, 0]}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name="location"
                        label="Location Found"
                        rules={[
                          {
                            required: true,
                            message: "Please enter location",
                          },
                        ]}
                      >
                        <Input className="w-full" placeholder="Location" />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name="mileage"
                        label="Mileage"
                        rules={[
                          {
                            required: true,
                            message: "Please enter mileage",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          className="w-full"
                          placeholder="Mileage"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name="technician"
                        label="Technician"
                        rules={[
                          {
                            required: true,
                            message: "Please select technician",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Search Technician"
                          optionFilterProp="children"
                          filterOption={(input: any, option: any) =>
                            option?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {technicianOptions}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            ) : null}
          </>
        )}
      </div>
      <CreateContact
        isVisible={contactModal}
        setVisible={setContactModal}
        customer={curVehicle?.customer}
        refetch={handleSearchVehicle}
      />

      <VehicleModel
        isVisible={modelModal}
        setVisible={setModelModal}
        vehicle={curVehicle}
        refetch={handleSearchVehicle}
      />
    </Modal>
  );
}
