import { Outlet } from "react-router";
import { GoHome } from "react-icons/go";
import { ImFileText2 } from "react-icons/im";
import { IoIosCog } from "react-icons/io";
import AdminLink from "../components/shared/AdminLink";
import Header from "../components/layouts/Header";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { FiUsers } from "react-icons/fi";
import { AiOutlineCar } from "react-icons/ai";

export default function MainLayout() {
  const { auth, user } = useContext(AuthContext);
  const menus = [
    {
      id: 1,
      to: "/dashboard",
      name: "Dashboard",
      icon: <GoHome size={28} />,
    },
    {
      id: 2,
      to: "/customers",
      name: "Customers",
      icon: <FiUsers size={26} />,
    },
    {
      id: 2,
      to: "/vehicles",
      name: "Vehicles",
      icon: <AiOutlineCar size={26} />,
    },
    {
      id: 3,
      to: "/services",
      name: "Services",
      icon: <ImFileText2 size={22} />,
    },
  ];

  const admin = [
    {
      id: 4,
      to: "/manage",
      name: "Manage",
      icon: <IoIosCog size={26} />,
    },
  ];

  return (
    <div className="flex bg-gray-100 font-toyota">
      <div className="w-full">
        <Header />
        <div className="flex">
          <div className="flex flex-col min-h-screen bg-red-50 border-r border-gray-300 w-24">
            <div className="mt-0">
              {menus.map((menu: any, index: number) => (
                <AdminLink menu={menu} key={index}>
                  {menu.name}
                </AdminLink>
              ))}

              {user?.email === "test@email.com" ? null : (
                <div className="">
                  {admin.map((menu: any, index: number) => (
                    <AdminLink menu={menu} key={index}>
                      {menu.name}
                    </AdminLink>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="flex-1">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
