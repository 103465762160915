export default function OneSw() {
  return (
    <div className="">
      <div className="">
        Msaada wa Toyota Tanzania Pembezoni mwa Barabara kwa saa 24/7 ni huduma
        ya uhakika kwa gari lako na inapatikana kwa saa 24 kwa siku, na kwa siku
        365 kwa mwaka Jiji la Dar es Salaam tu.
      </div>

      <div className="mt-2 font-bold">
        Taarifa unazotakiwa kuwa nazo unapopiga simu kuomba msaada
      </div>
      <div className="">
        Ili kupata huduma ya Toyota Tanzania Pembezoni mwa Barabara kwa saa
        24/7, ni rahisi kabisa; tupigie simu kupitia namba 0800 075 0257, ukiwa
        na taarifa zifuatazo mkononi:
      </div>
      <div className="ml-6">
        <li>Jina lako, kampuni (kama inahusika) na mahali ulipo</li>
        <li>Namba ya usajili wa gari na rangi ya gari lako</li>
        <li>Modeli ya gari</li>
        <li>Maelezo kuhusu tatizo ulilopata</li>
        <li>Namba ya simu unayoweza kupatikana kutoka ulipo</li>
      </div>
      <div style={{ fontSize: 8 }} className="italic mt-4">
        Simu zote kwa Toyota Tanzania 24/7 msaada wa barabarani zimerekodiwa.
        Hii itatusaidia kuthibitisha maelezo ya simu ambayo inaweza kuwa
        haijakamilika au haijulikani wazi.
      </div>
    </div>
  );
}
