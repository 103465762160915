import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import ReactToPrint from "react-to-print";
import { BsPrinter } from "react-icons/bs";

import InvoiceWrapper from "./components/InvoiceWrapper";
import fetchDataRoad from "../../services/fetchDataRoad";
import ServiceWrapper from "./components/ServiceWrapper";
import LicenseWrapper from "./components/LicenseWrapper";
import RegistrationWrapper from "./components/RegistrationWrapper";
import TermsWrapperEn from "./components/TermsWrapperEn";
import TermsWrapperSw from "./components/TermsWrapperSw";
import ExPhotosWrapper from "./components/ExPhotosWrapper";
import InPhotosWrapper from "./components/InPhotosWrapper";
import WlPhotosWrapper from "./components/WlPhotosWrapper";

export default function PrvService() {
  const [editable, setEditable] = useState(false);
  const [service, setService] = useState<any>({});

  const { id } = useParams();

  const jobRef = useRef(null);
  const handleFetchService = async () => {
    const uri = `services/${id}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setService(response?.payload);
    }
  };

  useEffect(() => {
    handleFetchService();
  }, []);

  return (
    <>
      <div className="md:flex">
        <div className="align-middle min-w-full overflow-hidden">
          <div style={{ fontSize: 12 }} className="grid gap-x-0 grid-cols-12">
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <div className="grid gap-x-0 grid-cols-12">
                <div className="col-span-12 sm:col-span-12 md:col-span-12 bg-gray-100">
                  <div className="flex flex-row justify-center items-center overflow-auto">
                    <div className="flex flex-col justify-between items-center">
                      <div className="bg-grayish">
                        <div className="flex justify-between bg-white p-2 -mb-8">
                          <ReactToPrint
                            trigger={() => {
                              return (
                                <button className="flex items-center border rounded-sm p-2 focus:outline-none hover:text-red-500 w-full text-left px-2">
                                  <BsPrinter size={15} className="mr-2" /> Print
                                  Documents
                                </button>
                              );
                            }}
                            content={() => jobRef.current}
                            documentTitle={`Document`}
                          />
                        </div>
                        <div className="" ref={jobRef}>
                          <ServiceWrapper
                            jobRef={jobRef}
                            service={service}
                            jobno={1}
                            refetch={handleFetchService}
                            editable={editable}
                            setEditable={setEditable}
                          />
                          {service?.isTowed && (
                            <>
                              <ExPhotosWrapper
                                service={service}
                                refetch={handleFetchService}
                                editable={editable}
                              />
                              <InPhotosWrapper
                                service={service}
                                refetch={handleFetchService}
                                editable={editable}
                              />
                              <WlPhotosWrapper
                                service={service}
                                refetch={handleFetchService}
                                editable={editable}
                              />
                            </>
                          )}
                          {service?.isKeyout && (
                            <>
                              <LicenseWrapper
                                service={service}
                                refetch={handleFetchService}
                                editable={editable}
                              />
                              <RegistrationWrapper
                                service={service}
                                refetch={handleFetchService}
                                editable={editable}
                              />
                            </>
                          )}

                          <TermsWrapperEn />
                          <TermsWrapperSw />

                          <InvoiceWrapper
                            jobRef={jobRef}
                            service={service}
                            jobno={1}
                            refetch={handleFetchService}
                            editable={editable}
                            setEditable={setEditable}
                          />
                        </div>

                        <div className="flex flex-col justify-center items-center mb-24">
                          <div className="text-lg text-toyota font-bold">
                            Toyota Tanzania Ltd
                          </div>
                          <div className="font-bold">
                            24/7 Roadside Assistant
                          </div>
                          <div className="">&copy; 2024</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
