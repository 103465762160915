import { useState } from "react";
import { FiChevronsRight } from "react-icons/fi";
import MainPanel from "./MainPanel";

export default function TodateWidget({ data, isLoading, year }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);

  return (
    <div className="flex flex-col justify-center items-center border-t-4 border-cyan-300">
      <div className="flex flex-col items-center flex-1 p-4">
        <div className="text-gray-400 text-xs uppercase">
          Year To Date SERVICES : {year}
        </div>
        <div className="font-bold text-toyota text-4xl mt-2 flex justify-center items-center">
          {isLoading ? (
            <div className="w-8 bg-gray-100 rounded-md text-gray-100">-</div>
          ) : (
            <div className="">{data?.length ? data?.length : 0}</div>
          )}
        </div>
      </div>
      <div className="w-full border-t pb-2 mx-4"></div>
      <button
        onClick={() => {
          setVisible(true);
        }}
        className="flex justify-between items-center border rounded-lg px-4  mb-2 hover:bg-blue-100 bg-blue-50 hover:cursor-pointer"
      >
        <div className="flex justify-center items-center mb-1">
          <div className="">View More</div>
          <div className="mt-1 ml-2">
            <FiChevronsRight />
          </div>
        </div>
      </button>

      <MainPanel
        isVisible={isVisible}
        setVisible={setVisible}
        data={data}
        title={"YTD"}
      />
    </div>
  );
}
