import { Button, Modal, Form, Row, Col, message, Select, Input } from "antd";
import { useEffect, useState } from "react";
import fetchDataRoad from "../../../services/fetchDataRoad";
import postDataRoad from "../../../services/postDataRoad";

const { Option } = Select;

export default function CreateItem({ isVisible, service, setVisible, refetch }: any) {
  const [isLoading, setLoading] = useState(false);
  const [offers, setOffers] = useState<any>([]);

  const [form] = Form.useForm();

  const handleFetchOffers = async () => {
    if (service?.vehicle?.model?._id === undefined) {
      return;
    }
    const uri = `offers/models/${service?.vehicle?.model?._id}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setOffers(response?.payload);
    }
  };

  const onSubmit = async () => {
    const { offer, remarks } = await form.getFieldsValue();

    const o = offers.filter((item: any) => item?._id === offer);
    const sertem = {
      callout_desc: o[0]?.callout_desc,
      invoice_desc: o[0]?.invoice_desc,
      unitmeasure: "HR",
      amount: o[0]?.amount,
      partsrate: o[0]?.partsrate,
      labourrate: o[0]?.labourrate,
      taxrate: o[0]?.taxrate,
      flattime: o[0]?.flattime,
      timetaken: o[0]?.timetaken,
      quantity: o[0]?.quantity,
      remarks: remarks,
      code: o[0]?.code,
    };

    const uri = `services/${service?._id}/item/add`;

    const data = { service: service?._id, ...sertem };
    const response = await postDataRoad(data, uri);
    if (response.success === false) {
      message.error("Something went wrong");
    } else {
      if (response.success) {
        message.success("Added Successfully");
        await refetch();
        form.resetFields();
        hideModal();
      } else {
        message.error(response.message);
      }
    }
  };

  const hideModal = () => {
    setVisible(false);
  };

  const offerOptions = offers?.map((offer: any, index: number) => {
    return (
      <Option value={offer._id} key={index}>
        {offer?.callout_desc}
      </Option>
    );
  });

  useEffect(() => {
    form.resetFields();
    handleFetchOffers();
  }, [service]);

  return (
    <Modal
      visible={isVisible}
      title="Service Item"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          danger
          form="createItem"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Add Item
        </Button>,
      ]}
    >
      <Form
        id="createItem"
        form={form}
        layout="vertical"
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="offer"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter date",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {offerOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="remarks"
              label="Remarks"
              rules={[
                {
                  required: true,
                  message: "Please enter remarks",
                },
              ]}
            >
              <Input type="text" placeholder="Remarks" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
