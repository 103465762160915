import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import patchDataRoad from "../../../services/patchDataRoad";

export default function UpdateCustomer({
  isVisible,
  setVisible,
  refetch,
  customer,
}: any) {
  const [selected, setSelected] = useState<number>(customer?.isCompany ? 1 : 2);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();
    const uri = `customers/${customer?._id}`;
    const isCompany = selected === 1 ? true : false;
    const data = { isCompany, ...values };
    const response = await patchDataRoad(data, uri);
    if (response.success) {
      message.success("Updated Successfully");
    } else {
      message.error(response?.message);
      setLoading(false);
      return;
    }
    refetch();
    setLoading(false);
    setVisible(false);
  };

  useEffect(() => {
    form.resetFields();
    setSelected(customer?.isCompany ? 1 : 2);
  }, [customer]);

  const clearForm = async () => {
    form.resetFields();
  };

  const types = [
    { id: 1, name: "Company" },
    { id: 2, name: "Individual" },
  ];

  return (
    <Modal
      visible={isVisible}
      title="Update Customer"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateCustomer"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateCustomer"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          {types?.map((type: any, index: number) => (
            <Col xs={{ span: 24 }} lg={{ span: 12 }} key={index}>
              <button
                type="button"
                className="flex flex-row  items-center mb-4 border w-full"
                onClick={() => setSelected(type.id)}
              >
                {selected === type?.id ? (
                  <div className="h-8 w-8 border-r flex justify-center items-center bg-green-100">
                    <AiOutlineCheck />
                  </div>
                ) : (
                  <div className="h-8 w-8 border-r flex justify-center items-center">
                    <AiOutlineCheck color="#FFFFFF" />
                  </div>
                )}
                <div className="ml-4"></div>
                {type?.name}
              </button>
            </Col>
          ))}
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Full Name"
              name="name"
              initialValue={customer?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Mobile"
              name="phone"
              initialValue={customer?.phone}
              rules={[
                {
                  required: true,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input placeholder="Mobile" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              initialValue={customer?.email}
              rules={[
                {
                  required: false,
                  message: "Please enter email",
                },
              ]}
            >
              <Input placeholder="Email (Optional)" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="address1"
              label="Address 1"
              initialValue={customer?.address1}
              rules={[
                {
                  required: false,
                  message: "Please enter address 1",
                },
              ]}
            >
              <Input placeholder="Address 1 (Optional)" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="address2"
              label="Address 2"
              initialValue={customer?.address2}
              rules={[
                {
                  required: false,
                  message: "Please enter address 2",
                },
              ]}
            >
              <Input placeholder="Address 2 (Optional)" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="address3"
              label="Address 3"
              initialValue={customer?.address3}
              rules={[
                {
                  required: false,
                  message: "Please enter address 3",
                },
              ]}
            >
              <Input placeholder="Address 3 (Optional)" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="address4"
              label="Address 4"
              initialValue={customer?.address4}
              rules={[
                {
                  required: false,
                  message: "Please enter address 4",
                },
              ]}
            >
              <Input placeholder="Address 4 (Optional)" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
