import { Button, Drawer, Space } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FiEdit, FiUser } from "react-icons/fi";
import { MdCorporateFare } from "react-icons/md";
import fetchDataRoad from "../../../services/fetchDataRoad";
import formatMoney from "../../../utils/formatMoney";
import { formatNumber } from "../../../utils/formatNumber";
import ContactPanel from "./ContactPanel";
import CreateVehiclePanel from "./CreateVehiclePanel";
import UpdateContactPanel from "./UpdateContactPanel";
import UpdateCreditPanel from "./UpdateCreditPanel";
import UpdateCustomerPanel from "./UpdateCustomerPanel";

export default function DetailsPanel({
  isVisible,
  setVisible,
  refetch,
  selected,
}: any) {
  const [customer, setCustomer] = useState<any>({ ...selected });
  const [contact, setContact] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [contactModal, setContactModal] = useState<boolean>(false);
  const [vehicleModal, setVehicleModal] = useState<boolean>(false);
  const [creditModal, setCreditModal] = useState<boolean>(false);
  const [updateCustomerModal, setUpdateCustomerModal] =
    useState<boolean>(false);
  const [updateContactModal, setUpdateContactModal] = useState<boolean>(false);

  const handleFetchCustomer = async () => {
    setLoading(true);
    const uri = `customers/${selected?._id}`;
    const response = await fetchDataRoad({ uri });
    setCustomer(response?.payload);
    setLoading(false);
  };

  useEffect(() => {
    setCustomer(selected);
  }, [selected]);

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">Customer Details</span>
        </div>
      }
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="-mt-2">
        <div className="p-2">
          <div className="flex justify-between">
            <div className="flex">
              <div className="flex justify-center items-center w-20 h-20 bg-gray-200 rounded-lg">
                {customer?.isCompany ? (
                  <div className="flex flex-col justify-center items-center">
                    <MdCorporateFare size={40} />
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center">
                    <FiUser size={40} />
                  </div>
                )}
              </div>
              <div className="ml-4">
                <div className="text-lg ">
                  <span className="font-bold mr-1">Customer :</span>
                  <span>{customer?.name}</span>
                </div>
                <div className="">
                  <div className="flex items-center">
                    <div className="">
                      Credit Customer :
                      {customer?.isCredit ? (
                        <span className="text-green-500 ml-1">Yes</span>
                      ) : (
                        <span className="text-red-500 ml-1">No</span>
                      )}
                    </div>
                    <button
                      className="ml-2"
                      onClick={() => setCreditModal(true)}
                    >
                      <FiEdit />
                    </button>
                  </div>
                </div>
                <div className="">
                  {customer?.isCredit ? (
                    <>
                      Credit :
                      <span className="text-green-500 mx-2">
                        [ {formatMoney(customer?.credit)} TZS ]
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="">
              <button
                onClick={() => {
                  setUpdateCustomerModal(true);
                }}
                className="flex items-center px-2 py-1 hover:bg-gray-100"
              >
                <FiEdit />
                <span className="ml-1">Edit</span>
              </button>
            </div>
          </div>
          <div className="mt-4 border-t">
            <div className="grid grid-cols-12 gap-x-6 mt-2">
              <div className="col-span-6 border-r">
                <div className="font-bold uppercase">Customer Profile</div>
                <div className="flex flex-col">
                  <span>
                    Customer Segment :{" "}
                    {customer?.isCompany ? "Company" : "Invidual"}
                  </span>
                  <span>{customer?.phone ? customer?.phone : ""}</span>
                  <span>{customer?.email ? customer?.email : ""}</span>
                  <span>{customer?.address1 ? customer?.address1 : ""}</span>
                  <span>{customer?.address2 ? customer?.address2 : ""}</span>
                  <span>{customer?.address3 ? customer?.address3 : ""}</span>
                  <span>{customer?.address4 ? customer?.address4 : ""}</span>
                </div>

                <div className="flex justify-between items-center mt-8 mb-4 mr-2">
                  <div className="font-bold ">CUSTOMER VEHICLE(S)</div>
                  <button
                    className="border py-1 px-2 bg-green-100"
                    onClick={() => setVehicleModal(true)}
                  >
                    + Add
                  </button>
                </div>
                <div className="">
                  {customer?.vehicles?.length > 0 ? (
                    <div className="">
                      <div className="flex py-2">
                        <div className="w-12">S/N.</div>
                        <div className="w-32">Registration</div>
                        <div className="">Mileage</div>
                      </div>
                      {customer?.vehicles?.map(
                        (vehicle: any, index: number) => (
                          <div className="flex" key={index}>
                            <div className="w-12">{index + 1}.</div>
                            <div className="w-32">{vehicle?.registration}</div>
                            <div className="">
                              {formatNumber(Number(vehicle?.mileage) ?? 0)} KM
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="text-gray-500 text-sm my-2">
                      No Vehicles Found
                    </div>
                  )}
                </div>
              </div>
              <div className="col-span-6">
                <div className="flex justify-between">
                  <div className="">Contact Person(s) / Driver(s)</div>
                  <button
                    className="border py-1 px-2 bg-green-100 hover:bg-green-200"
                    onClick={() => setContactModal(true)}
                  >
                    + Add
                  </button>
                </div>
                <div className="mt-2">
                  {customer?.contacts?.map((item: any, index: number) => (
                    <div className="py-2" key={index}>
                      <div className="flex justify-between">
                        <div className="flex flex-col">
                          <div className="">Name: {item?.name}</div>
                          <div className="">
                            Mobile: {item?.mobile ? item?.mobile : "-"}
                          </div>
                          <div className="">
                            Email: {item?.email ? item?.email : "-"}
                          </div>
                          <div className="">
                            Address: {item?.address ? item?.address : "-"}
                          </div>
                        </div>
                        <div className="">
                          <button
                            onClick={() => {
                              setContact({ ...item });
                              setUpdateContactModal(true);
                            }}
                            className="flex items-center px-2 py-1 hover:bg-gray-100"
                          >
                            <FiEdit />
                            <span className="ml-1">Edit</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactPanel
        customer={customer}
        isVisible={contactModal}
        setVisible={setContactModal}
        refetch={refetch}
        fetch={handleFetchCustomer}
      />
      <UpdateCreditPanel
        customer={customer}
        isVisible={creditModal}
        setVisible={setCreditModal}
        refetch={refetch}
        fetch={handleFetchCustomer}
      />

      <UpdateCustomerPanel
        customer={customer}
        isVisible={updateCustomerModal}
        setVisible={setUpdateCustomerModal}
        refetch={refetch}
        fetch={handleFetchCustomer}
      />

      <UpdateContactPanel
        customer={customer}
        contact={contact}
        isVisible={updateContactModal}
        setVisible={setUpdateContactModal}
        refetch={refetch}
        fetch={handleFetchCustomer}
      />
      <CreateVehiclePanel
        customerId={customer?._id}
        isVisible={vehicleModal}
        setVisible={setVehicleModal}
        refetch={refetch}
        fetch={handleFetchCustomer}
      />
    </Drawer>
  );
}
