import { useEffect, useRef, useState } from "react";

import ReactToPrint from "react-to-print";

import { IoLocationOutline } from "react-icons/io5";
import InvoiceWrapper from "./components/InvoiceWrapper";
import MixiToolnav from "../../components/toolnav/MixiToolnav";
import useQuery from "../../hooks/useQuery";
import CarModel from "../../assets/images/hilux.png";
import fetchDataRoad from "../../services/fetchDataRoad";
import { useParams } from "react-router";
import { BsPrinter } from "react-icons/bs";
import ServiceWrapper from "./components/ServiceWrapper";
import ServiceOffered from "./components/ServiceOffered";
import LicenseWrapper from "./components/LicenseWrapper";
import RegistrationWrapper from "./components/RegistrationWrapper";
import CompleteWidget from "./components/service/CompleteWidget";
import StatusWidget from "./components/service/StatusWidget";
import TermsWrapperEn from "./components/TermsWrapperEn";
import TermsWrapperSw from "./components/TermsWrapperSw";
import ExPhotosWrapper from "./components/ExPhotosWrapper";
import InPhotosWrapper from "./components/InPhotosWrapper";
import WlPhotosWrapper from "./components/WlPhotosWrapper";

export default function OneService() {
  const [editable, setEditable] = useState(false);
  const [service, setService] = useState<any>({});

  const { id } = useParams();

  const jobRef = useRef(null);
  const handleFetchService = async () => {
    const uri = `services/${id}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setService(response?.payload);
    }
  };

  let query = useQuery();
  let redirect = query.get("redirect");

  useEffect(() => {
    handleFetchService();
  }, []);

  return (
    <>
      <MixiToolnav
        link={redirect}
        links={[{ name: "Customer Service", to: `` }]}
      />
      <div className="md:flex px-4 sm:px-4 lg:px-4 py-2">
        <div className="bg-white align-middle min-w-full border overflow-hidden sm:rounded-lg">
          <div style={{ fontSize: 12 }} className="grid gap-x-0 grid-cols-12">
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <div className="flex justify-between items-center border-b bg-white mt-2 py-2">
                <div className="flex flex-row">
                  <div className="flex items-center mx-4">
                    <img src={CarModel} className="w-20 h-20" alt="" />
                  </div>
                  <div className="flex flex-col justify-center">
                    <div className="text-lg font-bold text-gray-700">
                      Roadside Assistance
                    </div>
                    <div className="text-md">
                      <span className="font-bold ">Customer : </span>
                      <span>{service?.customer?.name}</span>
                    </div>
                    <div className="flex text-gray-600">
                      <div className="">
                        <span className="font-bold">Technician : </span>
                        <span>{service?.technician?.name}</span>
                      </div>
                    </div>

                    <div className="my-2 justify-center">
                      {service?.isApproved ? (
                        <span className="text-gray-500 border p-1 bg-green-50">
                          Service approved
                        </span>
                      ) : (
                        <span className="text-gray-500 border p-1 bg-gray-50">
                          Awaiting Approval
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="ml-4">
                    <div className="text-gray-600 my-1">
                      <span className="font-bold mr-1">
                        Vehicle Registration :
                      </span>
                      {service?.vehicle?.registration}
                    </div>
                    <div className="text-gray-600">
                      <span className="font-bold mr-1">Vehicle Model :</span>
                      {service?.vehicle?.model?.name}
                    </div>
                    <div className="flex items-center">
                      <span className="font-bold mr-1">
                        Service Location :{" "}
                      </span>
                      <IoLocationOutline />
                      <span className="ml-1">{service?.location}</span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col border-l">
                  <StatusWidget service={service} />
                </div>
              </div>

              <div className="grid gap-x-0 grid-cols-12 bg-gray-100">
                <div className="col-span-12 sm:col-span-9 md:col-span-9 bg-gray-100">
                  <div className="flex flex-row bg-whitish  justify-start items-start overflow-auto">
                    <div className="bg-white flex flex-col justify-between items-center">
                      <div className="bg-grayish px-10">
                        <div className="" ref={jobRef}>
                          <ServiceWrapper
                            jobRef={jobRef}
                            service={service}
                            jobno={1}
                            refetch={handleFetchService}
                            editable={editable}
                            setEditable={setEditable}
                          />
                          {service?.isTowed && (
                            <>
                              <ExPhotosWrapper
                                service={service}
                                refetch={handleFetchService}
                                editable={editable}
                              />
                              <InPhotosWrapper
                                service={service}
                                refetch={handleFetchService}
                                editable={editable}
                              />
                              <WlPhotosWrapper
                                service={service}
                                refetch={handleFetchService}
                                editable={editable}
                              />
                            </>
                          )}
                          {service?.isKeyout && (
                            <>
                              <LicenseWrapper
                                service={service}
                                refetch={handleFetchService}
                                editable={editable}
                              />
                              <RegistrationWrapper
                                service={service}
                                refetch={handleFetchService}
                                editable={editable}
                              />
                            </>
                          )}

                          <TermsWrapperEn />
                          <TermsWrapperSw />

                          <InvoiceWrapper
                            jobRef={jobRef}
                            service={service}
                            jobno={1}
                            refetch={handleFetchService}
                            editable={editable}
                            setEditable={setEditable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12  sm:col-span-3 md:col-span-3 bg-whitish overflow-auto">
                  <div className="flex flex-col justify-between  bg-white p-2 border-b">
                    <div className="flex justify-between mt-2">
                      <div className="w-1/2">
                        {!editable ? (
                          <ReactToPrint
                            trigger={() => {
                              return (
                                <button className="flex items-center border rounded-sm p-2 focus:outline-none hover:text-red-500 w-full text-left px-2">
                                  <BsPrinter size={15} className="mr-2" /> Print
                                  Document
                                </button>
                              );
                            }}
                            content={() => jobRef.current}
                            documentTitle={`Document`}
                          />
                        ) : (
                          <button
                            disabled
                            className="flex  items-center border rounded-sm p-2 focus:outline-none bg-gray-100 cursor-not-allowed w-full text-left px-2"
                          >
                            <BsPrinter size={15} className="mr-2" /> Print
                            Document
                          </button>
                        )}
                      </div>
                      <div className="w-1/2">
                        <CompleteWidget
                          service={service}
                          refetch={handleFetchService}
                        />
                      </div>
                    </div>

                    <ServiceOffered
                      service={service}
                      refetch={handleFetchService}
                      jobRef={jobRef}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
