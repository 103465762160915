import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useState } from "react";
import postDataRoad from "../../../../services/postDataRoad";

type RequiredMark = boolean | "optional";

export default function CreateModel({ isVisible, setVisible, refetch }: any) {
  const [isLoading, setLoading] = useState(false);
  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();
    const uri = "models";
    const response = await postDataRoad(values, uri);
    setLoading(false);
    setVisible(false);
    if (response.success) {
      refetch();
      message.success("Model added successfully");
    } else {
      message.error(response.message);
    }
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title="Create Model"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createModel"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createModel"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
        initialValues={{ requiredMarkValue: requiredMark }}
        requiredMark={requiredMark}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Model name"
              rules={[
                {
                  required: true,
                  message: "Please enter model name",
                },
              ]}
            >
              <Input placeholder="Model name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
