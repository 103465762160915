import { Form, Input, message } from "antd";
import { useState } from "react";
import postDataRoad from "../../../services/postDataRoad";
import getBase64 from "../../../utils/getBase64";

export default function LicenseWrapper({ service, refetch, editable }: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  if (service?.isKeyout === undefined) {
    return <></>;
  }

  const changeHandler = async (event: any) => {
    setLoading(true);
    const base64 = await getBase64(event.target.files[0]);
    const uri = `services/${service?._id}/driver/card`;
    const data = { file: base64, service: service?._id, code: "driver" };
    const res = await postDataRoad(data, uri);
    if (res?.success) {
      message.success("Photo uploaded successfully");
      refetch();
    } else {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  return (
    <div className="book" id="invoice-doc">
      <div className="page">
        <div className="subpage2">
          <div className="p-1">
            <div style={{ fontSize: 15 }} className="flex justify-center mb-12">
              DRIVER'S LICENSE / NIDA CARD / PASSPORT
            </div>
            <div className="flex flex-1 justify-center items-center">
              {service?.driverCard ? (
                <div className="">
                  <img src={service?.driverCard} width={420} />
                </div>
              ) : (
                <div className="bg-gray-100 border p-2">
                  <div className="opacity-10">
                    <img
                      alt=""
                      src={
                        "https://kjl-roadside.s3.eu-west-1.amazonaws.com/assets/drivercard.jpeg"
                      }
                    />
                  </div>
                  <div className="opacity-100 -mt-6">
                    <Form.Item
                      name="file"
                      rules={[
                        {
                          required: false,
                          message: "Please select file",
                        },
                      ]}
                    >
                      <Input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        placeholder="Document"
                        onChange={(e) => changeHandler(e)}
                      />
                    </Form.Item>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
