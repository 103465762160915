import { useEffect, useState } from "react";
import HomeToolnav from "../../components/toolnav/HomeToolnav";
import CustomerWidget from "./components/CustomerWidget";
import MainChart from "./components/MainChart";
import CurrentWidget from "./components/CurrentWidget";
import SummaryWidget from "./components/SummaryWidget";
import VehicleWidget from "./components/VehicleWidget";
import TodateWidget from "./components/TodateWidget";
import fetchDataRoad from "../../services/fetchDataRoad";

export default function Dashboard() {
  const year = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(year);
  const [data, setData] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const records = [{ year: year - 2 }, { year: year - 1 }, { year: year }];

  const handleFetchData = async () => {
    setLoading(true);
    const uri = `services/reports/years/${selectedYear}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setData(response?.payload);
    }
    setLoading(false);
  };

  const y = records.map((record: any, i: any) => (
    <button
      className={
        selectedYear === record.year
          ? "rounded-sm border border-gray-200 p-2 focus:outline-none text-xs hover:bg-gray-200 ml-2 bg-gray-200"
          : "rounded-sm border border-gray-200 p-2 focus:outline-none text-xs hover:bg-gray-200 ml-2"
      }
      onClick={() => setSelectedYear(record?.year)}
    >
      <div className="flex justify-between items-center text-gray-800">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-calendar"
        >
          <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
          <path d="M16 2v4M8 2v4M3 10h18" />
        </svg>
        <span className="ml-2">{record.year}</span>
      </div>
    </button>
  ));

  useEffect(() => {
    handleFetchData();
  }, [selectedYear]);

  return (
    <>
      <HomeToolnav link={{ name: "Dashboard", to: "/dashboard" }} />
      <div className="grid grid-cols-12 gap-x-2 mx-4 my-2">
        <div className="col-span-12 sm:col-span-12">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 sm:col-span-4 border rounded-md bg-white">
              <CurrentWidget year={selectedYear} />
            </div>
            <div className="col-span-12 sm:col-span-4 border rounded-md bg-white">
              <TodateWidget
                isLoading={isLoading}
                data={data}
                year={selectedYear}
              />
            </div>
            <div className="col-span-12 sm:col-span-2 border rounded-md bg-white">
              <CustomerWidget />
            </div>
            <div className="col-span-12 sm:col-span-2 border rounded-md bg-white">
              <VehicleWidget />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-x-2">
            <div className="col-span-12 sm:col-span-8">
              <div className="grid grid-cols-12 mt-2">
                <div className="col-span-12 border h-auto rounded-md bg-white p-4">
                  <div className="flex flex-row justify-between items-center  border-b border-gray-200 px-6 mb-4">
                    <div className="">
                      <h4 className="text-gray-800">
                        <span className="font-bold text-lg">
                          Services Summary :
                        </span>
                        <span className="ml-1">{selectedYear}</span>
                      </h4>
                      <p className="text-gray-600 text-sm">
                        Services fullfiled by value
                      </p>
                    </div>

                    <div className="">{y}</div>
                  </div>
                  <MainChart isLoading={isLoading} data={data} />
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-4">
              <SummaryWidget isLoading={isLoading} data={data} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
