export default function FourSw() {
  return (
    <div className="mt-2">
      <div style={{ fontSize: 12 }}>Huduma Tusizotoa</div>
      <div className="">
        Bidhaa au huduma yoyote ambayo haijaoredheshwa au kuelezewa kwenye:
      </div>
      <div className="">
        <div className="">a) Mchanganuo wa gharama za huduma zetu, au</div>
        <div className="">b) Vigezo & Masharti haya.</div>
      </div>
    </div>
  );
}
