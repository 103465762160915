import * as React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

/*
|---------------------------------------------------------------------
|                             Main
|---------------------------------------------------------------------
*/

import AuthLayout from "./layouts/AuthLayout";
import HomeLayout from "./layouts/HomeLayout";
import Dashboard from "./pages/dashboard/Dashboard";
import MainLayout from "./layouts/MainLayout";
import Signin from "./pages/auth/Signin";

import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import AllCustomers from "./pages/customers/AllCustomers";
import AllServices from "./pages/services/AllServices";
import OneService from "./pages/services/OneService";
import OneVehicle from "./pages/vehicles/OneVehicle";
import Page404 from "./pages/404";

import AllVehicles from "./pages/vehicles/AllVehicles";
import CusServices from "./pages/customers/CusServices";
import CusDetails from "./pages/customers/CusDetails";
import Vehicles from "./pages/manage/vehicles/Vehicles";
import Customers from "./pages/manage/customers/Customers";
import Models from "./pages/manage/models/Models";
import Users from "./pages/manage/users/Users";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";

import "antd/dist/antd.min.css";
import "./custom.css";
import "./App.css";
import PrvService from "./pages/services/PrvService";
import Manage from "./pages/manage/main";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<Signin />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/services/preview/:id" element={<PrvService />} />
        </Route>
        <Route element={<HomeLayout />}></Route>
        <Route element={<MainLayout />}>
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/customers"
            element={
              <RequireAuth>
                <AllCustomers />
              </RequireAuth>
            }
          />
          <Route
            path="/customers/:id/services"
            element={
              <RequireAuth>
                <CusServices />
              </RequireAuth>
            }
          />
          <Route
            path="/customers/:id/details"
            element={
              <RequireAuth>
                <CusDetails />
              </RequireAuth>
            }
          />
          <Route
            path="customers/:cid/services/:id"
            element={
              <RequireAuth>
                <OneService />
              </RequireAuth>
            }
          />
          <Route
            path="services"
            element={
              <RequireAuth>
                <AllServices />
              </RequireAuth>
            }
          />
          <Route
            path="services/:id"
            element={
              <RequireAuth>
                <OneService />
              </RequireAuth>
            }
          />
          <Route
            path="/vehicles"
            element={
              <RequireAuth>
                <AllVehicles />
              </RequireAuth>
            }
          />
          <Route
            path="/vehicles/:id"
            element={
              <RequireAuth>
                <OneVehicle />
              </RequireAuth>
            }
          />

          <Route
            path="/manage"
            element={
              <RequireAuth>
                <Manage />
              </RequireAuth>
            }
          />
          <Route
            path="/manage/customers"
            element={
              <RequireAuth>
                <Customers />
              </RequireAuth>
            }
          />
          <Route
            path="/manage/vehicles"
            element={
              <RequireAuth>
                <Vehicles />
              </RequireAuth>
            }
          />
          <Route
            path="/manage/users"
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />
          <Route
            path="/manage/models"
            element={
              <RequireAuth>
                <Models />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </AuthProvider>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = React.useContext(AuthContext);
  let location = useLocation();
  if (!auth.token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
}
