export default function Four() {
  return (
    <div className="mt-2">
      <div style={{ fontSize: 12 }}>What we don't cover</div>
      <div className="">
        Any goods or services not otherwise listed or described in:
      </div>
      <div className="">
        <div className="">a) The pricing menu, or</div>
        <div className="">b) This Terms & Conditions.</div>
      </div>
    </div>
  );
}
