import { Button, Form, Input, message, Popover, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { BsSortUp } from "react-icons/bs";
import { useNavigate, useParams } from "react-router";
import Wrapper from "../../components/shared/Wrapper";
import formatMoney from "../../utils/formatMoney";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../utils/exportExcel";
import NoResults from "../../components/shared/NoResults";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import postDataMain from "../../services/postDataMail";
import fetchDataArpsPage from "../../services/fetchDataArpsPage";
import MixiToolnav from "../../components/toolnav/MixiToolnav";
import useQuery from "../../hooks/useQuery";
import fetchDataRoad from "../../services/fetchDataRoad";
import moment from "moment";
import Moment from "react-moment";
import CreateService from "../services/components/CreateService";

export default function CusServices() {
  const [services, setServices] = useState<any>([]);
  const [selected, setSelected] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);

  let query = useQuery();
  let name = query.get("name");

  const { id } = useParams();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    limit: 10,
  });

  const navigate = useNavigate();

  const [emailForm] = Form.useForm();
  const curUrl = `/customers/${id}/services?name=${name}`;

  const handleFetchServices = async () => {
    setLoading(true);
    const uri = `services/customer/${id}`;
    const response = await fetchDataRoad({ uri });
    setServices(response?.payload);
    setLoading(false);
  };

  const handleReFetchServices = async ({ pagination }: any) => {
    const data = await fetchDataArpsPage(pagination);
    setPagination({ total: data?.count, ...pagination });
    setServices(data?.results);
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = services?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Customer: item?.customer?.name === null ? "-" : item?.customer?.name,
        "Vehicle Registration":
          item?.vehicle?.registration === null
            ? "-"
            : item?.vehicle?.registration,
        "Vehicle Model":
          item?.vehicle?.model?.name === null
            ? "-"
            : item?.vehicle?.model?.name,
        Technician:
          item?.technician?.name === null ? "-" : item?.technician?.name,
        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
        Status: item?.status === null ? "-" : item?.status,
        Amount: formatMoney(getTotal(item?.items)),
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMain(maildata, mailuri);
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.amount;
    });

    return total;
  };

  const dummy = new Array(12).fill({ id: 1 });

  useEffect(() => {
    handleFetchServices();
  }, []);
  return (
    <>
      <MixiToolnav
        link="/customers"
        links={[
          { name: "Customer Services", to: `${curUrl}` },
          {
            name: "Customer Details",
            to: `/customers/${id}/details/?name=${name}`,
          },
        ]}
      />
      <Wrapper
        title={`SERVICES`}
        subtitle={`${name}`}
        description={`List of all customer services`}
        actions={
          <div className="flex">
            <Popover
              content={
                <>
                  <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    form={emailForm}
                    onFinish={sendMail}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is Required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        { required: true, message: "Subject is Required" },
                      ]}
                    >
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <div className="flex flex-row justify-between">
                      <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                        <Button type="primary" htmlType="submit">
                          {isMailLoading ? "Sending ..." : "Submit"}
                        </Button>
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                        <Button
                          onClick={() => {
                            toggleMessageModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              }
              title="Message Content"
              trigger="focus"
              placement="left"
              visible={messageModal}
            >
              <Tooltip title="Send Email">
                <button
                  className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={async () => {
                    toggleMessageModal(true);
                  }}
                >
                  <RiMailSendLine size={21} />
                </button>
              </Tooltip>
            </Popover>
            <Tooltip title="Export To Excel">
              <button
                color="#E50000"
                className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={() =>
                  ExportToExcel(
                    services?.map((item: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        Customer:
                          item?.customer?.name === null
                            ? "-"
                            : item?.customer?.name,
                        "Vehicle Registration":
                          item?.vehicle?.registration === null
                            ? "-"
                            : item?.vehicle?.registration,
                        "Vehicle Model":
                          item?.vehicle?.model?.name === null
                            ? "-"
                            : item?.vehicle?.model?.name,
                        Technicians:
                          item?.technician?.name === null
                            ? "-"
                            : item?.technician?.name,
                        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
                        Status: item?.status === null ? "-" : item?.status,
                        Amount: formatMoney(getTotal(item?.items)),
                      };
                    }),
                    "Services.xlsx"
                  )
                }
              >
                <RiFileExcel2Line size={20} />
              </button>
            </Tooltip>
            {/* <Button onClick={() => setCreateModal(true)}>+ New</Button> */}
          </div>
        }
      >
        <div className="flex flex-col">
          <div className="flex flex-col w-full">
            <div className="grid gap-x-0 grid-cols-12">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                <div className="m-6 bg-white rounded-md">
                  <div className="grid grid-cols-12 gap-0 bg-gray-50 py-2 px-4 border-b border-t mb-2">
                    <div className="col-span-12 sm:col-span-1">S/N</div>
                    {/* <div className="col-span-12 sm:col-span-3">
                      <div className="flex  items-center">
                        <BsSortUp />
                        Customer
                      </div>
                    </div> */}
                    <div className="col-span-12 sm:col-span-2">
                      <div className="flex  items-center">
                        <BsSortUp />
                        Vehicle
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-2">
                      <div className="flex  items-center">
                        <BsSortUp />
                        Vehicle Model
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-3">
                      <div className="flex  items-center">
                        <BsSortUp />
                        Technician
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-2">
                      <div className="flex  items-center">
                        <BsSortUp />
                        Created At
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-1">
                      <div className="flex  items-center">
                        <BsSortUp />
                        Status
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-1 text-center">
                      Amount
                    </div>
                  </div>
                  <div className="">
                    {isLoading ? (
                      <>
                        {dummy?.map((claim: any, index: number) => (
                          <div
                            className=" h-10 mb-2 hover:cursor-pointer bg-gray-100"
                            key={index}
                          >
                            <div className="grid grid-cols-12 gap-0 py-2 px-4 text-gray-100">
                              <div className="col-span-12 sm:col-span-1">-</div>
                              <div className="col-span-12 sm:col-span-2">-</div>
                              <div className="col-span-12 sm:col-span-2">-</div>
                              <div className="col-span-12 sm:col-span-3">-</div>
                              <div className="col-span-12 sm:col-span-2">-</div>
                              <div className="col-span-12 sm:col-span-1 text-right px-2">
                                -
                              </div>
                              <div className="col-span-12 sm:col-span-1 text-center">
                                -
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {services?.length === 0 || services === null ? (
                          <div className="flex justify-center items-center h-96">
                            <NoResults title="No Services Found" />
                          </div>
                        ) : (
                          <>
                            {services?.map((service: any, index: number) => (
                              <div
                                className=" h-10 mb-2 hover:cursor-pointer hover:bg-blue-50 border-b border-gray-100"
                                onClick={() =>
                                  navigate(
                                    `/customers/${id}/services/${service?._id}?redirect=${curUrl}`
                                  )
                                }
                                key={index}
                              >
                                <div className="grid grid-cols-12 gap-0 py-2 px-4 text-gray-600">
                                  <div className="col-span-12 sm:col-span-1">
                                    {index + 1}
                                  </div>
                                  <div className="col-span-12 sm:col-span-2">
                                    {service?.vehicle?.registration
                                      ? service?.vehicle?.registration
                                      : "-"}
                                  </div>
                                  <div className="col-span-12 sm:col-span-2">
                                    {service?.vehicle?.model?.name
                                      ? service?.vehicle?.model?.name
                                      : "-"}
                                  </div>
                                  <div className="col-span-12 sm:col-span-3">
                                    {service?.technician?.name
                                      ? service?.technician?.name
                                      : "-"}
                                  </div>
                                  <div className="col-span-12 sm:col-span-2">
                                    <Moment fromNow>
                                      {service?.createdAt}
                                    </Moment>
                                  </div>
                                  <div className="col-span-12 sm:col-span-1">
                                    {service?.status ? service?.status : "-"}
                                  </div>

                                  <div className="col-span-12 sm:col-span-1 text-right px-2">
                                    {formatMoney(getTotal(service?.items))}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <CreateService
                isVisible={createModal}
                setVisible={setCreateModal}
                selected={id}
                isCust={true}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}
