export default function ThreeSw() {
  return (
    <div className="mt-1">
      <div className="">
        <div style={{ fontSize: 12, marginTop: 2 }}>
          Manufaa na masharti ya Msaada wa Toyota Tanzania Pembezone mwa
          Barabara kwa saa
        </div>
        <div className="font-bold">Huduma kwa Magari</div>
        <div className="">
          Tutafanya utaratibu, kama inawezekana na ikiwa ni salama kufanya
          hivyo, kurekebisha hitilafu zinazojitokeza mara nyingi zaidi ikiwemo
          pancha, kubadilisha gurudumu, gari kushindwa kupokea moto vizuri au
          kubadilisha betri yeneye hitilafu. Inapofaa, huduma inaweza pia
          kujumuisha kutoa dondoo au ushauri kuhusu mambo unayoweza kufanya
          kwenye gari, viashiria vya kiusalama au cheche zinazoweza kutokea au
          maelekezo kuhusu unachoweza kufanya.
        </div>

        <div className="font-bold mt-1">Mategenezo Madogo kwa Magari</div>
        <div className="">
          Tutafanya utaratibu kwa ajili ya kufanya matengenezo madogo endapo
          inawezekana na ni salama kufanya hivyo katika eneo ambapo hitilafu
          imetokea. Hata hivyo, endapo matengenezo makubwa au uchunguzi zaidi
          utahitajika, gari litapelekwa kwa wakala wa Toyota Tanzania Dar es
          Salaam. Utawajibika kulipia matengenezo, ikiwemo nguvu kazi na vipuri
          vitakavohitajika.
        </div>

        <div className="font-bold mt-1">Betri Dhaifu</div>
        <div className="">
          Kama betri lako ni dhaifu, tutafanya utaratibu wa kuwasha gari lako
          kwa kutumia gari lingine au kukupatia betri lingine kama itahitajika.
        </div>

        <div className="font-bold mt-1">Mafuta ya Dharura</div>
        <div className="">
          Endapo utaishiwa mafuta njiani (petroli au dizeli), tutafanya
          utaratibu wa kukupatia mafuta ili uweze kufikia kituo cha mafuta
          kilicho karibu. Kiwango cha juu cha mafuta tunachoweza kukupatia ni
          lita 10 kwa bei ya soko. kwa ada kama ilivyoorodheshwa kwenye menyu ya
          bei/mchanganuo wa gharama
        </div>

        <div className="font-bold mt-1">Gurudumu Kuishiwa Upepo</div>
        <div className="">
          Endapo gurudumu la gari lako litaishiwa upepo, tutafanya utaratibu wa
          kulibadlisha, endapo utakuwa na lingine kwenye gari lako; au kuziba
          pancha.
        </div>
        <div className="font-bold mt-1">
          Funguo Zilizopotea au Zilizofungiwa
        </div>
        <div className="">
          Endapo funguo ya gari lako itapotea, kuibiwa au kufungiwa ndani ya
          gari, tutatoa msaada wa kunasua funguo.
        </div>
        <div style={{ fontSize: 8 }} className="my-2 italic">
          Nakala fanani za nyaraka zifuatazo zitahitaika zitahitajika kabla ya
          kupatiwa msaada wowote: Kadi ya usajili wa gari, leseni ya kuendesha
          gari au kitambulisho cha NIDA.
        </div>
        <div className="">
          Endapo utasisitiza kuvunja sehemu ya gari ili kupata funguo
          zilizofungiwa, hatutahusika kwa vyovyote vile, na upotevu au uharibifu
          utakaotokea kwenye gari lako.
        </div>
        <div className="font-bold mt-1">Huduma ya Kukotoa Gari</div>
        <div className="">
          Endapo gari halitaweza kutembea bararabani, tutafanya utaratibu wa
          kulikokota hadi kwa Wakala wa Toyota Tanzania Dar es Salaam. Huduma
          hii inatozwa, na inapatikana ndani ya mipaka ya Jiji la Dar es Salaam
          tu. Tafadhali zingatia kwamba utalipia huduma ya kukokota gari na
          matengenezo yoyote yatakayofanywa.
        </div>
        <div className="font-bold mt-1">Usafiri Mbadala</div>
        <div className="">
          Endapo hutaweza kusubiri matengenezo ya gari lako sehemu lilipota
          hitilafu, au kama gari lako litahitaji kukokotwa, tutafanya utaratibu
          wa kukupatia usafiri mwingine kwenda sehemu unayotaka. Usafiri mbadala
          unatolewa na Bolt Tanzania, kwa usalama na ufanisi wako. Tafadhali
          zingatia kwamba utalipia usafiri mbadala. Kwa kuchagua usafiri
          mbadala, unakubaliana na Vigezo na Masharti ya Bolt Tanzania, na
          kutupa nafuu ya kukutafutia usafiri mbadala.
        </div>
      </div>
    </div>
  );
}
