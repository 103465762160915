import { Button, Drawer, Space } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import formatMoney from "../../../utils/formatMoney";
export default function ItemsPanel({ setVisible, isVisible, selected }: any) {
  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + (item?.labourrate + item?.partsrate);
    });
    return isNaN(total) ? 0 : total;
  };

  const total = getTotal(selected?.items);

  return (
    <Drawer
      title={`${selected?.customer?.name} - ${selected?.vehicle?.registration}`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="font-toyota">
        <div className="flex py-1 border-b border-gray-300">
          <div className="mr-2 w-10 text-center font-bold">S/N.</div>
          <div className="flex justify-between items-center flex-1">
            <div className="flex items-center">
              <div className="w-52 truncate font-bold">Description</div>
              <div className="w-64 truncate text-left font-bold">Remarks</div>
            </div>
            <div className="font-bold w-full">
              <div className="flex">
                <div className="mr-2 text-left flex-1"></div>
                <div className="truncate flex justify-end">Amount</div>
              </div>
            </div>
          </div>
        </div>
        {selected?.items?.map((item: any, index: number) => (
          <div className="flex py-1 border-b border-gray-300" key={index}>
            <div className="mr-2 w-10 text-center">{index + 1}.</div>
            <div className="flex justify-between items-center flex-1">
              <div className="flex items-center">
                <div className="w-52">
                  {item?.callout_desc === null ? "-" : item?.callout_desc}
                </div>
                <div className="w-64 truncate">
                  {item?.remarks === null ? "-" : item?.remarks}
                </div>
              </div>
              <div className="w-full">
                <div className="flex">
                  <div className="mr-2 text-left flex-1"></div>
                  <div className="truncate ml-2 px-2 py-1 flex justify-center items-center">
                    <span className="ml-2">
                      {formatMoney(item?.labourrate + item?.partsrate)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="flex justify-between">
          <div className="w-2/3"></div>
          <div className="w-1/3">
            <div className="flex justify-between items-start p-1">
              <div className="ml-2">Sub Total </div>
              <div className="">{formatMoney(total)}</div>
            </div>
            <div className="flex justify-between items-start border-t p-1">
              <div className="ml-2">VAT </div>
              <div className="">{formatMoney(total * 0.18)}</div>
            </div>
            <div className="flex justify-between items-start border-t border-b border-gray-500 p-1">
              <div className="font-bold ml-2">Grand Total </div>
              <div className="font-bold">{formatMoney(total * 1.18)}</div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
