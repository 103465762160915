import { Button, Drawer, Form, Space, Input, Col, Row, message } from "antd";
import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import patchDataRoad from "../../../../services/patchDataRoad";
export default function UpdateOffer({
  isVisible,
  setVisible,
  offer,
  refetch,
}: any) {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    const values = await form.validateFields();
    console.log(values);
    const uri = `offers/${offer?._id}`;
    const data = values;
    const response = await patchDataRoad(data, uri);
    if (response?.success) {
      message.success("Updated Successfully");
      refetch();
      setVisible(false);
    }
  };

  const clearForm = () => {
    form.resetFields();
  };

  const hidePanel = () => {
    setVisible(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [offer]);

  return (
    <Drawer
      title={`UPDATE: ${offer?.callout_desc}`}
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="font-toyota">
        <Form
          id="createVehicle"
          form={form}
          name="normal_login"
          className="login-form"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="callout_desc"
                label="Call out Description"
                initialValue={offer?.callout_desc}
                rules={[
                  {
                    required: true,
                    message: "Please enter call out description",
                  },
                ]}
              >
                <Input placeholder="Call out Description" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="invoice_desc"
                label="Invoice description"
                initialValue={offer?.invoice_desc}
                rules={[
                  {
                    required: true,
                    message: "Please enter invoice description",
                  },
                ]}
              >
                <Input placeholder="Invoice description" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="flattime"
                label="Flat Time ( Hrs )"
                initialValue={offer?.flattime}
                rules={[
                  {
                    required: true,
                    message: "Please enter flat time",
                  },
                ]}
              >
                <Input placeholder="Flat Time ( Hrs )" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="timetaken"
                label="Time Taken ( Hrs )"
                initialValue={offer?.timetaken}
                rules={[
                  {
                    required: true,
                    message: "Please enter time taken",
                  },
                ]}
              >
                <Input placeholder="Time Taken ( Hrs )" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="labourrate"
                label="Labour Rate ( TZS )"
                initialValue={offer?.labourrate}
                rules={[
                  {
                    required: true,
                    message: "Please enter labour rate",
                  },
                ]}
              >
                <Input placeholder="Labour Rate ( TZS )" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="taxrate"
                label="Tax Rate ( TZS )"
                initialValue={offer?.taxrate}
                rules={[
                  {
                    required: true,
                    message: "Please enter tax rate",
                  },
                ]}
              >
                <Input placeholder="Tax Rate ( TZS )" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="partsrate"
                label="Parts each / Per Pc / Ltr"
                initialValue={offer?.partsrate}
                rules={[
                  {
                    required: true,
                    message: "Please enter amount",
                  },
                ]}
              >
                <Input type="number" placeholder="Amount" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button danger type="primary" htmlType="submit">
              Submit
            </Button>
            <Button
              danger
              htmlType="button"
              className="mx-2"
              onClick={clearForm}
            >
              Reset
            </Button>
            <Button
              danger
              htmlType="button"
              className="mx-2"
              onClick={hidePanel}
            >
              Close
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}
