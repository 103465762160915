import Logo from "../../../assets/images/logo.png";
import QRCode from "../../../assets/images/qrcode.png";
export default function DocumentLogo() {
  return (
    <>
      <div
        style={{ fontSize: 11 }}
        className="border border-black py-4 px-2 flex flex-col justify-between items-center text-xs"
      >
        <div className="flex flex-row justify-between items-center w-full py-4">
          <div className="flex flex-col justify-center items-center">
            <img src={QRCode} className="w-28" />
            <div className="">80870-675</div>
          </div>
          <img src={Logo} className="w-28" />
          <div className="flex flex-col justify-between">
            <div className="flex flex-col">
              <span className="">TOYOTA TANZANIA LIMITED</span>
              <span className="">P.O BOX 9060</span>
              <span className="">No.5 NYERERE ROAD</span>
              <span className="">DAR ES SALAAM</span>
              <span className="">TANZANIA</span>
            </div>
            <div className="flex flex-col mt-2">
              <div className="flex">
                <div className="font-bold mr-2">TIN : </div>100-146-304
              </div>
              <div className="flex">
                <div className="font-bold mr-2">VRN : </div>10-0066645-E
              </div>
            </div>
          </div>
        </div>
        <div className="w-full -my-8 mb-4 -ml-8 flex flex-row justify-center items-center text-2xl font-bold">
          INVOICE
        </div>
      </div>
    </>
  );
}
