import Vehicle from "./service/Vehicle";
import Attended from "./service/Attended";
import Customer from "./service/Customer";
import Vinnumber from "./service/Vinnumber";
import Nature from "./service/Nature";
import Additional from "./service/Additional";
import Inspection from "./service/Inspection";
import { BsCheck2 } from "react-icons/bs";

export default function ServiceWrapper({ service, refetch }: any) {
  if (service === undefined) {
    return <></>;
  }

  return (
    <div className="book" id="invoice-doc" style={{ fontSize: 12 }}>
      <div className="page">
        <div className="subpage2">
          <div className="p-1">
            <div style={{ fontSize: 15 }} className="flex justify-center">
              24/7 ROADSIDE ASSISTANCE SERVICE FORM
            </div>
            <Customer service={service} />
            <Attended service={service} />
            <Vehicle vehicle={service?.vehicle} />
            <Vinnumber service={service} refetch={refetch} />
            <Nature service={service} />
            <Additional service={service} refetch={refetch} />
            <Inspection service={service} refetch={refetch} />
            <div className="">
              <div className="mt-4">
                <div className="">Inspected by</div>
                <div className="">Name : {service?.technician?.name}</div>
                <div className="">Signature :</div>
                <div className="">
                  {service?.technicianSignature ? (
                    <img
                      src={`${service?.technicianSignature}`}
                      className="w-36 h-8"
                    />
                  ) : (
                    <div className="w-36 h-8 border mt-1"></div>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <div className="">Authorized by</div>
                <div style={{ fontSize: 9 }} className="">
                  By my signature here I: (a) acknowledge that I have read,
                  understood and agreed to the terms and conditions on the
                  reverse here of, and (b) confirm that I have removed all
                  personal effects and valuables from the vehicle prior to
                  handling over the vehicle for towing
                  <span className="italic">(if applicable).</span>
                </div>
                <div className="mt-4">
                  <div className="">Name: {service?.contact?.name}</div>
                  <div className="">Signature:</div>
                  <div className="">
                    {service?.customerSignature ? (
                      <img
                        src={`${service?.customerSignature}`}
                        className="w-36 h-8"
                      />
                    ) : (
                      <div className="w-36 h-8 border mt-1"></div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex mt-4">
                <div className="h-4 w-4 border border-black  flex justify-center items-center mr-2">
                  {service?.isTermsRead ? <BsCheck2 size={25} /> : ""}
                </div>
                I acknowledge that I have read and understand the
                <span className="underline ml-1">Terms & Conditions</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
