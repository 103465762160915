import fetchDataRoad from "../services/fetchDataRoad";
import { ExportToExcel } from "./exportExcel";

const fetchAllVehicles = async () => {
  const response = await fetchDataRoad({ uri: "vehicles" });
  if (response?.success) {
    ExportToExcel(
      response?.payload?.map((item: any, index: number) => {
        return {
          "S/N": index + 1,
          Registration: item?.registration === null ? "-" : item?.registration,
          Customer: item?.customer?.name === null ? "-" : item?.customer?.name,
          Model: item?.model?.name === null ? "-" : item?.model?.name,
          Year: item?.year === null ? "-" : item?.year,
          Mileage: item?.mileage === null ? "-" : item?.mileage,
          Fuel: item?.fuel === null ? "-" : item?.fuel,
          Chassis: item?.chassis === null ? "-" : item?.chassis,
        };
      }),
      "Vehicles.xlsx"
    );
  }
};

const fetchAllCustomers = async () => {
  const response = await fetchDataRoad({ uri: "customers" });
  if (response?.success) {
    ExportToExcel(
      response?.payload?.map((item: any, index: number) => {
        return {
          "S/N": index + 1,
          Name: item?.name || "-",
          Address1: item?.address1 || "-",
          Address2: item?.address2 || "-",
          Address3: item?.address3 || "-",
          Address4: item?.address4 || "-",
          Email: item?.email || "-",
          Mobile: item?.phone || "-",
          Segment: item?.isCompany ? "Company" : "Individual",
          "Credit Customer": item?.isCredit ? "YES" : "NO",
          "Credit Amount": item?.credit || "-"
        };
      }),
      "Customers.xlsx"
    );
  }
};

export { fetchAllVehicles, fetchAllCustomers };
