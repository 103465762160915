import { useState } from "react";
import { Button, Form, Row, Col, Input, message, Drawer, Space } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import postDataRoad from "../../../services/postDataRoad";

export default function ContactPanel({
  customer,
  isVisible,
  setVisible,
  refetch,
  fetch,
}: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();
    const uri = "customers/contact";
    const data = { customer: customer?._id, ...values };
    const response = await postDataRoad(data, uri);
    if (response.success) {
      message.success("Created Successfully");
    } else {
      message.error(response?.message);
      setLoading(false);
      return;
    }

    fetch();
    refetch();
    setLoading(false);
    setVisible(false);
    clearForm();
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">Add Contact Person</span>
        </div>
      }
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="contactPanel"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Full Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Mobile"
              rules={[
                {
                  required: true,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input placeholder="Mobile Number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: false,
                  message: "Please enter email",
                },
              ]}
            >
              <Input placeholder="Email (Optional)" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: false,
                  message: "Please enter address",
                },
              ]}
            >
              <Input placeholder="Address (Optional)" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" danger htmlType="submit" className="mr-2">
            {isLoading ? "Submiting ..." : "Submit"}
          </Button>
          <Button htmlType="button" danger onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
