export default function Vehicle({ vehicle }: any) {
  return (
    <div className="mt-4">
      <div className="">Vehicle Information</div>
      <div className="border border-black px-2 flex">
        <div className="border-r border-black w-32 ">Registration:</div>
        <div className="w-32 border-r border-black px-2">
          {vehicle?.registration}
        </div>
        <div className="w-16 border-r border-black px-2">Year:</div>
        <div className="w-16 border-r border-black px-2">{vehicle?.year}</div>
        <div className="w-20 border-r border-black px-2">Model:</div>
        <div className="w-40 border-r border-black px-2">
          {vehicle?.model?.name}
        </div>
        <div className="w-36  border-r border-black flex flex-col px-2 text-center">
          <div className="">Fuel Type:</div>
        </div>
        <div className="w-28 flex flex-col ml-1">
          <div className="px-2">{vehicle?.fuel}</div>
          <div className=""></div>
        </div>
      </div>
    </div>
  );
}
