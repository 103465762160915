const months: any = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const tens = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 8 },
  { id: 9 },
  { id: 10 },
];

  const data = [
    {
      name: "Jan",
      month: "January",
      amount: 0,
      data: [],
      count: 0,
    },
    {
      name: "Feb",
      month: "February",
      amount: 0,
      data: [],
      count: 0,
    },
    {
      name: "Mar",
      month: "March",
      amount: 0,
      data: [],
      count: 0,
    },
    {
      name: "Apr",
      month: "April",
      amount: 0,
      data: [],
      count: 0,
    },
    {
      name: "May",
      month: "May",
      amount: 0,
      data: [],
      count: 0,
    },
    {
      name: "Jun",
      month: "June",
      amount: 0,
      data: [],
      count: 0,
    },
    {
      name: "Jul",
      month: "July",
      amount: 0,
      data: [],
      count: 0,
    },
    {
      name: "Aug",
      month: "August",
      amount: 0,
      data: [],
      count: 0,
    },
    {
      name: "Sep",
      month: "September",
      amount: 0,
      data: [],
      count: 0,
    },
    {
      name: "Oct",
      month: "October",
      amount: 0,
      data: [],
      count: 0,
    },
    {
      name: "Nov",
      month: "November",
      amount: 0,
      data: [],
      count: 0,
    },
    {
      name: "Dec",
      month: "December",
      amount: 0,
      data: [],
      count: 0,
    },
  ];

  export { months, tens, data };
