import { useEffect, useState } from "react";
import formatMoney from "../../../utils/formatMoney";
import servicesYearly from "../../../utils/servicesYearly";
import SummaryPanel from "./SummaryPanel";

export default function SummaryWidget({ isLoading, data }: any) {
  const [services, setServices] = useState<any>(data);
  const [selected, setSelected] = useState<any>([]);
  const [isVisible, setVisible] = useState<boolean>(false);

  const handleFormatData = async () => {
    const formated = servicesYearly(data);
    setServices(formated);
  };

  useEffect(() => {
    handleFormatData();
  }, [isLoading]);

  return (
    <div className="mt-2">
      {services?.data?.map((item: any, index: number) => (
        <div
          className="border p-2 bg-white mb-1 hover:bg-green-50 hover:cursor-pointer"
          key={index}
        >
          <button
            className="flex justify-between items-center w-full "
            onClick={() => {
              setSelected(item);
              setVisible(true);
            }}
          >
            <div className="text-gray-600">{item?.month}</div>
            <div className="flex">
              {item?.count === 0 ? null : (
                <div className="mr-4"> {item?.count} Service(s) </div>
              )}
              <div className="w-28 text-right">
                {item?.amount
                  ? formatMoney(item?.amount ? item?.amount : 0)
                  : "-"}
              </div>
            </div>
          </button>
        </div>
      ))}

      <div className="border-t-2 border-black p-2 bg-white mb-1">
        <div className="flex justify-between items-center">
          <div className="">Total</div>
          <div className="">
            {services?.total
              ? formatMoney(services?.total ? services?.total : 0)
              : "-"}
          </div>
        </div>
      </div>

      <SummaryPanel
        isVisible={isVisible}
        setVisible={setVisible}
        data={selected}
      />
    </div>
  );
}
