export default function Five() {
  return (
    <div className="">
      <div style={{ fontSize: 12, marginBottom: 8 }} className="">
        Toyota Tanzania 24/7 Roadside Assistance Pricing
      </div>
      <div style={{ marginTop: 8, marginBottom: 8 }}>
        Please refer to the attached pricing guide.
      </div>
      <div className="">
        <div className="font-bold">Your responsibility for costs</div>
        <div className="">
          You are responsible for all costs of parts, labour and any other
          associated costs relating to the management and repair of your vehicle
          after a breakdown. We will not be liable for any indirect or
          consequential loss or damage that arises from providing or failing to
          provide any of the benefits or services whether arising from
          negligence or otherwise. We assume no responsibility for any advice or
          assistance given by any independent service provider for the services
          rendered hereunder.
        </div>
      </div>
    </div>
  );
}
