export default function FiveSw() {
  return (
    <div className="">
      <div style={{ fontSize: 12, marginBottom: 8 }} className="">
        Gharama za Huduma za Msaada wa Toyota Tanzania Pembezoni mwa Barabara
        kwa Saa
      </div>
      <div style={{ marginTop: 8, marginBottom: 8 }}>
        Tafadhali rejea mchanganuo wa gharama ulioambatanishwa hapa. Mwongozo wa
        bei /gharama
      </div>
      <div className="">
        <div className="font-bold">Wajibu wa Kulipia Huduma</div>
        <div className="">
          Unawajibika kulipa gharama zote za vipuri, nguvu kazi na gharama
          nyinginezo zinazohusiana na usimamizi na marekebisho ya gari lako
          baada ya kupata hitilafu. Hatutahusika na upotevu au uharibifu wowote
          utakaotokana na kutoa au kushindwa kutoa huduma kutokana na uzembe au
          vinginevyo. Hatutahusika na ushauri au msaada wowote utakaotolewa na
          mtoa huduma yeyote wa kujitegemea.
        </div>
      </div>
    </div>
  );
}
