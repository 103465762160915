import ReactSpeedometer from "react-d3-speedometer";

export default function GaugeMeter({ service }: any) {
  return (
    <div className="mb-4">
      <ReactSpeedometer
        customSegmentStops={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        segmentColors={[
          "#FF0D0D",
          "#FA5B3D",
          "#FA7A48",
          "#F99853",
          "#FFDF41",
          "#FFD422",
          "#F5BD22",
          "#B6DDAC",
          "#93CFA4",
          "#72AB94",
        ]}
        minValue={0}
        maxValue={100}
        width={120}
        height={60}
        textColor={"#fff"}
        ringWidth={15}
        labelFontSize={"0px"}
        needleHeightRatio={0.5}
        value={service?.gaugeLevel * 10}
      />
    </div>
  );
}
