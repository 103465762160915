import { useEffect } from "react";
import { Button, Modal, Form, message } from "antd";

export default function DeleteVehicle({
  selected,
  isVisible,
  setDeleteModal,
}: any) {
  const [form] = Form.useForm();

  useEffect(() => {}, [selected]);

  const onSubmit = async () => {
    message.error("Sorry, Can't delete right now");
    setDeleteModal(false);
  };

  const hideModal = async () => {
    setDeleteModal(false);
  };

  return (
    <Modal
      visible={isVisible}
      title="Delete Vehicle"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="DeleteSource"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form id="DeleteSource" form={form} onFinish={onSubmit}>
        <div>
          <p className="text-center font-bold">
            Are you sure you want to delete this vehicle
            <span className="text-red-500 ml-1">{selected?.registration}</span>
          </p>
        </div>
      </Form>
    </Modal>
  );
}
