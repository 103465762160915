import { Button, Drawer, Form, Space, Input } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { RiFilterOffLine } from "react-icons/ri";
import fetchDataRoad from "../../../../services/fetchDataRoad";
import { ExportToExcel } from "../../../../utils/exportExcel";
import formatMoney from "../../../../utils/formatMoney";
import UpdateOffer from "./UpdateOffer";
const { Search } = Input;
export default function PricingPanel({ isVisible, setVisible, selected }: any) {
  const [offers, setOffers] = useState<any>(selected?.offers);
  const [search, setSearch] = useState("");
  const [offer, setOffer] = useState<any>({});
  const [updateModal, setUpdateModal] = useState<boolean>(false);

  const [form] = Form.useForm();

  const handleFetchOffers = async () => {
    const uri = `offers/models/${selected?._id}`;
    if (selected?._id !== undefined) {
      const response = await fetchDataRoad({ uri });
      if (response?.success) {
        setOffers(response?.payload);
      }
    }
  };

  const getTotal = (item: any) => {
    let total = 0;
    total = item?.partsrate + item?.labourrate * item?.flattime;
    return isNaN(total) ? 0 : total;
  };

  useEffect(() => {
    handleFetchOffers();
  }, [selected]);

  const handleClearSearch = () => {
    setSearch("");
    form.resetFields();
  };

  return (
    <Drawer
      title={`PRICING : ${selected?.name}`}
      placement={"right"}
      width={1200}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="font-toyota">
        <div className="p-2 bg-gray-200 font-bold -mt-6 border-b border-t border-gray-300">
          <span className="text-green-600 mr-1">{selected?.name} :</span>
          Service pricing
        </div>
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mt-2">
          <div className="flex">
            <Form layout="inline" form={form}>
              <Form.Item name="registration">
                <Search
                  placeholder="Search by description"
                  allowClear
                  onChange={(val: any) => {
                    setSearch(val.target.value);
                  }}
                  onSearch={(value: any) => {
                    setSearch(value);
                  }}
                />
              </Form.Item>

              <Button className="" onClick={() => handleClearSearch()}>
                <RiFilterOffLine color="#6b7280" />
              </Button>
            </Form>
          </div>
          <div className="flex ">
            <button
              color="#E50000"
              className="flex justify-center items-center border p-1 hover:bg-green-100"
            >
              <BiExport size={24} />
              <span
                className="ml-2"
                onClick={() =>
                  ExportToExcel(
                    offers?.map((record: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        "Call out description":
                          record?.callout_desc === null
                            ? "-"
                            : record?.callout_desc,
                        "Invoice Description":
                          record?.invoice_desc === null
                            ? "-"
                            : record?.invoice_desc,
                        Model:
                          record?.vehicle?.model?.name === null
                            ? "-"
                            : record?.vehicle?.model?.name,
                        "Time In": moment(record?.timein).format(
                          "Do, MMM YYYY hh:mm A"
                        ),
                        "Time Out":
                          record?.timeout !== null
                            ? moment(record?.timeout).format(
                                "Do, MMM YYYY hh:mm A"
                              )
                            : null,
                      };
                    }),
                    "Offers.xlsx"
                  )
                }
              >
                Export To Excel
              </span>
            </button>
          </div>
        </div>

        <div className="flex py-3 border-b border-t border-gray-300">
          <div className="mr-2 w-10 text-center font-bold">S/N.</div>
          <div className="flex justify-between items-center flex-1">
            <div className="flex items-center">
              <div className="w-36 truncate font-bold">
                Call out Description
              </div>
              <div className="w-36 truncate text-left font-bold">
                Invoice Description
              </div>
              <div className="w-28 truncate font-bold">Flat Rate (Hrs)</div>
              <div className="w-28 truncate font-bold">Time Taken (Hrs)</div>
              <div className="w-20 truncate font-bold text-center">Labour</div>
              <div className="w-28 truncate font-bold">Total Labour</div>
              <div className="w-28 truncate font-bold">Parts / Others</div>
              <div className="w-28 truncate font-bold">Total (VAT exc)</div>
              <div className="w-28 truncate font-bold">Total (VAT inc)</div>
            </div>
            <div className="font-bold">
              <div className="flex">
                <div className="truncate flex-1 text-left">Edit</div>
              </div>
            </div>
          </div>
        </div>
        {offers
          ?.filter((offer: any) => offer?.callout_desc?.includes(search))
          ?.map((item: any, index: number) => (
            <div className="flex py-1 border-b border-gray-300" key={index}>
              <div className="mr-2 w-10 text-center">{index + 1}.</div>
              <div className="flex justify-between items-center flex-1">
                <div className="flex items-center">
                  <div className="w-36">
                    {item?.callout_desc === null ? "-" : item?.callout_desc}
                  </div>
                  <div className="w-36 truncate">
                    {item?.invoice_desc === null ? "-" : item?.invoice_desc}
                  </div>
                  <div className="w-28 truncate text-center">
                    {item?.flattime === 0 ? "-" : item?.flattime}
                  </div>
                  <div className="w-28 truncate text-center">
                    {item?.timetaken === 0 ? "-" : item?.timetaken}
                  </div>
                  <div className="w-20 truncate text-center">
                    {item?.labourrate === 0
                      ? "-"
                      : formatMoney(Number(item?.labourrate) ?? 0)}
                  </div>
                  <div className="w-28 truncate flex">
                    {item?.flattime === 0
                      ? "-"
                      : formatMoney(
                          Number(item?.labourrate) * Number(item?.flattime) ?? 0
                        )}
                  </div>
                  <div className="w-28 truncate flex">
                    {item?.partsrate === 0
                      ? "-"
                      : formatMoney(Number(item?.partsrate) ?? 0)}
                  </div>
                  <div className="w-28 truncate flex">
                    {formatMoney(getTotal(item))}
                  </div>
                  <div className="w-28 truncate flex">
                    {formatMoney(getTotal(item) * 1.18)}
                  </div>
                </div>
                <div className="">
                  <div className="flex">
                    <div className="truncate flex justify-end">
                      <div className="flex justify-start">
                        <button
                          onClick={() => {
                            setOffer({
                              ...item,
                            });
                            setUpdateModal(true);
                          }}
                          className="flex justify-center items-center text-yellow-700 border rounded-md p-2 bg-yellow-100 hover:bg-yellow-200 cursor-pointer"
                        >
                          <FiEdit />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <UpdateOffer
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
          offer={offer}
          refetch={handleFetchOffers}
        />
      </div>
    </Drawer>
  );
}
