const ROAD_BASE_URL = "https://api.kjl.co.tz:9000";
const ARPS_BASE_URL = "https://api.kjl.co.tz:3000";
const TRACE_BASE_URL = "https://api.kjl.co.tz:4000";
const EMAIL_BASE_URL = "https://api.kjl.co.tz:8080";

// const ROAD_BASE_URL = "http://192.168.100.145:4040";
// const ARPS_BASE_URL = "http://192.168.100.145:8080";
// const TRACE_BASE_URL = "http://192.168.100.145:4000";
// const EMAIL_BASE_URL = "http://192.168.100.145:8080";

export { TRACE_BASE_URL, EMAIL_BASE_URL, ARPS_BASE_URL, ROAD_BASE_URL };
