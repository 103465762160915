import { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col, Input, Select, message } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import fetchDataRoad from "../../../services/fetchDataRoad";
import postDataRoad from "../../../services/postDataRoad";

const { Option } = Select;
const { TextArea } = Input;

export default function UpdateVehicle({
  isVisible,
  selected,
  setUpdateModal,
  refetch,
}: any) {
  const [customers, setCustomers] = useState<any>([]);
  const [models, setModels] = useState<any>([]);

  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const [form] = Form.useForm();

  const onSubmit = async () => {
    const values = await form.validateFields();

    const uri = `vehicles/${selected?._id}`;

    const data = {
      _id: selected?._id,
      ...values,
    };

    const response = await postDataRoad(data, uri);
    if (response.success) {
      message.success("Vehicle updated successfully");
      setUpdateModal(false);
      await refetch();
    } else {
      message.error(response.message);
    }
  };

  const handleFetchCustomers = async () => {
    const uri = "customers";
    const data = await fetchDataRoad({ uri });
    setCustomers(data?.payload);
  };

  const handleFetchModels = async () => {
    const uri = "models";
    const data = await fetchDataRoad({ uri });
    setModels(data?.payload);
  };

  const hideModal = () => {
    setUpdateModal(false);
  };

  useEffect(() => {
    form.resetFields();
    handleFetchCustomers();
    handleFetchModels();
  }, [selected]);

  const clearForm = async () => {
    form.resetFields();
  };

  const customerOptions = customers?.map((customer: any, index: number) => {
    return (
      <Option value={customer._id} key={index}>
        {customer?.name}
      </Option>
    );
  });

  const modelOptions = models?.map((model: any, index: number) => {
    return (
      <Option value={model._id} key={index}>
        {model?.name}
      </Option>
    );
  });

  return (
    <Modal
      visible={isVisible}
      title="Update Vehicle"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          danger
          key="submit"
          form="updateVehicle"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateVehicle"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
        initialValues={{ requiredMarkValue: requiredMark }}
        requiredMark={requiredMark}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="registration"
              label="Registration"
              initialValue={selected?.registration}
              rules={[
                {
                  required: true,
                  message: "Please enter registration",
                },
              ]}
            >
              <Input placeholder="Registration" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="chassis"
              label="Chassis"
              initialValue={selected?.chassis}
              rules={[
                {
                  required: true,
                  message: "Please enter chassis number",
                },
              ]}
            >
              <Input placeholder="Chassis" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="make"
              label="Make"
              initialValue={selected?.make}
              rules={[
                {
                  required: true,
                  message: "Please enter make",
                },
              ]}
            >
              <Input placeholder="Make" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="model"
              label="Model"
              initialValue={selected?.model?._id}
              rules={[
                {
                  required: true,
                  message: "Please enter model",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {modelOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="year"
              label="Year"
              initialValue={selected?.year}
              rules={[
                {
                  required: true,
                  message: "Please enter year",
                },
              ]}
            >
              <Input type="number" placeholder="Year" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mileage"
              label="Mileage"
              initialValue={selected?.mileage}
              rules={[
                {
                  required: true,
                  message: "Please enter mileage",
                },
              ]}
            >
              <Input type="number" placeholder="Mileage" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="fuel"
              label="Fuel Type"
              initialValue={selected?.fuel}
              rules={[
                {
                  required: true,
                  message: "Please enter fuel type",
                },
              ]}
            >
              <Select placeholder="Fuel Type">
                <Option value={"Petrol"}>Petrol</Option>
                <Option value={"Diesel"}>Diesel</Option>
                <Option value={"Electric"}>Electric</Option>
                <Option value={"Hybrid"}>Hybrid</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="customer"
              label="Customer"
              initialValue={selected?.customer?._id}
              rules={[
                {
                  required: true,
                  message: "Please select customer",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search to Customer"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {customerOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              initialValue={selected?.description}
              rules={[
                {
                  required: false,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
