import { Button, Form, Row, Col, Input, message, Space, Drawer } from "antd";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import postDataRoad from "../../../services/postDataRoad";

export default function UpdateCreditPanel({
  isVisible,
  setVisible,
  customer,
  refetch,
  fetch,
}: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const { credit } = await form.validateFields();
    const cr = customer?.isCredit === false ? credit : 0;
    const uri = `customers/${customer?._id}/credit/update`;
    const data = { isCredit: !customer?.isCredit, credit: cr };
    const response = await postDataRoad(data, uri);
    if (response.success) {
      form.resetFields();
      message.success("Updated Successfully");
    } else {
      message.error(response?.message);
      setLoading(false);
      return;
    }

    fetch();
    refetch();
    setLoading(false);
    setVisible(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">Update Credit</span>
        </div>
      }
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="CompleteService"
        form={form}
        onFinish={onSubmit}
        layout="vertical"
      >
        <div>
          {customer?.isCredit ? (
            <>
              <p className=" font-bold">
                Are you sure you want to
                <span className="text-lg font-bold text-red-600 mx-1">
                  Revoke
                </span>{" "}
                Credit to this Customer
              </p>
            </>
          ) : (
            <>
              <p className="font-bold">
                Are you sure you want to{" "}
                <span className="text-lg font-bold text-green-500 mx-1">
                  Grant
                </span>
                Credit to this Customer?
              </p>
              <Row gutter={[16, 0]}>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                  <Form.Item
                    name="credit"
                    label="Credit limit  (TZS)"
                    rules={[
                      {
                        required: true,
                        message: "Please enter credit limit amount",
                      },
                    ]}
                  >
                    <Input placeholder="Amount" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </div>
        <Form.Item>
          <Button type="primary" danger htmlType="submit" className="mr-2">
            {isLoading ? "Submiting ..." : "Submit"}
          </Button>
          <Button htmlType="button" danger onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
