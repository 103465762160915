import VehicleWidget from "./VehicleWidget";
export default function WlPhotosWrapper({ service, refetch }: any) {
  if (service?.wlphotos === undefined) {
    return <></>;
  }

  const side1 = service?.wlphotos[0];
  const side2 = service?.wlphotos[1];
  const side3 = service?.wlphotos[2];
  const side4 = service?.wlphotos[3];

  return (
    <div className="book" id="invoice-doc">
      <div className="page">
        <div className="subpage2">
          <div className="p-1">
            <div
              className="flex justify-center items-center"
              style={{ fontSize: 16 }}
            >
              WHEELS PHOTOS
            </div>
            <div className="flex mb-6">
              <div className="w-1/2 flex justify-center h-auto mx-4 ">
                <VehicleWidget
                  item={side4}
                  service={service}
                  refetch={refetch}
                />
              </div>
              <div className="w-1/2 flex justify-center h-auto mx-4 ">
                <VehicleWidget
                  item={side1}
                  service={service}
                  refetch={refetch}
                />
              </div>
            </div>
            <div className="flex mb-6">
              <div className="w-1/2 flex justify-center h-auto mx-4">
                <VehicleWidget
                  item={side3}
                  service={service}
                  refetch={refetch}
                />
              </div>
              <div className="w-1/2 flex justify-center h-auto mx-4">
                <VehicleWidget
                  item={side2}
                  service={service}
                  refetch={refetch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
