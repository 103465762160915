export default function Six() {
  return (
    <div className="mt-2">
      <div style={{ fontSize: 12 }}>Definitions</div>
      <div className="">
        The following words have these meanings throughout this document:
      </div>
      <div className="mt-1">
        <span className="font-bold">Accident: </span>
        your vehicle is damaged by impact or collision of any nature or
        attempted or successful theft or break-in of the vehicle.
      </div>
      <div className="mt-1">
        <span className="font-bold">Breakdown: </span>a vehicle failure which
        has caused the vehicle to be immobilized or become unroadworthy or
        unsafe to drive in transit, due to mechanical or electrical fault. This
        can also include a flat tyre, flat battery, a vehicle that has run out
        of fuel or keys that have been locked in the vehicle or lost.
      </div>
      <div className="mt-1">
        <span className="font-bold">Restricted access area: </span>
        an area that is protected by security and/or other systems designed to
        prevent access to unauthorized people and includes areas which we do not
        have permission to enter (for example airports, state grounds, protests,
        concerts and certain business premises).
      </div>
      <div className="mt-1">
        <span className="font-bold">Vehicle: </span>
        the vehicle to which the breakdown relates
      </div>
      <div className="mt-1">
        <span className="font-bold">We, Us, Our: </span>
        Toyota Tanzania Limited
      </div>
      <div className="mt-1">
        <span className="font-bold">You, Your : </span>
        the owner or driver of the vehicle, or individual who requested for
        Roadside Assistance.
      </div>

      <div className="my-2">
        Vehicles and contents are stored and driven by our staff at owner’s
        risk. No responsibility attached to us for loss by fire, burglary,
        theft, accident, delay due to non-availability of material or other
        causes whatsoever.
      </div>

      <div className="">
        <div className="font-bold">Important Information</div>
        <div className="mb-1">
          We reserve the right to make changes to these Terms and Conditions
          where is necessary in order to comply with any applicable laws,
          regulations or the advice or instruction of any regulatory authority.
          Please check our website from time to time to take notice of any such
          changes we make.
        </div>
        <div className="">
          Toyota Tanzania 24/7 Roadside Assistance is provided by Toyota
          Tanzania Limited, located on Plot No. 5 Nyerere Road, Dar es Salaam,
          Tanzania.
        </div>
      </div>
    </div>
  );
}
