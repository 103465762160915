const data = [
  {
    name: "Jan",
    month: "January",
    amount: 0,
    data: [],
    count: 0,
  },
  {
    name: "Feb",
    month: "February",
    amount: 0,
    data: [],
    count: 0,
  },
  {
    name: "Mar",
    month: "March",
    amount: 0,
    data: [],
    count: 0,
  },
  {
    name: "Apr",
    month: "April",
    amount: 0,
    data: [],
    count: 0,
  },
  {
    name: "May",
    month: "May",
    amount: 0,
    data: [],
    count: 0,
  },
  {
    name: "Jun",
    month: "June",
    amount: 0,
    data: [],
    count: 0,
  },
  {
    name: "Jul",
    month: "July",
    amount: 0,
    data: [],
    count: 0,
  },
  {
    name: "Aug",
    month: "August",
    amount: 0,
    data: [],
    count: 0,
  },
  {
    name: "Sep",
    month: "September",
    amount: 0,
    data: [],
    count: 0,
  },
  {
    name: "Oct",
    month: "October",
    amount: 0,
    data: [],
    count: 0,
  },
  {
    name: "Nov",
    month: "November",
    amount: 0,
    data: [],
    count: 0,
  },
  {
    name: "Dec",
    month: "December",
    amount: 0,
    data: [],
    count: 0,
  },
];

const total = 0;

export default { data, total };
