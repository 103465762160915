import InvoiceItemRead from "./InvoiceItemRead";

export default function InvoiceItemsRead({ items }: any) {
  return (
    <>
      <div className="border border-black  my-2 text-xs">
        <div className="grid grid-cols-12 border-b  border-black  bg-gray-200">
          <div className="col-span-10 p-1 font-bold border-r border-black  text-left">
            Description
          </div>
          <div className="col-span-2 p-1 font-bold  text-center">Amount</div>
        </div>
        <div className="">
          {items?.map((item: any, index: number) => (
            <InvoiceItemRead
              style={{ color: "red" }}
              key={index}
              index={index}
              item={item}
            />
          ))}
        </div>
      </div>
    </>
  );
}
