import { message } from "antd";
import { useEffect, useState } from "react";
import { FaGasPump } from "react-icons/fa";
import { TbChevronDown } from "react-icons/tb";
import postDataRoad from "../../../../../services/postDataRoad";
import { tens } from "../../../../../utils/data";

export default function GaugeWidget({ service, refetch }: any) {
  const [level, setLevel] = useState<number>(service?.gaugeLevel || 0);
  const current = tens.slice(0, level);
  const empties = tens.slice(level);

  const percent = (current.length / 10) * 100;

  const handleSetLevel = () => {
    setLevel(service?.gaugeLevel);
  };

  const handleShiftLevel = async (item: any) => {
    return;
    if (service?.isCompleted) {
      message.error("Sorry, This work is completed");
      return;
    }
    setLevel(item?.id);
    const data = { level: item?.id };
    const uri = `services/${service?._id}/gauge/level`;
    const response = await postDataRoad(data, uri);
    if (response?.success) {
      message.success("Updated successfully");
      refetch();
    }
  };
  useEffect(() => {
    handleSetLevel();
  }, [service, level]);

  if (service?.gaugeLevel === undefined) {
    return <></>;
  }
  return (
    <div className="flex flex-col justify-center items-center">
      <div style={{ fontSize: 10 }}>
        <span>
          Fuel Level :{" "}
          <span>
            About <span style={{ fontWeight: "bold" }}> {percent}% </span>
            full
          </span>
        </span>
      </div>
      <div className="flex flex-col">
        <div className="flex -mb-1">
          <div className="flex justify-start items-center w-5 font-bold"></div>
          <div className="flex justify-center items-center w-12"></div>
          <div className="flex justify-center items-center w-8">
            <TbChevronDown size={15} />
          </div>
          <div className="flex justify-center items-center w-12"></div>
          <div className="flex justify-end items-center w-5 font-bold"></div>
        </div>
        <div className="flex">
          <div className="flex justify-center items-center w-1 bg-toyota"></div>
          {current?.map((item: any, index: number) => (
            <button
              key={index}
              className="flex justify-center items-center w-4 h-4 border-r border-black bg-gray-400"
              onClick={() => handleShiftLevel(item)}
            ></button>
          ))}
          {empties?.map((item: any, index: number) => (
            <button
              key={index}
              className="flex justify-center items-center w-4 h-4 border-r border-black text-white"
              onClick={() => handleShiftLevel(item)}
            >
              -
            </button>
          ))}
          <div className="flex justify-center items-center w-1 bg-black"></div>
        </div>
        <div className="flex">
          <div className="flex justify-start items-center w-5 font-bold">E</div>
          <div className="flex justify-center items-center w-12"></div>
          <div className="flex justify-center items-center w-8 ">
            <FaGasPump />
          </div>
          <div className="flex justify-center items-center w-12"></div>
          <div className="flex justify-end items-center w-5 font-bold">F</div>
        </div>
      </div>
    </div>
  );
}
