export default function TwoSw() {
  return (
    <div className="mt-1">
      <div className="">
        <div style={{ fontSize: 12 }}>Usalama Kwanza</div>
        <div className="">
          Kama gari lako limepata hitilafu katika eneo lisilo salama, tafadhali
          mjulishe mhudumu na kuhakikisha kwamba uko salama dhidi ya hatari
          inayoweza kusababishwa na magari yanayopita.
        </div>
      </div>
      <div className="">
        <div className="font-bold mt-1">Kaa karibu na gari lako</div>
        <div className="">
          Unapoomba huduma hii, ni muhimu kukaa karibu na gari lako. Endapo
          Kikosi chetu cha Huduma kitalifikia gari lako lakini haupo,
          tutashindwa kufanya chochote na unaweza kulipa zaidi endapo utapiga
          simu baadae kwa ajili ya huduma hiyo. Endapo itabidi kuondoka lilipo
          gari lako kwa sababu za kiusalama, tafadhali mjulishe mhudumu wetu.
        </div>
      </div>
    </div>
  );
}
