import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  message,
  Drawer,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import fetchDataRoad from "../../../services/fetchDataRoad";
import postDataRoad from "../../../services/postDataRoad";

const { Option } = Select;
const { TextArea } = Input;

export default function CreateVehiclePanel({
  isVisible,
  setVisible,
  customerId,
  refetch,
  fetch,
}: any) {
  const [models, setModels] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();

    const data = {
      isDeleted: false,
      magic: 0,
      customer: customerId,
      ...values,
    };

    const uri = "vehicles";
    const response = await postDataRoad(data, uri);

    if (response.success) {
      message.success("Vehicle added successfully");
      await refetch();
    } else {
      message.error(response.message);
      setLoading(false);
      return;
    }
    fetch();
    refetch();
    setLoading(false);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setLoading(false);
  };

  const handleFetchModels = async () => {
    const uri = "models";
    const data = await fetchDataRoad({ uri });
    setModels(data?.payload);
  };

  useEffect(() => {
    handleFetchModels();
  }, []);

  const modelOptions = models?.map((model: any, index: number) => {
    return (
      <Option value={model._id} key={index}>
        {model?.name}
      </Option>
    );
  });

  return (
    // <Modal
    //   visible={isVisible}
    //   title="New Vehicle"
    //   onCancel={() => setVisible(false)}
    //   footer={[
    //     <Button danger key="back" onClick={clearForm}>
    //       Clear
    //     </Button>,
    //     <Button
    //       key="submit"
    //       form="createVehicle"
    //       htmlType="submit"
    //       type="primary"
    //       onSubmit={onSubmit}
    //       loading={isLoading}
    //       danger
    //     >
    //       Submit
    //     </Button>,
    //   ]}
    // >
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">New Vehicle</span>
        </div>
      }
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="createVehicle"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="registration"
              label="Registration"
              rules={[
                {
                  required: true,
                  message: "Please enter registration",
                },
              ]}
            >
              <Input placeholder="Registration" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="chassis"
              label="Chassis"
              rules={[
                {
                  required: true,
                  message: "Please enter chassis number",
                },
              ]}
            >
              <Input placeholder="Chassis" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="make"
              label="Make"
              rules={[
                {
                  required: true,
                  message: "Please enter make",
                },
              ]}
            >
              <Input placeholder="Make" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="model"
              label="Model"
              rules={[
                {
                  required: true,
                  message: "Please enter model",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {modelOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="year"
              label="Year"
              rules={[
                {
                  required: true,
                  message: "Please enter year",
                },
              ]}
            >
              <Input type="number" placeholder="Year" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mileage"
              label="Mileage"
              rules={[
                {
                  required: true,
                  message: "Please enter mileage",
                },
              ]}
            >
              <Input type="number" placeholder="Mileage" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="fuel"
              label="Fuel Type"
              rules={[
                {
                  required: true,
                  message: "Please enter fuel type",
                },
              ]}
            >
              <Select placeholder="Fuel Type">
                <Option value={"Petrol"}>Petrol</Option>
                <Option value={"Diesel"}>Diesel</Option>
                <Option value={"Electric"}>Electric</Option>
                <Option value={"Hybrid"}>Hybrid</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: false,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" danger htmlType="submit" className="mr-2">
            {isLoading ? "Submiting ..." : "Submit"}
          </Button>
          <Button htmlType="button" danger onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
