import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useState } from "react";
import postDataRoad from "../../../services/postDataRoad";

export default function UpdateCredit({
  isVisible,
  setVisible,
  customer,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const { credit } = await form.validateFields();
    const cr = customer?.isCredit === false ? credit : 0;
    const uri = `customers/${customer?._id}/credit/update`;
    const data = { isCredit: !customer?.isCredit, credit: cr };
    const response = await postDataRoad(data, uri);
    if (response.success) {
      message.success("Updated Successfully");
    } else {
      message.error(response?.message);
      setLoading(false);
      return;
    }

    refetch();
    setLoading(false);
    setVisible(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={isVisible}
      title="Update Credit Status"
      onCancel={hideModal}
      footer={[
        <Button key="back" onClick={hideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="CompleteService"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Yes
        </Button>,
      ]}
    >
      <Form id="CompleteService" form={form} onFinish={onSubmit}>
        <div>
          {customer?.isCredit ? (
            <>
              <p className="flex justify-center items-center">
                {customer?.name}
              </p>
              <p className="text-center font-bold">
                Are you sure you want to
                <span className="text-lg font-bold text-red-600 mx-1">
                  Revoke
                </span>{" "}
                Credit to this Customer
              </p>
            </>
          ) : (
            <>
              <p className="flex justify-center items-center">
                {customer?.name}
              </p>
              <p className="text-center font-bold">
                Are you sure you want to{" "}
                <span className="text-lg font-bold text-green-500 mx-1">
                  Grant
                </span>
                Credit to this Customer?
              </p>
              <Row gutter={[16, 0]}>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                  <Form.Item
                    name="credit"
                    rules={[
                      {
                        required: true,
                        message: "Please enter credit limit amount",
                      },
                    ]}
                  >
                    <Input placeholder="Credit limit  (TZS)" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
}
