import { Button, Drawer, Form, Space, Input } from "antd";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import { BsEye } from "react-icons/bs";
import { RiFilterOffLine } from "react-icons/ri";
import { ExportToExcel } from "../../../utils/exportExcel";
import formatMoney from "../../../utils/formatMoney";
import ItemsPanel from "./ItemsPanel";
const { Search } = Input;
export default function SummaryPanel({ setVisible, isVisible, data }: any) {
  const [search, setSearch] = useState("");
  const [showItems, setShowItems] = useState<boolean>(false);
  const [list, setList] = useState<any>();

  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + (item?.labourrate + item?.partsrate) * 1.18;
    });
    return isNaN(total) ? 0 : total;
  };

  const [form] = Form.useForm();

  const handleClearSearch = () => {
    setSearch("");
    form.resetFields();
  };

  if (data?.data?.length === undefined) {
    return <></>;
  }

  return (
    <Drawer
      title={`${data?.month} Services`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="font-toyota">
        <div className="flex justify-between items-center border-b border-gray-300 mb-2 pb-2 -mt-4">
          <div className="flex">
            <Form layout="inline" form={form}>
              <Form.Item name="registration">
                <Search
                  placeholder="Search vehicle registration"
                  allowClear
                  onChange={(val: any) => {
                    setSearch(val.target.value);
                  }}
                  onSearch={(value: any) => {
                    setSearch(value);
                  }}
                />
              </Form.Item>

              <Button className="" onClick={() => handleClearSearch()}>
                <RiFilterOffLine color="#6b7280" />
              </Button>
            </Form>
          </div>
          <div className="flex ">
            <button
              color="#E50000"
              className="flex justify-center items-center border p-1 hover:bg-green-100"
            >
              <BiExport size={24} />
              <span
                className="ml-2"
                onClick={() =>
                  ExportToExcel(
                    data?.data?.map((item: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        Vehicle:
                          item?.vehicle?.registration === null
                            ? "-"
                            : item?.vehicle?.registration,
                        Customer:
                          item?.customer?.name === null
                            ? "-"
                            : item?.customer?.name,
                        Status: item?.status === null ? "-" : item?.status,
                        "Total Amount": formatMoney(getTotal(item?.items)),
                      };
                    }),
                    "Services.xlsx"
                  )
                }
              >
                Export To Excel
              </span>
            </button>
          </div>
        </div>
        <div className="flex py-1 border-b border-gray-300">
          <div className="mr-2 w-10 text-center font-bold">S/N.</div>
          <div className="flex justify-between items-center flex-1">
            <div className="flex items-center">
              <div className="w-36 truncate font-bold">Vehicle</div>
              <div className="w-36 truncate text-left font-bold">Customer</div>
              <div className="w-36 truncate font-bold">Status</div>
            </div>
            <div className="font-bold w-full">
              <div className="flex">
                <div className="mr-2 text-left flex-1">Amount</div>
                <div className="truncate flex justify-end">Details</div>
              </div>
            </div>
          </div>
        </div>
        {data?.data
          ?.filter((cla: any) => cla?.vehicle?.registration?.includes(search))
          .map((item: any, index: number) => (
            <div className="flex py-1 border-b border-gray-300" key={index}>
              <div className="mr-2 w-10 text-center">{index + 1}.</div>
              <div className="flex justify-between items-center flex-1">
                <div className="flex items-center">
                  <div className="w-36">
                    {item?.vehicle?.registration === null
                      ? "-"
                      : item?.vehicle?.registration}
                  </div>
                  <div className="w-36 truncate">
                    {item?.customer?.name === null ? "-" : item?.customer?.name}
                  </div>
                  <div className="w-36 truncate flex">{item?.status}</div>
                </div>
                <div className="w-full">
                  <div className="flex">
                    <div className="mr-2 text-left flex-1">
                      {formatMoney(getTotal(item?.items))}
                    </div>
                    <button
                      onClick={() => {
                        setShowItems(true);
                        setList(item);
                      }}
                      className="truncate ml-2 border px-2 py-1 flex justify-center items-center hover:bg-blue-50 hover:cursor-pointer"
                    >
                      <BsEye /> <span className="ml-2">Details</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <ItemsPanel
          isVisible={showItems}
          setVisible={setShowItems}
          selected={list}
        />
      </div>
    </Drawer>
  );
}
