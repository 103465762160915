import Moment from "react-moment";

interface IDocumentRefProps {
  service: any;
}
export default function DocumentRef({ service }: IDocumentRefProps) {
  return (
    <>
      <div className="bg-white border-b border-t border-r border-l border-black mb-2 flex flex-row justify-between items-center text-xs">
        <div className=" flex flex-col w-full sm:w-7/12 text-xs">
          <div className="px-2 flex flex-col truncate">
            <div className="">Customer Name : {service?.contact?.name}</div>
            <div className="">
              Company :
              {service?.customer?.isCompany ? service?.customer?.name : "N/A"}
            </div>
          </div>
          <div className="px-2 flex truncate">
            <span className="w-10">Email</span>
            <span className="px-2">: {service?.contact?.email}</span>
          </div>
          <div className="px-2 flex truncate">
            <span className="w-10">Phone </span>
            <span className="px-2"> : {service?.contact?.mobile}</span>
          </div>
        </div>

        <div className={`border-l border-black  w-5/12`}>
          <div className="flex justify-between items-center px-2 border-b  border-black">
            <div className="border-r border-black w-24 py-2 ">Ref. No </div>
            <div className="w-full text-left px-2 uppercase">
              {service?._id?.slice(-6)}
            </div>
          </div>
          <div className="flex justify-between items-center px-2  border-black">
            <div className="border-r border-black w-24 py-2">DATE </div>
            <div className="w-full text-left px-2">
              <Moment format="Do MMM, YYYY">{service?.createdAt}</Moment>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
