import { useEffect } from "react";
import { Button, Modal, Form, message } from "antd";
import formatMoney from "../../../../utils/formatMoney";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import postDataRoad from "../../../../services/postDataRoad";

export default function CompleteModal({
  service,
  isVisible,
  setVisible,
  refetch,
}: any) {
  const [form] = Form.useForm();

  const getTotal = () => {
    let total = 0;
    service?.items?.forEach((item: any) => {
      total =
        total + (item?.labourrate * item?.flattime + item?.partsrate) * 1.18;
    });
    return isNaN(total) ? 0 : total;
  };

  const onSubmit = async () => {
    if (!service?.customerSignature) {
      message.error("Customer Signature Required");
      return;
    }
    const uri = `services/${service?._id}/complete`;
    const data = {};

    const response = await postDataRoad(data, uri);
    if (response.success) {
      message.success("Completed Successfully");
      refetch();
    }
    setVisible(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  useEffect(() => {}, [service]);

  return (
    <Modal
      visible={isVisible}
      title="Complete Work"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          No
        </Button>,
        <Button
          key="submit"
          form="DeleteService"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          danger
        >
          Yes
        </Button>,
      ]}
    >
      <Form id="DeleteService" form={form} onFinish={onSubmit}>
        <div>
          <p className="text-center font-bold">
            Are you sure you want to mark this work as
            <span className="text-green-500 font-bold mx-1">Completed</span>
          </p>
          <div className="pb-4 flex flex-col justify-center items-center">
            <div className="flex text-black font-bold text-lg">
              Total Amount
            </div>
            <div className="text-green-800">{formatMoney(getTotal())}</div>
            <div className="flex flex-col items-center">
              <span>Customer Signature:</span>
              <div className="flex justify-center items-center w-10 h-10 border">
                {service?.customerSignature ? (
                  <div className="">
                    <AiOutlineCheck size={20} color="#16a34a" />
                  </div>
                ) : (
                  <div className="">
                    <AiOutlineClose size={20} color="#E50000" />
                  </div>
                )}
              </div>
            </div>
            {service?.customerSignature ? null : (
              <div className="text-toyota mt-4">
                Note: Customer Signature Required
              </div>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
