const servicesYearly = function (services: any) {
  let total = 0;
  let [janTotal, febTotal, marTotal] = [0, 0, 0];
  let [aprTotal, mayTotal, junTotal] = [0, 0, 0];
  let [julTotal, augTotal, sepTotal] = [0, 0, 0];
  let [octTotal, novTotal, decTotal] = [0, 0, 0];

  let janData: any = [];
  let febData: any = [];
  let marData: any = [];
  let aprData: any = [];
  let mayData: any = [];
  let junData: any = [];
  let julData: any = [];
  let augData: any = [];
  let octData: any = [];
  let sepData: any = [];
  let novData: any = [];
  let decData: any = [];

  services?.forEach((service: any) => {
    if (service?.month === 1) {
      janData.push(service);
      service?.items?.forEach((item: any) => {
        total = total + (item?.labourrate + item?.partsrate) * 1.18;
        janTotal = janTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
    if (service?.month === 2) {
      febData.push(service);
      service?.items?.forEach((item: any) => {
        total = total + (item?.labourrate + item?.partsrate) * 1.18;
        febTotal = febTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
    if (service?.month === 3) {
      marData.push(service);
      service?.items?.forEach((item: any) => {
        total = total + (item?.labourrate + item?.partsrate) * 1.18;
        marTotal = marTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
    if (service?.month === 4) {
      aprData.push(service);
      service?.items?.forEach((item: any) => {
        aprTotal = aprTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
    if (service?.month === 5) {
      mayData.push(service);
      service?.items?.forEach((item: any) => {
        total = total + (item?.labourrate + item?.partsrate) * 1.18;
        mayTotal = mayTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
    if (service?.month === 6) {
      junData.push(service);
      service?.items?.forEach((item: any) => {
        total = total + (item?.labourrate + item?.partsrate) * 1.18;
        junTotal = junTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
    if (service?.month === 7) {
      julData.push(service);
      service?.items?.forEach((item: any) => {
        total = total + (item?.labourrate + item?.partsrate) * 1.18;
        julTotal = julTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
    if (service?.month === 8) {
      augData.push(service);
      service?.items?.forEach((item: any) => {
        total = total + (item?.labourrate + item?.partsrate) * 1.18;
        augTotal = augTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
    if (service?.month === 9) {
      sepData.push(service);
      service?.items?.forEach((item: any) => {
        total = total + (item?.labourrate + item?.partsrate) * 1.18;
        sepTotal = sepTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
    if (service?.month === 10) {
      octData.push(service);
      service?.items?.forEach((item: any) => {
        total = total + (item?.labourrate + item?.partsrate) * 1.18;
        octTotal = octTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
    if (service?.month === 11) {
      novData.push(service);
      service?.items?.forEach((item: any) => {
        total = total + (item?.labourrate + item?.partsrate) * 1.18;
        novTotal = novTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
    if (service?.month === 12) {
      decData.push(service);
      service?.items?.forEach((item: any) => {
        total = total + (item?.labourrate + item?.partsrate) * 1.18;
        decTotal = decTotal + (item?.labourrate + item?.partsrate) * 1.18;
      });
    }
  });

  const data = [
    {
      name: "Jan",
      month: "January",
      amount: janTotal,
      data: janData,
      count: janData?.length,
    },
    {
      name: "Feb",
      month: "February",
      amount: febTotal,
      data: febData,
      count: febData?.length,
    },
    {
      name: "Mar",
      month: "March",
      amount: marTotal,
      data: marData,
      count: marData?.length,
    },
    {
      name: "Apr",
      month: "April",
      amount: aprTotal,
      data: aprData,
      count: aprData?.length,
    },
    {
      name: "May",
      month: "May",
      amount: mayTotal,
      data: mayData,
      count: mayData?.length,
    },
    {
      name: "Jun",
      month: "June",
      amount: junTotal,
      data: junData,
      count: junData?.length,
    },
    {
      name: "Jul",
      month: "July",
      amount: julTotal,
      data: julData,
      count: julData?.length,
    },
    {
      name: "Aug",
      month: "August",
      amount: augTotal,
      data: augData,
      count: augData?.length,
    },
    {
      name: "Sep",
      month: "September",
      amount: sepTotal,
      data: sepData,
      count: sepData?.length,
    },
    {
      name: "Oct",
      month: "October",
      amount: sepTotal,
      data: octData,
      count: octData?.length,
    },
    {
      name: "Nov",
      month: "November",
      amount: novTotal,
      data: novData,
      count: novData?.length,
    },
    {
      name: "Dec",
      month: "December",
      amount: decTotal,
      data: decData,
      count: decData?.length,
    },
  ];

  return { data, total };
};

export default servicesYearly;
