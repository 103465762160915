import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useEffect, useState } from "react";
import patchDataRoad from "../../../../services/patchDataRoad";

type RequiredMark = boolean | "optional";

export default function UpdateModel({
  isVisible,
  setVisible,
  selected,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState(false);
  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();
    const uri = `models/${selected?._id}`;
    const response = await patchDataRoad(values, uri);

    if (response.success) {
      refetch();
      message.success("Model updated successfully");
    } else {
      message.error("Sorry, Something went wrong");
      setLoading(false);
      return;
    }
    setLoading(false);
    setVisible(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [selected]);

  return (
    <Modal
      visible={isVisible}
      title="Update Model"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateModel"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateModel"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
        initialValues={{ requiredMarkValue: requiredMark }}
        requiredMark={requiredMark}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Model name"
              initialValue={selected?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter model name",
                },
              ]}
            >
              <Input placeholder="Model name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
