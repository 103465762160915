export default function DocumentVehicle({ service }: any) {
  return (
    <div className="border border-black  text-xs">
      <div className="grid grid-cols-12 gap-0">
        <div className="col-span-2 p-1 border-r border-b border-black font-bold">
          Service Offered
        </div>
        <div className="col-span-10 p-1 border-b  border-black">
          Roadside assistant
        </div>
      </div>
      <div className="grid grid-cols-12 gap-0">
        <div className="col-span-2 p-1 border-r border-b border-black font-bold">
          Reg No.
        </div>
        <div className="col-span-4 p-1 border-r border-b  border-black font-bold">
          Model
        </div>
        <div className="col-span-1 p-1 border-r border-b  border-black font-bold text-center">
          Year
        </div>
        <div className="col-span-3 p-1 border-r border-b  border-black font-bold">
          Chassis
        </div>

        <div className="col-span-2 p-1  border-b  border-black font-bold">
          Mileage ( Kms)
        </div>
      </div>
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-2 p-1 border-r border-black  text-center">
          {service?.vehicle?.registration
            ? service?.vehicle?.registration
            : "-"}
        </div>
        <div className="col-span-4 p-1 border-r border-black  text-left">
          {service?.vehicle?.model?.name ? service?.vehicle?.model?.name : "-"}
        </div>
        <div className="col-span-1 p-1 border-r border-black px-2 text-center">
          {service?.vehicle?.year ? service?.vehicle?.year : "-"}
        </div>
        <div className="col-span-3 p-1 border-r border-black  px-2">
          {service?.vehicle?.chassis ? service?.vehicle?.chassis : "-"}
        </div>

        <div className="col-span-2 p-1  border-black  px-2">
          {service?.vehicle?.mileage ? service?.vehicle?.mileage : "-"}
        </div>
      </div>
    </div>
  );
}
