import { GiCheckMark } from "react-icons/gi";
import Image from "../../../../assets/images/service.png";
import CheckWidget from "./components/CheckWidget";
import { FaCheck } from "react-icons/fa";
import postDataRoad from "../../../../services/postDataRoad";
import { message } from "antd";
import GaugeWidget from "./components/GaugeWidget";
import GaugeMeter from "./components/GaugeMeter";

export default function Inspection({ service, refetch }: any) {
  const handleUpdateStatus = async (item: any) => {
    return;
    if (service?.isCompleted) {
      message.error("Sorry, This work is completed");
      return;
    }
    const uri = `services/floormats/${item?._id}`;
    let status = !item?.status;
    const data = { status, all: service?.floormats };
    const response = await postDataRoad(data, uri);
    if (response?.success) {
      message.success("Status updated successfully");
      refetch();
    }
  };

  return (
    <div className="mt-2">
      <div className="">Vehicle Inspection</div>
      <div className="flex justify-between mt-1">
        <div className="w-2/3">
          <div className="flex flex-col border border-black">
            <div className="flex">
              <div className="w-1/2 border-r border-black">
                <div className="">
                  <div className="flex justify-between font-bold pl-2">
                    Walk-Around check
                    <div className="flex justify-center items-center w-6 border-l border-black">
                      <FaCheck color={"green"} />
                    </div>
                  </div>
                  {service?.checks?.map((item: any, index: number) => (
                    <CheckWidget
                      item={item}
                      key={index}
                      service={service}
                      all={service?.checks}
                      category={"checks"}
                      refetch={refetch}
                    />
                  ))}
                </div>
              </div>
              <div className="w-1/2">
                <div className="">
                  <div className="flex border-black justify-between pl-2 font-bold">
                    Valuables in the Vehicle
                    <div className="flex justify-center items-center w-6 border-l border-black"></div>
                  </div>
                  {service?.valuables?.map((item: any, index: number) => (
                    <CheckWidget
                      item={item}
                      key={index}
                      service={service}
                      all={service?.valuables}
                      category={"valuables"}
                      refetch={refetch}
                    />
                  ))}

                  <div className="flex border-t border-black justify-between font-bold pl-2">
                    Payment Type
                    <div className="flex justify-center items-center w-6 border-l border-black">
                      <FaCheck color={"green"} />
                    </div>
                  </div>
                  {service?.payments?.map((item: any, index: number) => (
                    <CheckWidget
                      item={item}
                      service={service}
                      all={service?.payments}
                      category={"payments"}
                      key={index}
                      refetch={refetch}
                    />
                  ))}
                  <div className="border-t border-black text-white">-</div>
                </div>
              </div>
            </div>
            <div className="border-t border-b border-black text-white">-</div>
            <div className="flex">
              <div className="w-1/2 border-r border-black">
                <div className="flex justify-between font-bold pl-2">
                  Floor Mat Condition
                  <div className="flex justify-center items-center w-6 border-l border-black">
                    <FaCheck color={"green"} />
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  {service?.floormats?.map((item: any) => (
                    <div className="col-span-6 border-t border-black">
                      <div className="flex justify-between p-1">
                        <div className="truncate">{item?.name}</div>
                        <button
                          onClick={() => handleUpdateStatus(item)}
                          className="flex justify-center items-center border border-black w-5"
                        >
                          {item?.status ? (
                            <div className="flex justify-center items-center">
                              {item?.status ? <GiCheckMark /> : null}
                            </div>
                          ) : null}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-1/2">
                <div className="font-bold pl-2 border-b border-black">
                  Odometer Reading
                </div>
                <div className="flex justify-between pl-2 py-1 border-b border-black">
                  <div className="">{service?.vehicle?.mileage}</div>
                  <div className="mr-8">Km</div>
                </div>
                <div className="flex flex-col justify-center items-center -mt-3">
                  <GaugeMeter service={service} />
                  <GaugeWidget service={service} refetch={refetch} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <img src={Image} alt="" />
        </div>
      </div>
    </div>
  );
}
