import OneSw from "./terms/OneSw";
import TwoSw from "./terms/TwoSw";
import ThreeSw from "./terms/ThreeSw";
import FourSw from "./terms/FourSw";
import FiveSw from "./terms/FiveSw";
import SixSw from "./terms/SixSw";

export default function TermsWrapperSw() {
  return (
    <div className="book" id="invoice-doc">
      <div className="page">
        <div className="subpage2">
          <div className="p-1">
            <div className="font-thin mb-4">
              <div style={{ fontSize: 22 }} className="flex">
                Toyota Tanzania Msaada wa Pembezone mwa Barabara
              </div>
              <div style={{ fontSize: 22 }} className="flex">
                Vigezo na Masharti
              </div>
            </div>

            <div className="flex">
              <div style={{ fontSize: 8 }} className="w-1/2 mr-2">
                <OneSw />
                <TwoSw />
                <ThreeSw />
                <FourSw />
              </div>

              <div style={{ fontSize: 8 }} className="w-1/2 ml-2">
                <FiveSw />
                <SixSw />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
