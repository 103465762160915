import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { BarsOutlined, LeftOutlined } from "@ant-design/icons";

export default function MixiToolnav({ link, links }: any) {
  let resolved = useResolvedPath(link);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <header className="bg-gray-50 px-4 sm:px-5 lg:px-5 border-b border-gray-300 ">
      <div className="flex flex-row items-center ">
        <NavLink
          to={`${link}`}
          className="flex items-center text-sm text-gray-600 py-1 px-1 "
        >
          <LeftOutlined />
          <span className="ml-1 pr-3 border-r border-gray-400">Back</span>
        </NavLink>
        {links.map((link: any, index: any) => (
          // <NavLink
          //   to={`${link.to}`}
          //   className="flex items-center text-sm text-gray-600 ml-2  py-1"
          // >
          //   <BarsOutlined /> <span className="ml-1">{link.name}</span>
          // </NavLink>
          <NavLink
            to={`${link.to}`}
            className={`flex items-center text-sm text-gray-800 py-2 hover:text-toyota mr-2 ${
              match ? "border-b border-toyota" : ""
            }`}
            key={index}
          >
            <BarsOutlined /> <span className="ml-1">{link?.name}</span>
          </NavLink>
        ))}
      </div>
    </header>
  );
}
