import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { startCase } from "lodash";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import { blobToDataUrl, getExportData } from "../../../utils/exportExcel";

import fetchDataRoad from "../../../services/fetchDataRoad";
import CreateCustomer from "../../customers/components/CreateCustomer";
import Manage from "..";
import { FiEdit } from "react-icons/fi";
import { IoTrashBinOutline } from "react-icons/io5";
import postDataMail from "../../../services/postDataMail";
import { fetchAllCustomers } from "../../../utils/dataExports";
import { BiLoader } from "react-icons/bi";
import formatMoney from "../../../utils/formatMoney";
import { FaRegEdit } from "react-icons/fa";
import UpdateCredit from "../../customers/components/UpdateCredit";
import UpdateCustomer from "../../customers/components/UpdateCustomer";
import { AiOutlineEye } from "react-icons/ai";
import DeleteCustomer from "../../customers/components/DeleteCustomer";
import DetailsPanel from "../../customers/components/DetailsPanel";
import { BsSearch } from "react-icons/bs";

export default function Customers() {
  const [selected, setSelected] = useState<any>({});
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isSearched, setSearched] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [creditModal, setCreditModal] = useState(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [detailPanel, setDetailPanel] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [customers, setCustomers] = useState([]);
  const [searchKey, setSearchKey] = useState(null);
  const [form] = Form.useForm();

  const initial = {
    current: 1,
    pageSize: 10,
    limit: 10,
  };
  const [pagination, setPagination] = useState(initial);

  const [emailForm] = Form.useForm();

  const handleFetchCustomers = async ({ pagination }: any) => {
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `customers/paginated?skip=${skip}&limit=${limit}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setCustomers(response?.payload?.results);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handleSearchCustomers = async ({ pagination }: any) => {
    if (!searchKey) return;
    setSearched(true);
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `customers/search?keyword=${searchKey}&skip=${skip}&limit=${limit}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setCustomers(response?.payload?.results);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handlePageChange = (pagination: any) => {
    if (searchKey) {
      handleSearchCustomers({
        pagination,
      });
    } else {
      handleFetchCustomers({
        pagination,
      });
    }
  };

  const handleRefetch = () => {
    handleFetchCustomers({
      pagination,
    });
  };

  const handleFetchAll = async () => {
    setFetching(true);
    await fetchAllCustomers();
    setFetching(false);
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const response = await fetchDataRoad({ uri: "customers" });
    if (response?.success) {
      const data = response?.payload?.map((item: any, index: number) => {
        return {
          "S/N": index + 1,
          Name: item?.name || "-",
          Address1: item?.address1 || "-",
          Address2: item?.address2 || "-",
          Address3: item?.address3 || "-",
          Address4: item?.address4 || "-",
          Email: item?.email || "-",
          Mobile: item?.phone || "-",
          Segment: item?.isCompany ? "Company" : "Individual",
          "Credit Customer": item?.isCredit ? "YES" : "NO",
          "Credit Amount": item?.credit || "-"
        };
      });

      const raw = getExportData(data);
      const uri = await blobToDataUrl(raw);

      const maildata = {
        email: values.email,
        subject: values.subject,
        data: uri,
      };

      const mailuri = "mail";

      await postDataMail(maildata, mailuri);
      setMailLoading(false);

      message.success("Email Sent Successfully");
      emailForm.resetFields();
      toggleMessageModal(false);
    }
  };

  const columns = [
    {
      title: "S/N",
      width: 30,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Name",
      width: 100,
      render: (record: any) => {
        const name = record?.name;
        return (
          <span className="text-gray-700">{startCase(name.toLowerCase())}</span>
        );
      },
    },
    {
      title: "Mobile",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.phone === null ? "-" : record?.phone}
        </span>
      ),
    },
    {
      title: "Email",
      width: 90,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.email === null ? "-" : record?.email}
        </span>
      ),
    },
    {
      title: "Address 1",
      width: 80,
      render: (record: any) => (
        <div className="text-gray-700">
          {record?.address1 === null ? "-" : record?.address1}
        </div>
      ),
    },

    {
      title: "Address 2",
      width: 70,
      render: (record: any) => (
        <div className="text-gray-700">
          {record?.address2 === null ? "-" : record?.address2}
        </div>
      ),
    },
    {
      title: "Address 3",
      width: 70,
      render: (record: any) => (
        <div className="text-gray-700">
          {record?.address3 === null ? "-" : record?.address3}
        </div>
      ),
    },
    {
      title: "Credit Customer",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.isCredit ? (
            <div
              className="flex justify-between items-center"
              onClick={() => {
                setSelected({ ...record });
                setCreditModal(true);
              }}
            >
              <>
                <span className="text-green-600">Yes</span>
                <span className="text-green-600 mx-2">
                  [ {formatMoney(record?.credit)} ]
                </span>
              </>
              <FaRegEdit />
            </div>
          ) : (
            <div
              className="flex justify-between items-center"
              onClick={() => {
                setSelected({ ...record });
                setCreditModal(true);
              }}
            >
              <span className="text-red-600">No</span>
              <FaRegEdit />
            </div>
          )}
        </span>
      ),
    },
    {
      title: "Segment",
      width: 60,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.isCompany ? "Company" : "Individual"}
        </span>
      ),
    },
    {
      title: "Details",
      width: 50,
      render: (record: any) => (
        <div className="flex justify-start">
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setDetailPanel(true);
            }}
            className="flex justify-center items-center ml-2 text-gray-700 border rounded-md p-2 bg-green-100 hover:bg-green-200 cursor-pointer"
          >
            <AiOutlineEye /> Details
          </button>
        </div>
      ),
    },
    {
      title: "Action",
      width: 60,
      render: (record: any) => (
        <div className="flex justify-start">
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setUpdateModal(true);
            }}
            className="flex justify-center items-center text-gray-700 border rounded-md p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
          >
            <FiEdit />
          </button>
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setDeleteModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
          >
            <IoTrashBinOutline />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    handleFetchCustomers({ pagination });
  }, []);

  return (
    <Manage
      title={"Customers"}
      subtitle={"All"}
      description={"List of all customers"}
      actions={
        <div className="flex">
          <div className="flex">
            <Form layout="inline" form={form}>
              <Form.Item name="name">
                <Input
                  name="searchKey"
                  onChange={(e: any) => {
                    setSearchKey(e.target.value);
                    setSearched(false);
                  }}
                  type="text"
                  placeholder="Search by name"
                />
              </Form.Item>
            </Form>
            <Button
              className="-ml-4 focus:outline-none"
              onClick={() => {
                if (isSearched) {
                  setSearched(false);
                  form.resetFields();
                  setSearchKey(null);
                  handleFetchCustomers({ pagination: initial });
                } else {
                  handleSearchCustomers({
                    pagination: initial,
                  });
                }
              }}
            >
              {isSearched ? (
                <RiFilterOffLine color={"#f87171"} />
              ) : (
                <BsSearch color={searchKey ? "#16a34a" : "#9ca3af"} />
              )}
            </Button>
          </div>
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            visible={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() => handleFetchAll()}
            >
              {isFetching ? (
                <BiLoader size={20} />
              ) : (
                <RiFileExcel2Line size={20} />
              )}
            </button>
          </Tooltip>
          <Button onClick={() => setCreateModal(true)}>+ New</Button>
          <CreateCustomer
            isVisible={createModal}
            setVisible={setCreateModal}
            refetch={handleRefetch}
          />

          <UpdateCustomer
            isVisible={updateModal}
            setVisible={setUpdateModal}
            customer={selected}
            refetch={handleRefetch}
          />

          <DeleteCustomer
            isVisible={deleteModal}
            setVisible={setDeleteModal}
            customer={selected}
            refetch={handleRefetch}
          />

          <DetailsPanel
            isVisible={detailPanel}
            setVisible={setDetailPanel}
            selected={selected}
            refetch={handleRefetch}
          />

          <UpdateCredit
            customer={selected}
            isVisible={creditModal}
            setVisible={setCreditModal}
            refetch={handleRefetch}
          />
        </div>
      }
    >
      <>
        <div className="flex flex-col">
          <div className="flex flex-col w-full">
            <div className="grid gap-x-0 grid-cols-12">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                <Table
                  size="small"
                  dataSource={isLoading ? [] : customers}
                  columns={columns}
                  pagination={pagination}
                  scroll={{ y: 660, x: 0 }}
                  onChange={handlePageChange}
                  rowKey="id"
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </Manage>
  );
}
