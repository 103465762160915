import { useState } from "react";
import { BsCheck2Square } from "react-icons/bs";
import CompleteModal from "./CompleteModal";
import ReopenModal from "./ReopenModal";

export default function CompleteWidget({ service, refetch }: any) {
  const [completeModal, setCompleteModal] = useState<boolean>(false);
  const [reopenModal, setReopenModal] = useState<boolean>(false);

  return (
    <>
      {service?.isCompleted ? (
        <button
          onClick={() => setReopenModal(true)}
          className="flex items-center border focus:outline-none hover:font-bold p-2 w-full text-left px-2"
        >
          <BsCheck2Square size={15} className="mr-2" />
          Re-open Work
        </button>
      ) : (
        <button
          onClick={() => setCompleteModal(true)}
          className="flex items-center border focus:outline-none hover:font-bold p-2 w-full text-left px-2"
        >
          <BsCheck2Square size={15} className="mr-2" />
          Complete Work
        </button>
      )}
      <CompleteModal
        isVisible={completeModal}
        setVisible={setCompleteModal}
        service={service}
        refetch={refetch}
      />
      <ReopenModal
        isVisible={reopenModal}
        setVisible={setReopenModal}
        service={service}
        refetch={refetch}
      />
    </>
  );
}
