import { message } from "antd";
import postDataRoad from "../../../../services/postDataRoad";

export default function Vinnumber({ service, refetch }: any) {
  const chassis = service?.vehicle?.chassis;
  const c = chassis === undefined ? "" : chassis;

  const handleUpdateStatus = async (item: any) => {
    return;
    if (service?.isCompleted) {
      message.error("Sorry, This work is completed");
      return;
    }
    const uri = `services/chassis/${item?._id}`;
    let status = !item?.status;
    const data = { status, all: service?.chassis };
    const response = await postDataRoad(data, uri);
    if (response?.success) {
      message.success("Status updated successfully");
      refetch();
    }
  };

  return (
    <div className="mt-3">
      <div className="flex justify-between">
        <div className="">
          <div className="flex">
            <div className="">Chassis Number Condition :</div>
            <div className="flex">
              {service?.chassis?.map((item: any) => (
                <button
                  onClick={() => handleUpdateStatus(item)}
                  className={`w-24 border-r border-black flex justify-center hover:cursor-pointer ${
                    item?.status ? "" : ""
                  }`}
                >
                  <div
                    className={`px-2 border-t border-b rounded-full ${
                      item?.status
                        ? " border-black bg-gray-100"
                        : " border-white hover:bg-gray-200"
                    }`}
                  >
                    {item?.name}
                  </div>
                </button>
              ))}
            </div>
          </div>
          <div className="flex border border-black mt-3 w-full">
            <div className="w-36 border-r border-black px-2 mr-3">
              Chassis #:
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[0] === undefined ? "" : c[0]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[1] === undefined ? "" : c[1]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[2] === undefined ? "" : c[2]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[3] === undefined ? "" : c[3]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[4] === undefined ? "" : c[4]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[5] === undefined ? "" : c[5]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[6] === undefined ? "" : c[6]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[7] === undefined ? "" : c[7]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[8] === undefined ? "" : c[8]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[9] === undefined ? "" : c[9]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[10] === undefined ? "" : c[10]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[11] === undefined ? "" : c[11]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[12] === undefined ? "" : c[12]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[13] === undefined ? "" : c[13]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[14] === undefined ? "" : c[14]}
            </div>
            <div className="w-7 border-r border-black text-center">
              {c[15] === undefined ? "" : c[15]}
            </div>
            <div className="w-7 text-center">
              {c[16] === undefined ? "" : c[16]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
