import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import logo from "../../assets/images/official.png";
import group from "../../assets/images/karimjee.png";
import spin from "../../assets/spin.svg";
import "../../waves.css";
import { BiChevronLeft } from "react-icons/bi";
import postDataTrace from "../../services/postDataTrace";
import { Col, Form, message, Row } from "antd";
import React from "react";
import zxcvbn from "zxcvbn";
import { PasswordInput } from "antd-password-input-strength";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Reset() {
  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  let query = useQuery();
  let userEmail = query.get("email");
  let userToken = query.get("token");
  let userCode = query.get("code");

  const onSubmit = async (e: any) => {
    const { password, cpassword } = form.getFieldsValue();
    setLoading(true);
    e.preventDefault();
    const score = password.length === 0 ? -1 : zxcvbn(password).score;
    if (score < 3) {
      message.error("Use stronger password");
      setLoading(false);
      return;
    } else {
      if (password !== cpassword) {
        message.error("Passwords must match");
        setLoading(false);
        return;
      }
      const data = {
        password,
        token: userToken,
        email: userEmail,
        code: userCode,
      };
      const uri = "users/password/reset/self";
      const response = await postDataTrace(data, uri);
      if (response.success) {
        message.success("Password was updated successfully");
        navigate("/");
      } else {
        message.error("Something went wrong");
        setLoading(false);
      }
    }
  };

  const handleVerifyToken = async () => {
    const uri = `auth/verify`;

    const data = {
      email: userEmail,
      code: userCode,
      _id: userToken,
    };
    if (userEmail === undefined) {
      return;
    }
    const response = await postDataTrace(data, uri);
    if (response.success) {
    } else {
      message.error("Invalid or Expired Token");
      navigate("/");
    }
  };

  useEffect(() => {
    handleVerifyToken();
  }, [userEmail]);

  return (
    <div className="header">
      <div className="inner-header flexo">
        <div className="max-w-2xl w-full pt-24 sm:pt-36 px-5">
          <div className="flex flex-row justify-center w-full">
            <div className="p-4 w-96 bg-gray-100 rounded-lg">
              <div className="text-center mt-2">
                <img className="mx-auto h-16 w-auto my-6" src={logo} alt="" />
                <div className="font-bold text-black my-4">RESET PASSWORD</div>
                <p>Enter and confirm your new password </p>
              </div>
              <div className="mb-4">
                <form className="mt-2">
                  <input type="hidden" name="remember" value="true" />
                  <div className="rounded-md shadow-sm">
                    <Form
                      id="changePassword"
                      form={form}
                      name="normal_login"
                      className="login-form"
                      onFinish={onSubmit}
                    >
                      <Row gutter={[16, 0]}>
                        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                          <Form.Item
                            name="password"
                            className="py-2"
                            rules={[
                              {
                                required: true,
                                message: `Please enter Password`,
                              },
                            ]}
                          >
                            <PasswordInput
                              inputProps={{
                                size: "middle",
                                placeholder: "New Password",
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 0]} className="-mt-6">
                        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                          <Form.Item
                            name="cpassword"
                            rules={[
                              {
                                required: true,
                                message: `Please enter Password`,
                              },
                            ]}
                          >
                            <PasswordInput
                              inputProps={{
                                size: "middle",
                                placeholder: "Confirm Password",
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>

                  <Link
                    to="/"
                    className="text-xs text-center flex items-center text-karimjee hover:text-toyota mt-2 py-2 rounded-md"
                  >
                    <BiChevronLeft size={20} />
                    Login
                  </Link>

                  <div className="border-t border-gray-300  max-w-md mt-4 mb-4 w-full"></div>

                  <div className="mt-4 mb-4">
                    <button
                      onClick={(e: any) => onSubmit(e)}
                      className="group relative w-full flex justify-center py-3 sm:py-2 px-4
                              border border-transparent text-sm leading-5 font-medium
                              rounded-md text-white bg-toyota hover:bg-red-700
                              focus:outline-none focus:border-toyota
                              focus:shadow-outline-indigo active:bg-toyota transition
                              duration-150 ease-in-out"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                      {isLoading ? (
                        <span>
                          <img
                            src={spin}
                            alt="spin"
                            height="20px"
                            width="20px"
                          />
                        </span>
                      ) : (
                        <span className="text-white">Submit</span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(255,255,255,0.7"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255,255,255,0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255,255,255,0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </div>
    </div>
  );
}
