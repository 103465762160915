import { Form, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import formatMoney from "../../utils/formatMoney";
import { blobToDataUrl, getExportData } from "../../utils/exportExcel";
import postDataMain from "../../services/postDataMail";
import MixiToolnav from "../../components/toolnav/MixiToolnav";
import useQuery from "../../hooks/useQuery";
import fetchDataRoad from "../../services/fetchDataRoad";
import moment from "moment";
import { MdCorporateFare } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import CreateContact from "./components/CreateContact";
import { formatNumber } from "../../utils/formatNumber";
import CreateVehicleModal from "./components/CreateVehicleModal";

export default function CusDetails() {
  const [services, setServices] = useState<any>([]);
  const [customer, setCustomer] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [vehicleModal, setVehicleModal] = useState<boolean>(false);

  let query = useQuery();
  let name = query.get("name");

  const { id } = useParams();

  const navigate = useNavigate();

  const [emailForm] = Form.useForm();
  const curUrl = `/customers/${id}/details?name=${name}`;

  const handleFetchCustomer = async () => {
    setLoading(true);
    const uri = `customers/${id}`;
    const response = await fetchDataRoad({ uri });
    setCustomer(response?.payload);
    setLoading(false);
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = services?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Customer: item?.customer?.name === null ? "-" : item?.customer?.name,
        "Vehicle Registration":
          item?.vehicle?.registration === null
            ? "-"
            : item?.vehicle?.registration,
        "Vehicle Model":
          item?.vehicle?.model?.name === null
            ? "-"
            : item?.vehicle?.model?.name,
        Technician:
          item?.technician?.name === null ? "-" : item?.technician?.name,
        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
        Status: item?.status === null ? "-" : item?.status,
        Amount: formatMoney(getTotal(item?.items)),
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMain(maildata, mailuri);
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total = total + item?.amount;
    });

    return total;
  };

  useEffect(() => {
    handleFetchCustomer();
  }, []);
  return (
    <>
      <MixiToolnav
        link="/customers"
        links={[
          {
            name: "Customer Services",
            to: `/customers/${customer?._id}/services/?name=${customer?.name}`,
          },
          { name: "Customer Details", to: `${curUrl}` },
        ]}
      />
      <main className="md:flex px-4 sm:px-4 lg:px-4 py-2">
        <div className="bg-white align-middle min-w-full border overflow-hidden sm:rounded-lg">
          <div className="p-4 mb-8">
            <div className="flex">
              <div className="flex justify-center items-center w-20 h-20 bg-gray-200 rounded-lg">
                {customer?.isCompany ? (
                  <div className="flex flex-col justify-center items-center">
                    <MdCorporateFare size={40} />
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center">
                    <FiUser size={40} />
                  </div>
                )}
              </div>
              <div className="ml-4">
                <div className="text-lg ">
                  <span className="font-bold mr-1">Customer :</span>
                  <span>{name}</span>
                </div>
                <div className="">
                  <div className="flex items-center">
                    <div className="">
                      Credit Customer :
                      {customer?.isCredit ? (
                        <span className="text-green-500">Yes</span>
                      ) : (
                        <span className="text-red-500">No</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="">
                  {customer?.isCredit ? (
                    <>
                      Credit :
                      <span className="text-green-500 mx-2">
                        [ {formatMoney(customer?.credit)} TZS ]
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mt-4 border-t">
              <div className="grid grid-cols-12 gap-x-6 mt-2">
                <div className="col-span-4 border-r">
                  <div className="font-bold mb-2">CUSTOMER PROFILE</div>
                  <div className="flex flex-col">
                    <span>
                      Customer Segment :{" "}
                      {customer?.isCompany ? "Company" : "Invidual"}
                    </span>
                    <span>{customer?.phone ? customer?.phone : ""}</span>
                    <span>{customer?.email ? customer?.email : ""}</span>
                    <span>{customer?.address1 ? customer?.address1 : ""}</span>
                    <span>{customer?.address2 ? customer?.address2 : ""}</span>
                    <span>{customer?.address3 ? customer?.address3 : ""}</span>
                    <span>{customer?.address4 ? customer?.address4 : ""}</span>
                  </div>

                  <div className="flex justify-between items-center mt-8 mr-2">
                    <div className="font-bold">CUSTOMER VEHICLE(S)</div>
                    <button
                      className="border py-1 px-2 bg-green-100"
                      onClick={() => setVehicleModal(true)}
                    >
                      + Add
                    </button>
                  </div>
                  <div className="">
                    {customer?.vehicles?.length > 0 ? (
                      <div className="">
                        <div className="flex py-2">
                          <div className="w-12">S/N.</div>
                          <div className="w-32">Registration</div>
                          <div className="">Mileage</div>
                        </div>
                        {customer?.vehicles?.map(
                          (vehicle: any, index: number) => (
                            <div className="flex" key={index}>
                              <div className="w-12">{index + 1}.</div>
                              <div className="w-32">
                                {vehicle?.registration}
                              </div>
                              <div className="">
                                {formatNumber(Number(vehicle?.mileage) ?? 0)} KM
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className="text-gray-500 text-sm my-2">
                        No Vehicles Found
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-span-8">
                  <div className="flex justify-between">
                    <div className="uppercase font-bold">
                      Contact Person(s) / Driver(s)
                    </div>
                    <button
                      className="border py-1 px-2 bg-green-100"
                      onClick={() => setContactModal(true)}
                    >
                      + Add
                    </button>
                  </div>
                  <div className="mt-2">
                    {customer?.contacts?.map((item: any, index: number) => (
                      <div className="py-2" key={index}>
                        <div className="flex flex-col">
                          <div className="">Name: {item?.name}</div>
                          <div className="">
                            Mobile: {item?.mobile ? item?.mobile : "-"}
                          </div>
                          <div className="">
                            Email: {item?.email ? item?.email : "-"}
                          </div>
                          <div className="">
                            Address: {item?.address ? item?.address : "-"}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CreateContact
          customer={customer}
          isVisible={contactModal}
          setVisible={setContactModal}
          refetch={handleFetchCustomer}
        />

        <CreateVehicleModal
          customerId={customer?._id}
          isVisible={vehicleModal}
          setVisible={setVehicleModal}
          refetch={handleFetchCustomer}
        />
      </main>
    </>
  );
}
