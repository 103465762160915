import {
  Table,
  Input,
  Button,
  Space,
  Form,
  message,
  Tooltip,
  Popover,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { IoTrashBinOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";
import postDataMain from "../../../services/postDataMail";
import fetchDataRoad from "../../../services/fetchDataRoad";
import { formatNumber } from "../../../utils/formatNumber";
import UpdateVehicle from "../../vehicles/components/UpdateVehicle";
import DeleteVehicle from "../../vehicles/components/DeleteVehicle";
import Manage from "..";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import CreateVehicle from "../../vehicles/components/CreateVehicle";
import { BsSearch } from "react-icons/bs";

export default function Vehicles() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSearched, setSearched] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<any>([]);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [searchKey, setSearchKey] = useState<any>(null);
  const [form] = Form.useForm();

  const initial = {
    current: 1,
    pageSize: 10,
    limit: 10,
  };
  const [pagination, setPagination] = useState(initial);

  const [emailForm] = Form.useForm();

  const handleFetchVehicles = async ({ pagination }: any) => {
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `vehicles/paginated?skip=${skip}&limit=${limit}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setVehicles(response?.payload?.results);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handleSearchVehicles = async ({ pagination }: any) => {
    if (!searchKey) return;
    setSearched(true);
    setLoading(true);

    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const key = searchKey.replace(/\s/g, "").toUpperCase();
    const uri = `vehicles/search?keyword=${key}&skip=${skip}&limit=${limit}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setVehicles(response?.payload?.results);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });

  const seRef: any = useRef(Node);

  const handlePageChange = (pagination: any) => {
    if (searchKey) {
      handleSearchVehicles({
        pagination,
      });
    } else {
      handleFetchVehicles({
        pagination,
      });
    }
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = vehicles?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Registration: item?.registration === null ? "-" : item?.registration,
        Make: item?.make === null ? "-" : item?.make,
        Model: item?.model === null ? "-" : item?.model,
        Chassis: item?.chassis === null ? "-" : item?.chassis,
        Year: item?.year === null ? "-" : item?.year,
        Mileage: item?.mileage === null ? "-" : item?.mileage,
        Customer: item?.customer?.name === null ? "-" : item?.customer?.name,
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMain(maildata, mailuri);
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={seRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            danger
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            size="small"
            danger
            onClick={() => {
              handleReset(clearFilters);
              confirm({ closeDropdown: true });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => seRef.select(), 100);
      }
    },
    render: (text: any) => (state.searchedColumn === dataIndex ? "dhj" : text),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setState({ searchText: "", searchedColumn: "" });
  };

  const columns = [
    {
      title: "S/N",
      width: 50,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },

    {
      title: "Registration",
      width: 100,
      ...getColumnSearchProps("registration"),
      render: (record: any) => (
        <span className="text-gray-700">{record?.registration}</span>
      ),
    },
    {
      title: "Make",
      width: 60,
      ...getColumnSearchProps("registration"),
      render: (record: any) => (
        <span className="text-gray-700">{record?.make}</span>
      ),
    },
    {
      title: "Model",
      width: 120,
      ...getColumnSearchProps("model"),
      render: (record: any) => (
        <span className="text-gray-700">{record?.model?.name}</span>
      ),
    },
    {
      title: "Chassis",
      width: 120,
      ...getColumnSearchProps("model"),
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.chassis ? record?.chassis : 0}
        </span>
      ),
    },
    {
      title: "Year",
      width: 50,
      render: (record: any) => (
        <span className="text-gray-700">{record?.year ?? 0}</span>
      ),
    },
    {
      title: "Mileage",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {formatNumber(Number(record?.mileage)) ?? 0}
          <span className="ml-1">Km</span>
        </span>
      ),
    },

    {
      title: "Customer",
      width: 100,
      render: (record: any) => (
        <div className="text-gray-700 truncate">{record?.customer?.name}</div>
      ),
    },
    {
      title: "Action",
      width: 60,
      render: (record: any) => (
        <div className="flex justify-start">
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setUpdateModal(true);
            }}
            className="flex justify-center items-center text-gray-700 border rounded-md p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
          >
            <FiEdit />
          </button>
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setDeleteModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
          >
            <IoTrashBinOutline />
          </button>
        </div>
      ),
    },
  ];

  const handleRefetch = () => {
    handleFetchVehicles({
      pagination,
    });
  };

  useEffect(() => {
    handleFetchVehicles({ pagination });
  }, []);

  return (
    <Manage
      title={"Vehicles"}
      subtitle={"All"}
      description={"List of all vehicles"}
      actions={
        <div className="flex">
          <div className="flex">
            <Form layout="inline" form={form}>
              <Form.Item name="name">
                <Input
                  name="searchKey"
                  onChange={(e: any) => {
                    setSearchKey(e.target.value);
                    setSearched(false);
                  }}
                  type="text"
                  placeholder="Search by registration"
                />
              </Form.Item>
            </Form>
            <Button
              className="-ml-4 focus:outline-none"
              onClick={() => {
                if (isSearched) {
                  setSearched(false);
                  form.resetFields();
                  setSearchKey(null);
                  handleFetchVehicles({ pagination: initial });
                } else {
                  handleSearchVehicles({
                    pagination: initial,
                  });
                }
              }}
            >
              {isSearched ? (
                <RiFilterOffLine color={"#f87171"} />
              ) : (
                <BsSearch color={searchKey ? "#16a34a" : "#9ca3af"} />
              )}
            </Button>
          </div>

          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            visible={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() =>
                ExportToExcel(
                  vehicles?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Registration:
                        item?.registration === null ? "-" : item?.registration,
                      Make: item?.make === null ? "-" : item?.make,
                      Model: item?.model === null ? "-" : item?.model,
                      Chassis: item?.chassis === null ? "-" : item?.chassis,
                      Year: item?.year === null ? "-" : item?.year,
                      Mileage: item?.mileage === null ? "-" : item?.mileage,
                      Customer:
                        item?.customer?.name === null
                          ? "-"
                          : item?.customer?.name,
                    };
                  }),
                  "Vehicles.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </Tooltip>
          <Button onClick={() => setCreateModal(true)}>+ New</Button>
          <CreateVehicle
            isVisible={createModal}
            setVisible={setCreateModal}
            refetch={handleRefetch}
          />
        </div>
      }
    >
      <div className="grid gap-x-0 grid-cols-12">
        <div className="col-span-12 sm:col-span-12 md:col-span-12">
          <Table
            columns={columns}
            dataSource={vehicles}
            size="small"
            pagination={pagination}
            scroll={{ y: 660, x: 0 }}
            onChange={handlePageChange}
            rowKey="id"
            loading={isLoading}
          />
        </div>

        <UpdateVehicle
          selected={selected}
          isVisible={updateModal}
          setUpdateModal={setUpdateModal}
          refetch={handleRefetch}
        />

        <DeleteVehicle
          selected={selected}
          isVisible={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      </div>
    </Manage>
  );
}
