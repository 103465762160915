import { BiArrowBack } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { IoWarningOutline } from "react-icons/io5";

export default function index() {
  return (
    <div className="flex flex-col justify-center items-center ">
      <div className=" flex items-center justify-center  mt-36">
        <IoWarningOutline size={20} color={"#4145A8"} />
        <span className="text-lg text-toyota ml-1">Page Not Found</span>
      </div>
      <div className="text-9xl mb-4">404</div>

      <NavLink
        to="/dashboard"
        className="flex justify-center items-center text-toyota border p-2 hover:text-toyota-dark hover:border-toyota"
      >
        <BiArrowBack />
        <span className="ml-2">Back</span>
      </NavLink>
    </div>
  );
}
