import One from "./terms/One";
import Five from "./terms/Five";
import Two from "./terms/Two";
import Three from "./terms/Three";
import Four from "./terms/Four";
import Six from "./terms/Six";

export default function TermsWrapperEn() {
  return (
    <div className="book" id="invoice-doc">
      <div className="page">
        <div className="subpage2">
          <div className="p-1">
            <div className="font-thin mb-4">
              <div style={{ fontSize: 22 }} className="flex">
                Toyota Tanzania Roadside Assistance
              </div>
              <div style={{ fontSize: 22 }} className="flex">
                Terms and Conditions
              </div>
            </div>

            <div className="flex">
              <div style={{ fontSize: 8 }} className="w-1/2 mr-2">
                <One />
                <Two />
                <Three />
                <Four />
              </div>

              <div style={{ fontSize: 8 }} className="w-1/2 ml-2">
                <Five />
                <Six />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
