import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { BsEye, BsSearch } from "react-icons/bs";
import { startCase } from "lodash";
import { NavLink } from "react-router-dom";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import Wrapper from "../../components/shared/Wrapper";
import PageToolnav from "../../components/toolnav/PageToolnav";
import { blobToDataUrl, getExportData } from "../../utils/exportExcel";

import CreateCustomer from "./components/CreateCustomer";
import fetchDataRoad from "../../services/fetchDataRoad";
import formatMoney from "../../utils/formatMoney";
import { BiLoader } from "react-icons/bi";
import postDataMail from "../../services/postDataMail";
import { fetchAllCustomers } from "../../utils/dataExports";

export default function AllCustomers() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSearched, setSearched] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [searchKey, setSearchKey] = useState(null);
  const [form] = Form.useForm();

  const initial = {
    current: 1,
    pageSize: 10,
    limit: 10,
  };
  const [pagination, setPagination] = useState(initial);

  const [emailForm] = Form.useForm();

  const handleFetchCustomers = async ({ pagination }: any) => {
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `customers/paginated?skip=${skip}&limit=${limit}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setCustomers(response?.payload?.results);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handleSearchCustomers = async ({ pagination }: any) => {
    if (!searchKey) return;
    setSearched(true);
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `customers/search?keyword=${searchKey}&skip=${skip}&limit=${limit}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setCustomers(response?.payload?.results);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handleFetchAll = async () => {
    setFetching(true);
    await fetchAllCustomers();
    setFetching(false);
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const response = await fetchDataRoad({ uri: "customers" });
    if (response?.success) {
      const data = response?.payload?.map((item: any, index: number) => {
        return {
          "S/N": index + 1,
          Name: item?.name === null ? "-" : item?.name,
          Address1: item?.address1 === null ? "-" : item?.address1,
          Address2: item?.address2 === null ? "-" : item?.address2,
          Address3: item?.address3 === null ? "-" : item?.address3,
          Address4: item?.address4 === null ? "-" : item?.address4,
          Email: item?.email === null ? "-" : item?.email,
          Mobile: item?.phone === null ? "-" : item?.phone,
          Segment: item?.isCompany ? "Company" : "Individual",
        };
      });

      const raw = getExportData(data);
      const uri = await blobToDataUrl(raw);

      const maildata = {
        email: values.email,
        subject: values.subject,
        data: uri,
      };

      const mailuri = "mail";

      await postDataMail(maildata, mailuri);
      setMailLoading(false);

      message.success("Email Sent Successfully");
      emailForm.resetFields();
      toggleMessageModal(false);
    }
  };

  const handlePageChange = (pagination: any) => {
    if (searchKey) {
      handleSearchCustomers({
        pagination,
      });
    } else {
      handleFetchCustomers({
        pagination,
      });
    }
  };

  const columns = [
    {
      title: "S/N",
      width: 20,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "Name",
      width: 100,
      render: (record: any) => {
        const name = record?.name;
        return (
          <span className="text-gray-700">{startCase(name.toLowerCase())}</span>
        );
      },
    },
    {
      title: "Mobile",
      width: 50,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.phone === null ? "-" : record?.phone}
        </span>
      ),
    },
    {
      title: "Email",
      width: 90,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.email === null ? "-" : record?.email}
        </span>
      ),
    },
    {
      title: "Address 1",
      width: 80,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.address1 === null ? "-" : record?.address1}
        </span>
      ),
    },

    {
      title: "Address 2",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.address2 === null ? "-" : record?.address2}
        </span>
      ),
    },
    {
      title: "Address 3",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.address3 === null ? "-" : record?.address3}
        </span>
      ),
    },
    {
      title: "Credit Customer",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.isCredit ? (
            <>
              <span className="text-green-600">Yes</span>
              <span className="text-green-600 mx-2">
                [ {formatMoney(record?.credit)} ]
              </span>
            </>
          ) : (
            <>
              <span className="text-red-600">No</span>
            </>
          )}
        </span>
      ),
    },
    {
      title: "Segment",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.isCompany ? (
            <span className="">Company</span>
          ) : (
            <span className="">Individual</span>
          )}
        </span>
      ),
    },
    {
      title: "Services",
      width: 40,
      render: (record: any) => (
        <NavLink
          to={`/customers/${record?._id}/services?name=${record?.name}`}
          className="flex justify-center items-center ml-1 text-gray-700 border rounded-md px-2 py-1 bg-blue-100 hover:bg-blue-200 cursor-pointer"
        >
          <span className="ml-1">Services</span>
        </NavLink>
      ),
    },
    {
      title: "Details",
      width: 40,
      render: (record: any) => (
        <NavLink
          to={`/customers/${record?._id}/details?name=${record?.name}`}
          className="flex justify-center items-center ml-1 text-gray-700 border rounded-md px-2 py-1 bg-gray-100 hover:bg-gray-200 cursor-pointer"
        >
          <BsEye size={18} /> <span className="ml-1">Details</span>
        </NavLink>
      ),
    },
  ];

  useEffect(() => {
    handleFetchCustomers({ pagination });
  }, []);

  return (
    <>
      <PageToolnav link={{ name: "All Customers", to: "/customers" }} />
      <Wrapper
        title="CUSTOMERS"
        subtitle={`All`}
        description="List of all customers"
        actions={
          <div className="flex">
            <div className="flex">
              <Form layout="inline" form={form}>
                <Form.Item name="name">
                  <Input
                    name="searchKey"
                    onChange={(e: any) => {
                      setSearchKey(e.target.value);
                      setSearched(false);
                    }}
                    type="text"
                    placeholder="Search by name"
                  />
                </Form.Item>
              </Form>
              <Button
                className="-ml-4 focus:outline-none"
                onClick={() => {
                  if (isSearched) {
                    setSearched(false);
                    form.resetFields();
                    setSearchKey(null);
                    handleFetchCustomers({ pagination: initial });
                  } else {
                    handleSearchCustomers({
                      pagination: initial,
                    });
                  }
                }}
              >
                {isSearched ? (
                  <RiFilterOffLine color={"#f87171"} />
                ) : (
                  <BsSearch color={searchKey ? "#16a34a" : "#9ca3af"} />
                )}
              </Button>
            </div>
            <Popover
              content={
                <>
                  <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    form={emailForm}
                    onFinish={sendMail}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is Required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        { required: true, message: "Subject is Required" },
                      ]}
                    >
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <div className="flex flex-row justify-between">
                      <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                        <Button type="primary" htmlType="submit">
                          {isMailLoading ? "Sending ..." : "Submit"}
                        </Button>
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                        <Button
                          onClick={() => {
                            toggleMessageModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              }
              title="Message Content"
              trigger="focus"
              placement="left"
              visible={messageModal}
            >
              <Tooltip title="Send Email">
                <button
                  className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={async () => {
                    toggleMessageModal(true);
                  }}
                >
                  <RiMailSendLine size={21} />
                </button>
              </Tooltip>
            </Popover>
            <Tooltip title="Export To Excel">
              <button
                color="#E50000"
                className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={() => handleFetchAll()}
              >
                {isFetching ? (
                  <BiLoader size={20} />
                ) : (
                  <RiFileExcel2Line size={20} />
                )}
              </button>
            </Tooltip>
            <Button onClick={() => setCreateModal(true)}>+ New</Button>
            <CreateCustomer
              isVisible={createModal}
              setVisible={setCreateModal}
              refetch={handleFetchCustomers}
            />
          </div>
        }
      >
        <div className="flex flex-col">
          <div className="flex flex-col w-full">
            <div className="grid gap-x-0 grid-cols-12">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                <Table
                  size="middle"
                  dataSource={isLoading ? [] : customers}
                  columns={columns}
                  pagination={pagination}
                  scroll={{ y: 660, x: 0 }}
                  onChange={handlePageChange}
                  rowKey="id"
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}
