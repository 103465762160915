export default function Two() {
  return (
    <div className="mt-1">
      <div className="">
        <div style={{ fontSize: 12 }}>Safety First</div>
        <div className="">
          If your vehicle has broken down in a hazardous location, please advise
          the operator when you call and ensure you are not exposed to danger
          from oncoming vehicles.
        </div>
      </div>
      <div className="">
        <div className="font-bold mt-1">Stay with your vehicle</div>
        <div className="">
          Once assistance has been called, it’s vital that you remain with your
          vehicle. If our Response Team arrives at the scene of the breakdown
          and your vehicle is unattended, we will be unable to carry out any
          work and additional payment may be required for any subsequent
          callouts for the same incident. If you have to leave your vehicle for
          safety reasons, please advise our operator.
        </div>
      </div>
    </div>
  );
}
