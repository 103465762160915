import formatMoney from "../../../utils/formatMoney";

export default function InvoiceItemRead({ item }: any) {
  const { invoice_desc } = item;
  return (
    <div className="grid grid-cols-12 border-t border-black items-center">
      <div className="col-span-10 p-1 border-r border-black  text-left">
        {invoice_desc}
      </div>
      <div className="col-span-2 p-1  text-right">
        {formatMoney(
          Number(item?.labourrate * item?.flattime + item?.partsrate)
        )}
      </div>
    </div>
  );
}
