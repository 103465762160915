export default function One() {
  return (
    <div className="">
      <div className="">
        Toyota Tanzania 24/7 Roadside Assistance is a dedicated automotive
        assistance service for your vehicle and is provided 24 hours a day, 365
        days a year in Dar es Salaam only.
      </div>

      <div className="mt-2 font-bold">Your checklist when calling for help</div>
      <div className="">
        To access Toyota Tanzania 24/7 Roadside Assistance simply call us on
        0746 986 560, and have the following information on hand:
      </div>
      <div className="ml-6">
        <li>Your name, company (if applicable) and exact location</li>
        <li>Vehicle registration number and color of your vehicle</li>
        <li>Vehicle model</li>
        <li>Description of the issue encountered</li>
        <li>A telephone number where you can be contacted</li>
      </div>
      <div style={{ fontSize: 8 }} className="italic mt-4">
        All calls to Toyota Tanzania 24/7 Roadside Assistance are recorded. This
        will help us to confirm details of a call that may be incomplete or
        unclear.
      </div>
    </div>
  );
}
