import { message } from "antd";
import { GiCheckMark } from "react-icons/gi";
import postDataRoad from "../../../../../services/postDataRoad";

export default function CheckWidget({
  service,
  item,
  category,
  all,
  refetch,
}: any) {
  const handleUpdateStatus = async () => {
    return;
    if (service?.isCompleted) {
      message.error("Sorry, This work is completed");
      return;
    }
    const uri = `services/${category}/${item?._id}`;
    let status = !item?.status;
    const data = { status, all: all };
    const response = await postDataRoad(data, uri);
    if (response?.success) {
      message.success("Status updated successfully");
      refetch();
    }
  };
  return (
    <div className="flex justify-between border-t border-black pl-2">
      <div className="">{item?.name}</div>
      <button
        onClick={() => handleUpdateStatus()}
        className="w-6 border-l border-black"
      >
        {item?.status ? (
          <div className="flex justify-center items-center">
            {item?.status ? <GiCheckMark /> : null}
          </div>
        ) : null}
      </button>
    </div>
  );
}
