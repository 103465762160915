import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function AdminLink({ menu }: any) {
  const { to, icon, name } = menu;
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  return (
    <div
      className={`flex justify-center items-center mb-6 py-2 ${
        match
          ? "border-r-2 border-toyota bg-red-100"
          : "border-r-2 border-red-50"
      }`}
    >
      <Link to={to}>
        <div className="flex flex-col justify-center items-center">
          <div
            className={`flex flex-col justify-center items-center  rounded-lg ${
              match ? "" : ""
            }`}
          >
            <div className={`my-2 ${match ? "text-toyota" : "text-gray-600"}`}>
              {icon}
            </div>
          </div>
          <div className={`text-xs  ${match ? "text-toyota" : "text-black"}`}>
            {name}
          </div>
        </div>
      </Link>
    </div>
  );
}
