import { useEffect, useState } from "react";
import { Button, Modal, Form, message } from "antd";
import postDataRoad from "../../../../services/postDataRoad";

export default function ReopenModal({
  service,
  isVisible,
  setVisible,
  refetch,
}: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {}, [service]);

  const onSubmit = async () => {
    setLoading(true);
    const uri = `services/${service?._id}/reopen`;
    const data = {};

    const response = await postDataRoad(data, uri);
    if (response.success) {
      message.success("Re-opened Successfully");
      refetch();
    }
    setVisible(false);
    setLoading(false);
  };

  const hideModal = async () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={isVisible}
      title="Re-open Work"
      onCancel={hideModal}
      footer={[
        <Button danger key="back" onClick={hideModal}>
          No
        </Button>,
        <Button
          key="submit"
          form="ReopenService"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Yes
        </Button>,
      ]}
    >
      <Form id="ReopenService" form={form} onFinish={onSubmit}>
        <div>
          <p className="text-center font-bold">
            Are you sure you want to
            <span className="text-green-600 font-bold mx-1">re-open</span>
            this work?
          </p>
          <div className="pb-4 flex flex-col justify-center items-center">
            <div style={{ fontSize: 10 }} className="text-toyota">
              Note: Email and SMS Notifications will be sent to responsible
              personel
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
