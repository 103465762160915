import { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  LabelList,
  Bar,
  BarChart,
} from "recharts";
import formatShort from "../../../utils/formatShort";
import servicesYearly from "../../../utils/servicesYearly";
import MainPanel from "./MainPanel";
import dummy from "../../../utils/dummy";

export default function MainChart({ isLoading, data }: any) {
  const [services, setServices] = useState<any>(dummy);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const [title, setTitle] = useState<any>("");

  const labelFormatter = (value: any) => {
    return formatShort(value);
  };

  const handleFormatData = async () => {
    const formated = servicesYearly(data);
    setServices(formated);
  };

  useEffect(() => {
    handleFormatData();
  }, [isLoading]);

  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={468}>
        <BarChart
          width={730}
          height={250}
          data={services?.data}
          margin={{ top: 25, right: 30, left: 20, bottom: 5 }}
          onClick={(e: any) => {
            setSelected(e?.activePayload[0]?.payload?.data);
            setVisible(true);
            setTitle(e.activePayload[0]?.payload?.month);
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name">
            <Label offset={0} position="insideBottom" />
          </XAxis>
          <YAxis label={{ value: "", angle: -90, position: "insideLeft" }} />
          <Bar dataKey="amount" fill="#16a34a" barSize={20}>
            <LabelList
              dataKey="amount"
              position="top"
              formatter={labelFormatter}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <MainPanel
        isVisible={isVisible}
        setVisible={setVisible}
        data={selected}
        title={title}
      />
    </div>
  );
}
