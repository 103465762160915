import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { BsEye, BsSearch } from "react-icons/bs";
import Wrapper from "../../components/shared/Wrapper";
import PageToolnav from "../../components/toolnav/PageToolnav";
import formatMoney from "../../utils/formatMoney";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../utils/exportExcel";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import postDataMain from "../../services/postDataMail";
import fetchDataRoad from "../../services/fetchDataRoad";
import Moment from "react-moment";
import moment from "moment";
import CreateService from "./components/CreateService";
import { NavLink, useNavigate } from "react-router-dom";

export default function AllServices() {
  const [services, setServices] = useState<any>([]);

  const [isSearched, setSearched] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [searchKey, setSearchKey] = useState<any>(null);
  const [form] = Form.useForm();

  const initial = {
    current: 1,
    pageSize: 10,
    limit: 10,
  };
  const [pagination, setPagination] = useState(initial);

  const [emailForm] = Form.useForm();

  const handleFetchServices = async ({ pagination }: any) => {
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `services/paginated?skip=${skip}&limit=${limit}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setServices(response?.payload?.results);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handleSearchServices = async ({ pagination }: any) => {
    if (!searchKey) return;
    setSearched(true);
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const key = searchKey.replace(/\s/g, "").toUpperCase();
    const uri = `services/search?keyword=${key}&skip=${skip}&limit=${limit}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setServices(response?.payload?.results);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const getTotal = (items: any) => {
    let total = 0;
    items?.forEach((item: any) => {
      total =
        total + (item?.labourrate * item?.flattime + item?.partsrate) * 1.18;
    });
    return isNaN(total) ? 0 : total;
  };

  const navigate = useNavigate()

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = services?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Customer: item?.customer?.name === null ? "-" : item?.customer?.name,
        "Vehicle Registration":
          item?.vehicle?.registration === null
            ? "-"
            : item?.vehicle?.registration,
        "Vehicle Model":
          item?.vehicle?.model?.name === null
            ? "-"
            : item?.vehicle?.model?.name,
        Technician:
          item?.technician?.name === null ? "-" : item?.technician?.name,
        "Transaction Id":
          item?.transactionId === null ? "-" : item?.transactionId,
        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
        Status: item?.status === null ? "-" : item?.status,
        Amount: formatMoney(getTotal(item?.items)),
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMain(maildata, mailuri);
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const handlePageChange = (pagination: any) => {
    if (searchKey) {
      handleSearchServices({
        pagination,
      });
    } else {
      handleFetchServices({
        pagination,
      });
    }
  };

  const columns = [
    {
      title: "S/N",
      width: 20,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">   {pagination?.current * pagination?.limit -
          pagination?.limit +
          index +
          1}</span>
      ),
    },
    {
      title: "Customer",
      width: 100,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.customer?.name ? record?.customer?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Vehicle",
      width: 50,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.vehicle?.registration ? record?.vehicle?.registration : "-"}
        </span>
      ),
    },
    {
      title: "Model",
      width: 90,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.vehicle?.model?.name ? record?.vehicle?.model?.name : "-"}
        </span>
      ),
    },
    {
      title: "Technician",
      width: 80,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.technician?.name ? record?.technician?.name : "-"}
        </span>
      ),
    },

    {
      title: "Transaction Id",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.transactionId ? record?.transactionId : "-"}
        </span>
      ),
    },
    {
      title: "Created",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          <Moment fromNow>{record?.createdAt}</Moment>
        </span>
      ),
    },
    {
      title: "Amount",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">
          {formatMoney(getTotal(record?.items))}
        </span>
      ),
    },
    {
      title: "Status",
      width: 40,
      render: (record: any) => (
        <>
          {record?.isCompleted ? (
            <div className="text-green-600">
              {record?.status ? record?.status : "-"}
            </div>
          ) : (
            <div className="text-amber-500">
              {record?.status ? record?.status : "-"}
            </div>
          )}
        </>
      ),
    }
  ];

  useEffect(() => {
    handleFetchServices({ pagination });
  }, []);
  return (
    <>
      <PageToolnav link={{ name: "All Services", to: "/services" }} />
      <Wrapper
        title="Services"
        subtitle={`All`}
        description="List of all services"
        actions={
          <div className="flex">
            <div className="flex">
              <Form layout="inline" form={form}>
                <Form.Item name="name">
                  <Input
                    name="searchKey"
                    onChange={(e: any) => {
                      setSearchKey(e.target.value);
                      setSearched(false);
                    }}
                    type="text"
                    placeholder="Search by registration"
                  />
                </Form.Item>
              </Form>
              <Button
                className="-ml-4 focus:outline-none"
                onClick={() => {
                  if (isSearched) {
                    setSearched(false);
                    form.resetFields();
                    setSearchKey(null);
                    handleFetchServices({ pagination: initial });
                  } else {
                    handleSearchServices({
                      pagination: initial,
                    });
                  }
                }}
              >
                {isSearched ? (
                  <RiFilterOffLine color={"#f87171"} />
                ) : (
                  <BsSearch color={searchKey ? "#16a34a" : "#9ca3af"} />
                )}
              </Button>
            </div>
            <Popover
              content={
                <>
                  <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    form={emailForm}
                    onFinish={sendMail}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is Required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        { required: true, message: "Subject is Required" },
                      ]}
                    >
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <div className="flex flex-row justify-between">
                      <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                        <Button type="primary" htmlType="submit">
                          {isMailLoading ? "Sending ..." : "Submit"}
                        </Button>
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                        <Button
                          onClick={() => {
                            toggleMessageModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              }
              title="Message Content"
              trigger="focus"
              placement="left"
              visible={messageModal}
            >
              <Tooltip title="Send Email">
                <button
                  className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={async () => {
                    toggleMessageModal(true);
                  }}
                >
                  <RiMailSendLine size={21} />
                </button>
              </Tooltip>
            </Popover>
            <Tooltip title="Export To Excel">
              <button
                color="#E50000"
                className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={() =>
                  ExportToExcel(
                    services?.map((item: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        Customer:
                          item?.customer?.name === null
                            ? "-"
                            : item?.customer?.name,
                        "Vehicle Registration":
                          item?.vehicle?.registration === null
                            ? "-"
                            : item?.vehicle?.registration,
                        "Vehicle Model":
                          item?.vehicle?.model?.name === null
                            ? "-"
                            : item?.vehicle?.model?.name,
                        Technician:
                          item?.technician?.name === null
                            ? "-"
                            : item?.technician?.name,
                        "Transaction Id":
                          item?.transactionId === null
                            ? "-"
                            : item?.transactionId,
                        Created: moment(item?.createdAt).format("DD/MM/YYYY"),
                        Status: item?.status === null ? "-" : item?.status,
                        Amount: formatMoney(getTotal(item?.items)),
                      };
                    }),
                    "Services.xlsx"
                  )
                }
              >
                <RiFileExcel2Line size={20} />
              </button>
            </Tooltip>
            <Button onClick={() => setCreateModal(true)}>+ New</Button>
          </div>
        }
      >
        <div className="flex flex-col">
          <div className="flex flex-col w-full">
            <div className="grid gap-x-0 grid-cols-12">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                <Table
                  size="middle"
                  dataSource={isLoading ? [] : services}
                  columns={columns}
                  pagination={pagination}
                  scroll={{ y: 660, x: 0 }}
                  onChange={handlePageChange}
                  rowKey="id"
                  onRow={(record: any) => ({
                    onClick: () => {
                      navigate(`/services/${record?._id}?redirect=${"/services"}`);
                    }
                  })}
                  rowClassName={"cursor-pointer"}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>

        <CreateService
          isVisible={createModal}
          setVisible={setCreateModal}
          selected={selected}
        />
      </Wrapper>
    </>
  );
}
