import { BsClockHistory } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";

export default function StatusWidget({ service }: any) {
  return (
    <div className="flex flex-col justify-center items-center px-5">
      {service?.isCompleted ? (
        <>
          <div className="flex justify-center items-center w-10 h-10 rounded-xl border border-green-600">
            <FiCheckCircle size={16} color="#16a34a" />
          </div>
          <div className="my-2 justify-center">
            <div style={{ color: "#16a34a" }}>{service?.status}</div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center items-center w-10 h-10 rounded-xl border border-amber-500">
            <BsClockHistory size={16} color="#F59E0B" />
          </div>
          <div className="my-2 justify-center">
            <div style={{ color: "#F59E0B" }}>{service?.status}</div>
          </div>
        </>
      )}
    </div>
  );
}
