import formatMoney from "../../../utils/formatMoney";

export default function InvoiceTotals({ items }: any) {
  const calculateSubTotal = () => {
    let total = 0;
    items?.map((item: any) => {
      total = total + item?.partsrate + item?.labourrate * item?.flattime;
    });
    return isNaN(total) ? 0 : total;
  };

  const total = calculateSubTotal();

  return (
    <div className="flex justify-between">
      <div className=""></div>
      <div className="border border-black w-1/3">
        <div className="">
          <div className="flex flex-row justify-between px-2 border-b border-black">
            <div className="">Sub-Total</div>
            <div className="">{formatMoney(total)}</div>
          </div>
          <div className="flex flex-row justify-between px-2 border-b border-black">
            <div className="">VAT ( 18% )</div>
            <div className="">{formatMoney(total * 0.18)}</div>
          </div>
          <div className="flex flex-row justify-between border-t border-black font-bold py-1 px-2">
            <div className="font-bold">Grand Total </div>
            <div className=" font-bold ">
              <div className="">{formatMoney(total * 1.18)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
