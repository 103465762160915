import { message } from "antd";
import postDataRoad from "../../../../services/postDataRoad";

export default function Additional({ service, refetch }: any) {
  const handleUpdateStatus = async (item: any) => {
    return;
    if (service?.isCompleted) {
      message.error("Sorry, This work is completed");
      return;
    }
    const uri = `services/towins/${item?._id}`;
    let status = !item?.status;
    const data = {
      status,
      name: item?.name,
      service: service?._id,
      all: service?.towins,
    };
    const response = await postDataRoad(data, uri);
    if (response?.success) {
      message.success("Status updated successfully");
      refetch();
    }
  };

  return (
    <div className="mt-4 flex justify-between">
      <div className="w-96">
        Additional Services Required (circle if applicable) :
      </div>

      {service?.towins?.map((item: any) => (
        <button
          onClick={() => handleUpdateStatus(item)}
          className={`w-48 border-r border-black flex justify-center items-center hover:cursor-pointer ${
            item?.status ? "" : ""
          }`}
        >
          <div
            className={`px-2 border-t border-b rounded-full ${
              item?.status
                ? " border-black bg-gray-100"
                : " border-white hover:bg-gray-200"
            }`}
          >
            {item?.name}
          </div>
        </button>
      ))}
    </div>
  );
}
