import { Button, Modal, Form, message } from "antd";

export default function DeleteCustomer({
  isVisible,
  setVisible,
  customer,
}: any) {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    message.error("Sorry, you can't delete customer");
    setVisible(false);
  };

  return (
    <Modal
      visible={isVisible}
      title="Delete Customer"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="deleteCustomer"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form id="deleteCustomer" form={form} onFinish={onSubmit}>
        <div className="text-center font-bold">
          <p>Are you sure you want to delete this customer</p>
          <div className="text-red-500 ml-1">{customer?.name}</div>
        </div>
      </Form>
    </Modal>
  );
}
