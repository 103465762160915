import { Outlet } from "react-router";
import Header from "../components/layouts/Header";

export default function HomeLayout() {
  return (
    <div className="w-full h-screen bg-gray-100 font-toyota">
      <Header />
      <Outlet />
    </div>
  );
}
