import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useEffect, useState } from "react";
import fetchDataRoad from "../../../services/fetchDataRoad";
import postDataRoad from "../../../services/postDataRoad";

export default function VehicleModel({
  vehicle,
  isVisible,
  setVisible,
  refetch,
}: any) {
  const [models, setModels] = useState<any>([]);
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleUpdateVehicle = async (item: any) => {
    const uri = `vehicles/${vehicle?._id}`;
    const data = {
      model: item?._id,
    };
    const response = await postDataRoad(data, uri);

    if (response?.success) {
      setVisible(false);
      refetch();
    }
  };

  const handleFetchModels = async () => {
    const response = await fetchDataRoad({ uri: "models" });
    if (response.success) {
      setModels(response.payload);
    }
  };

  useEffect(() => {
    handleFetchModels();
  }, []);

  return (
    <Modal
      visible={isVisible}
      title="Vehicle Model"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
      ]}
    >
      <div className="grid grid-cols-12 gap-2">
        {models?.map((item: any, index: number) => (
          <div
            className="col-span-4 border p-4"
            onClick={() => handleUpdateVehicle(item)}
          >
            {item?.name}
          </div>
        ))}
      </div>
    </Modal>
  );
}
