export default function Six() {
  return (
    <div className="mt-2">
      <div style={{ fontSize: 12 }}>Ufafanuzi wa Maneno</div>
      <div className="">
        Maneno yafuatayo yatakuwa na maana zifuatazo katika nyaraka hii:
      </div>
      <div className="mt-1">
        <span className="font-bold">Ajali: </span>
        gari lako limeharibika kutokana na athari au mgongano wa aina yoyote au
        jaribio la wizi au kuvunjwa.
      </div>
      <div className="mt-1">
        <span className="font-bold">Hitilafu: </span>
        gari kushindwa au kutofaa kutembea barabarani au kutokuwa salama
        kuendelea na safari, kutokana hitilafu ya mitambo au mfumo wa umeme.
        Hitilafu inaweza pia kujumuisha gurudumu kuishiwa upepo, gari kuishiwa
        mafuta au kufungia funguo kwenye gari au kupoteza funguo.
      </div>
      <div className="mt-1">
        <span className="font-bold">Eneo lililozuiliwa: </span>
        eneo ambalo linalindwa kwa kutumia mifumo ya usalama na/au mifumo
        mingine ambayo inazuia watu wasiohusika kuingia eneo hilo ikiwa ni
        pamoja na maeneo yasiyoruhusiwa kuingia (mfano viwanja vya ndege,
        viwanja vya serikali, maandamano, matamasha na baadhi ya maeneo ya
        biashara)
      </div>
      <div className="mt-1">
        <span className="font-bold">Gari: </span>
        gari linalohusika na hitilafu.
      </div>
      <div className="mt-1">
        <span className="font-bold">Tu(-), Sisi, (-)letu: </span>
        Toyota Tanzania Limited
      </div>
      <div className="mt-1">
        <span className="font-bold">Wewe, (-)ako: </span>
        mmiliki au dereva wa gari, au mtu aliyeomba Msaada Pembezoni mwa
        Barabara.
      </div>

      <div className="my-2">
        Magari na vilivyomo vinatunzwa na kuendeshwa na wafanyakazi wetu kwa
        dhamana ya mmiliki. Hatutahusika na hasara itokanayo na moto, wizi,
        ajali au ucheleweshaji unaotokana na ukosefu wa vifaa au sababu nyingine
        zozote.
      </div>

      <div className="">
        <div className="font-bold">Taarifa Muhimu</div>
        <div className="mb-1">
          Tuna haki ya kubadilisha Vigezo na Masharti haya endapo italazimika
          ili kuendana na sheria na kanuni husika au ushauri au maelekezo ya
          mamlaka yoyote ya usimamizi. Tafadhali tembelea tovuti yetu mara kwa
          mara kuona kama kuna mabadiliko yoyote.
        </div>
        <div className="">
          Msaada wa Toyota Tanzania Pembezoni mwa Barabara kwa saa 24/7
          unatolewa na Toyota Tanzania Limited, iliyopo Kiwanja Na. 5 Barabara
          ya Nyerere, Dar es Salaam, Tanzania.
        </div>
      </div>
    </div>
  );
}
