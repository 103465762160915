import Key from "../../../assets/images/key.png";
import VehicleWidget from "./VehicleWidget";
export default function ExPhotosWrapper({ service, refetch }: any) {
  if (service?.exphotos === undefined) {
    return <></>;
  }

  const side1 = service?.exphotos[0];
  const side2 = service?.exphotos[1];
  const side3 = service?.exphotos[2];
  const side4 = service?.exphotos[3];
  const side5 = service?.exphotos[4];
  const side6 = service?.exphotos[5];
  const side7 = service?.exphotos[6];
  const side8 = service?.exphotos[7];

  return (
    <div className="book" id="invoice-doc">
      <div className="page">
        <div className="subpage2">
          <div className="p-1">
            <div
              className="flex justify-center items-center"
              style={{ fontSize: 16 }}
            >
              EXTERIOR PHOTOS
            </div>
            <div className="flex">
              <div className="w-1/3">
                <div className="m-4"></div>
                <div className=" m-4 mt-16">
                  <VehicleWidget
                    item={side8}
                    service={service}
                    refetch={refetch}
                  />
                </div>
                <div className="  m-4">
                  <VehicleWidget
                    item={side7}
                    service={service}
                    refetch={refetch}
                  />
                </div>
                <div className="  m-4">
                  <VehicleWidget
                    item={side6}
                    service={service}
                    refetch={refetch}
                  />
                </div>
                <div className="m-4"></div>
              </div>
              <div className="w-1/3 flex flex-col justify-between">
                <div className="h-0"></div>
                <div className=" m-4">
                  <VehicleWidget
                    item={side1}
                    service={service}
                    refetch={refetch}
                  />
                </div>
                <div className="flex-1">
                  <div className="flex justify-center items-center h-full">
                    <img alt="" src={Key} />
                  </div>
                </div>
                <div className=" m-4 mt-24">
                  <VehicleWidget
                    item={side5}
                    service={service}
                    refetch={refetch}
                  />
                </div>
                <div className="h-32"></div>
              </div>
              <div className="w-1/3">
                <div className="m-4"></div>
                <div className=" m-4 mt-16">
                  <VehicleWidget
                    item={side2}
                    service={service}
                    refetch={refetch}
                  />
                </div>
                <div className=" m-4">
                  <VehicleWidget
                    item={side3}
                    service={service}
                    refetch={refetch}
                  />
                </div>
                <div className=" m-4">
                  <VehicleWidget
                    item={side4}
                    service={service}
                    refetch={refetch}
                  />
                </div>
                <div className="m-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
