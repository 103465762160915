import { Checkbox } from "antd";
import { useState } from "react";
import ReactToPrint from "react-to-print";
import NoResults from "../../../components/shared/NoResults";
import formatMoney from "../../../utils/formatMoney";
import CreateItem from "./CreateItem";
import { PrinterOutlined } from "@ant-design/icons";

export default function ServiceOffered({ service, refetch, jobRef }: any) {
  const [createModel, setCreateModel] = useState<boolean>(false);
  const [updateModel, setUpdateModel] = useState<boolean>(false);
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);

  const calculateSubTotal = () => {
    let total = 0;
    service?.items?.map((item: any) => {
      total = total + item?.partsrate + item?.labourrate * item?.flattime;
    });
    return isNaN(total) ? 0 : total;
  };

  const total = calculateSubTotal();
  return (
    <div className="flex flex-col">
      <div className="h-full w-full mt-4">
        <div className="py-2 border-t border-b w-full flex justify-between bg-gray-100 px-2">
          <div className="w-1/2" style={{ fontSize: 15 }}>
            Services Offered
          </div>
          <div className="w-1/2">
            {/* <div className="flex">
              <button className=" w-1/2 flex justify-between border border-gray-300 text-xs items-center p-1 mr-1">
                <Checkbox onChange={() => setEditable(!editable)}></Checkbox>
                <span className="text-xs">Edit Mode</span>
              </button>
              <button
                onClick={() => setCreateModel(true)}
                className="w-1/2 border border-gray-300 p-1"
              >
                + Add Item
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {service?.items?.length === 0 ? (
        <div className="my-8">
          <NoResults title="No Services offered" />
        </div>
      ) : (
        <div className="px-2">
          {service?.items?.map((item: any) => (
            <div className="flex justify-between py-1 border-b">
              <div className="">{item?.callout_desc}</div>
              <div className="">
                {formatMoney(
                  item?.labourrate * item?.flattime + item?.partsrate
                )}
              </div>
            </div>
          ))}
          <div className="flex justify-between">
            <div className="w-1/2"></div>
            <div className="w-1/2">
              <div className="flex justify-between py-1">
                <div className="">Sub Total</div>
                <div className="">{formatMoney(total)}</div>
              </div>
              <div className="flex justify-between py-1">
                <div className="text-left">VAT</div>
                <div className="">{formatMoney(total * 0.18)}</div>
              </div>
              <div className="flex justify-between py-1 border-t-2 border-black">
                <div className="">Grand Total</div>
                <div className="">{formatMoney(total * 1.18)}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CreateItem
        isVisible={createModel}
        setVisible={setCreateModel}
        refetch={refetch}
        service={service}
      />
    </div>
  );
}
