import { Button, Drawer, Form, Space, Input, Tooltip, Pagination } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiLoader } from "react-icons/bi";
import { BsEye } from "react-icons/bs";
import { RiFileExcel2Line, RiFilterOffLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import fetchDataRoad from "../../../services/fetchDataRoad";
import { fetchAllVehicles } from "../../../utils/dataExports";
const { Search } = Input;
export default function VehiclePanel({ setVisible, isVisible }: any) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 15,
    limit: 15,
  });

  const handleFetchData = async (pagination: any) => {
    setLoading(true);
    const { current, limit } = pagination;
    const skip = current * limit - limit;
    const uri = `vehicles/paginated?skip=${skip}&limit=${limit}`;
    const response = await fetchDataRoad({ uri });
    if (response?.success) {
      setData(response?.payload?.results);
      setPagination({ total: response?.payload?.count, ...pagination });
    }
    setLoading(false);
  };

  const handleFetchAll = async () => {
    setFetching(true);
    await fetchAllVehicles();
    setFetching(false);
  };

  const handleNavigate = () => {
    setVisible(false);
    navigate("/vehicles");
  };

  const [form] = Form.useForm();

  const handleClearSearch = () => {
    setSearch("");
    form.resetFields();
  };

  const handlePageChange = (e: any) => {
    setPagination({
      current: e,
      pageSize: 15,
      limit: 15,
    });

    const a = {
      current: e,
      pageSize: 15,
      limit: 15,
    };

    handleFetchData(a);
  };

  useEffect(() => {
    handleFetchData(pagination);
  }, []);

  if (data.length === undefined) {
    return <></>;
  }

  return (
    <Drawer
      title={`All Vehicles`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      visible={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="font-toyota">
        <div className="flex justify-between items-center border-b border-gray-300 mb-2 pb-2 -mt-4">
          <div className="flex">
            <Form layout="inline" form={form}>
              <Form.Item name="name">
                <Search
                  placeholder="Search vehicle registration"
                  allowClear
                  onChange={(val: any) => {
                    setSearch(val.target.value);
                  }}
                  onSearch={(value: any) => {
                    setSearch(value);
                  }}
                />
              </Form.Item>

              <Button className="" onClick={() => handleClearSearch()}>
                <RiFilterOffLine color="#6b7280" />
              </Button>
            </Form>
          </div>
          <button
            color="#E50000"
            className="flex justify-center items-center border px-3 py-1 hover:bg-gray-100"
            onClick={() => handleFetchAll()}
          >
            {isFetching ? (
              <BiLoader size={20} />
            ) : (
              <RiFileExcel2Line size={20} />
            )}
            <span className="ml-1">Export</span>
          </button>
        </div>
        <div className="flex py-1 border-b border-gray-300">
          <div className="mr-2 w-10 text-center font-bold">S/N.</div>
          <div className="flex justify-between items-center flex-1">
            <div className="flex items-center">
              <div className="w-36 truncate font-bold">Registration</div>
              <div className="w-36 truncate text-left font-bold">Customer</div>
              <div className="w-36 truncate font-bold">Model</div>
              <div className="w-36 truncate font-bold">Chassis</div>
            </div>
            <div className="font-bold w-full">
              <div className="flex">
                <div className="mr-2 text-left flex-1"></div>
                <div className="truncate flex justify-end">Services</div>
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center mt-16">
            Loading ...
          </div>
        ) : (
          <>
            {data
              ?.filter((item: any) => item?.registration?.includes(search))
              .map((item: any, index: number) => (
                <div className="flex py-1 border-b border-gray-300" key={index}>
                  <div className="mr-2 w-10 text-center">{index + 1}.</div>
                  <div className="flex justify-between items-center flex-1">
                    <div className="flex items-center">
                      <div className="w-36">
                        {item?.registration === null ? "-" : item?.registration}
                      </div>
                      <div className="w-36 truncate">
                        {item?.customer?.name === null
                          ? "-"
                          : item?.customer?.name}
                      </div>
                      <div className="w-36 truncate flex">
                        {item?.model?.name === null ? (
                          "-"
                        ) : (
                          <span>
                            {item?.model?.name} - {item?.year}
                          </span>
                        )}
                      </div>
                      <div className="w-36 truncate flex">
                        {item?.chassis === null ? (
                          "-"
                        ) : (
                          <span>{item?.chassis}</span>
                        )}
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="flex">
                        <div className="mr-2 text-left flex-1"></div>
                        <button
                          onClick={() => {}}
                          disabled
                          className="truncate ml-2 border px-2 py-1 flex justify-center items-center hover:bg-blue-50 hover:cursor-not-allowed"
                        >
                          <BsEye /> <span className="ml-2">Services</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div className="flex flex-col justify-center items-center mt-4">
              <button
                onClick={() => handleNavigate()}
                className="flex justify-center items-center border px-2 py-1 rounded-lg hover:bg-green-100 mb-4"
              >
                Go to Vehicles Page
              </button>
              <Pagination
                defaultCurrent={1}
                total={pagination?.total}
                pageSize={15}
                defaultPageSize={15}
                onChange={handlePageChange}
              />
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
}
