import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import postDataRoad from "../../../services/postDataRoad";

export default function CreateCustomer({
  isVisible,
  setVisible,
  refetch,
}: any) {

  const [isLoading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState(1);

  const [form] = Form.useForm();

  const onSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();
    const uri = "customers";
    const isCompany = selected === 1 ? true : false;
    const data = { isCompany, ...values };
    const response = await postDataRoad(data, uri);
    if (response.success) {
      message.success("Created Successfully");
    } else {
      message.error(response?.message);
      setLoading(false);
      return;
    }

    refetch();
    setLoading(false);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  const types = [
    { id: 1, name: "Company" },
    { id: 2, name: "Individual" },
  ];

  return (
    <Modal
      visible={isVisible}
      title="New Customer"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createCustomer"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createCustomer"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          {types?.map((type: any, index: number) => (
            <Col xs={{ span: 24 }} lg={{ span: 12 }} key={index}>
              <button
                type="button"
                className="flex flex-row  items-center mb-4 border w-full"
                onClick={() => setSelected(type.id)}
              >
                {selected === type?.id ? (
                  <div className="h-8 w-8 border-r flex justify-center items-center bg-green-100">
                    <AiOutlineCheck />
                  </div>
                ) : (
                  <div className="h-8 w-8 border-r flex justify-center items-center">
                    <AiOutlineCheck color="#FFFFFF" />
                  </div>
                )}
                <div className="ml-4"></div>
                {type?.name}
              </button>
            </Col>
          ))}
        </Row>

        {selected === 1 ? (
          <>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input placeholder={"Company Name"} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile",
                    },
                  ]}
                >
                  <Input placeholder="Mobile" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: false,
                      message: "Please enter email",
                    },
                  ]}
                >
                  <Input placeholder="Email (Optional)" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="address1"
                  rules={[
                    {
                      required: false,
                      message: "Please enter address 1",
                    },
                  ]}
                >
                  <Input placeholder="Address 1 (Optional)" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="address2"
                  rules={[
                    {
                      required: false,
                      message: "Please enter address 2",
                    },
                  ]}
                >
                  <Input placeholder="Address 2 (Optional)" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="address3"
                  rules={[
                    {
                      required: false,
                      message: "Please enter address 3",
                    },
                  ]}
                >
                  <Input placeholder="Address 3 (Optional)" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="address4"
                  rules={[
                    {
                      required: false,
                      message: "Please enter address 4",
                    },
                  ]}
                >
                  <Input placeholder="Address 4 (Optional)" />
                </Form.Item>
              </Col>
            </Row>

            <div className="">Contact Person</div>

            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="cname"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="cmobile"
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile",
                    },
                  ]}
                >
                  <Input placeholder="Mobile" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="cemail"
                  rules={[
                    {
                      required: false,
                      message: "Please enter email",
                    },
                  ]}
                >
                  <Input placeholder="Email (Optional)" />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile",
                    },
                  ]}
                >
                  <Input placeholder="Mobile" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: false,
                      message: "Please enter email",
                    },
                  ]}
                >
                  <Input placeholder="Email (Optional)" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="address1"
                  rules={[
                    {
                      required: false,
                      message: "Please enter address 1",
                    },
                  ]}
                >
                  <Input placeholder="Address 1 (Optional)" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="address2"
                  rules={[
                    {
                      required: false,
                      message: "Please enter address 2",
                    },
                  ]}
                >
                  <Input placeholder="Address 2 (Optional)" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="address3"
                  rules={[
                    {
                      required: false,
                      message: "Please enter address 3",
                    },
                  ]}
                >
                  <Input placeholder="Address 3 (Optional)" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="address4"
                  rules={[
                    {
                      required: false,
                      message: "Please enter address 4",
                    },
                  ]}
                >
                  <Input placeholder="Address 4 (Optional)" />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
}
