import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function ManageLink({ children, icon, to }: any) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  return (
    <div
      className={`border-l${
        match ? " border-toyota bg-white" : "border-gray-50"
      }`}
    >
      <Link to={to}>
        <div className="py-4 px-6 flex items-center text-gray-600">
          <div className="">{icon}</div>
          <div className="ml-2 hidden sm:block">{children}</div>
        </div>
      </Link>
    </div>
  );
}
