import { EMAIL_BASE_URL } from "../utils/urls";

const postDataMail = async (maildata: any, mailuri: any) => {
  const response = await fetch(`${EMAIL_BASE_URL}/${mailuri}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(maildata),
  });
  const result = await response.json();
  return result;
};

export default postDataMail;
